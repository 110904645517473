<div id="card-calendar" class="card p-0">
  <div class="card-body" appCalendarInteraction>
    <div id="shive-calendar">
      @defer (when finishedLoading()) {
        <app-shive-control-bar [calendarViewMode]="calendarViewMode"></app-shive-control-bar>
        <table id="shive-outer-grid">
          <thead id="shive-calendar-header" [ngClass]="CalendarView[calendarViewMode]">
            <tr>
              <td>
                <app-shive-calendar-header
                  [fulldayLanesLoaded$]="fulldayLanesLoaded$"
                ></app-shive-calendar-header>
              </td>
            </tr>
          </thead>
          <tbody
            id="shive-calendar-body"
            class="scrollable"
            [ngClass]="CalendarView[calendarViewMode]"
          >
            <tr>
              <td>
                @if (calendarViewMode === CalendarView.DayGrid) {
                  <app-day-view
                    [fulldayLanesLoaded$]="fulldayLanesLoaded$.asObservable()"
                  ></app-day-view>
                }
                @if (calendarViewMode === CalendarView.WeekGrid) {
                  <app-week-view
                    [fulldayLanesLoaded$]="fulldayLanesLoaded$.asObservable()"
                  ></app-week-view>
                }
                @if (calendarViewMode === CalendarView.MonthGrid) {
                  <app-month-view></app-month-view>
                }
              </td>
            </tr>
          </tbody>
        </table>
      } @placeholder (minimum 500ms) {
        <app-calendar-skeleton-week-view />
      }
    </div>
  </div>
</div>
