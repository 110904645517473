import { createFeatureSelector, createSelector } from '@ngrx/store';
import { holidayTemplateAdapter } from './holiday-templates.reducer';

export const selectHolidayTemplates = createFeatureSelector('holidayTemplates');

export const selectAllHolidayTemplates = createSelector(
  selectHolidayTemplates,
  holidayTemplateAdapter.getSelectors().selectAll,
);

export const selectHolidayTemplateHolidays = (holidayTemplateId: number) =>
  createSelector(selectAllHolidayTemplates, (entities) => {
    const holidayTemplate = entities.find(
      (holidayTemplate) => holidayTemplate.id === holidayTemplateId,
    );
    return holidayTemplate?.holidayEntries;
  });

export const selectHolidayTemplatesOfUser = (userId: number) => {
  createSelector(selectAllHolidayTemplates, (entities) => {
    const holidayTemplates = entities.filter((holidayTemplate) =>
      holidayTemplate.members.some((member) => member.id === userId),
    );

    const test = holidayTemplates.flatMap((holidayTemplate) => holidayTemplate.holidayEntries);
    return test;
  });
};

export const selectHolidayDates = () =>
  createSelector(selectAllHolidayTemplates, (holidayTemplates) => {
    // Holidays
    const flattenedHolidays = holidayTemplates.flatMap((template) =>
      template.holidayEntries.map((entry) => entry.date),
    );

    return flattenedHolidays.filter(function (item, pos, self) {
      const dateStr = item.toISOString(); // Convert to ISO string for comparison
      return pos === self.findIndex((d) => d.toISOString() === dateStr);
    });
  });
