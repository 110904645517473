import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UserOperation } from '../../../../../../core/enums/user-operation';
import { faCirclePlus, faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShiveDialogService } from '../../../../../../core/services/controls/shive-dialog.service';
import { AbsenceReasonModel } from '../../../../../../core/models/absence-reason/absence-reason.model';

@Component({
  selector: 'app-absence-reason-settings-dialog',
  templateUrl: './absence-reason-settings-dialog.component.html',
})
export class AbsenceReasonSettingsDialogComponent implements OnInit {
  @ViewChild('dialogFooter') dialogFooter: TemplateRef<unknown>;
  @Input() absenceReasonName = '';
  @Input() isWorkingTime = false;
  @Input() userOperation = UserOperation.None;

  public absenceReasonForm: FormGroup;
  public UserOperation = UserOperation;
  public readonly faCirclePlus = faCirclePlus;
  public readonly faPenToSquare = faPenToSquare;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly shiveDialogService: ShiveDialogService,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  public submit() {
    if (this.absenceReasonForm.valid) {
      const absenceReasonName = this.absenceReasonForm.get('absenceReasonName').value;
      const isWorkingTime = this.absenceReasonForm.get('isWorkingTime').value;
      const absenceReason = new AbsenceReasonModel();
      absenceReason.name = absenceReasonName;
      absenceReason.isWorkingTime = isWorkingTime;
      this.shiveDialogService.close(absenceReason);
    }
  }

  public close() {
    this.shiveDialogService.close(UserOperation.Cancel);
  }

  private initForm(): void {
    this.absenceReasonForm = this.formBuilder.group({
      absenceReasonName: [this.absenceReasonName, Validators.required],
      isWorkingTime: [this.isWorkingTime],
    });
  }
}
