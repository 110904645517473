import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DropdownOption } from '../../../../shared/data-types/dropdown-option';
import { entityToDropdownOption } from '../../../../shared/functions/dropdown-functions';
import { SubSink } from 'subsink';
import { combineLatestWith, filter, map, take, tap } from 'rxjs/operators';
import { HolidayTemplateEntryModel } from '../../../../core/models/settings/holiday/holiday-template-entry.model';
import { UserOperation } from '../../../../core/enums/user-operation';
import { UserModel } from '../../../../core/models/user/user.model';
import { ShiveDialogService } from '../../../../core/services/controls/shive-dialog.service';
import { HolidayTemplateDialogComponent } from './dialogs/holiday-template-dialog/holiday-template-dialog.component';
import Autobind from '../../../../shared/typescript-decorators/autobind.decorator';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { HolidaySettingsStore } from '../state-and-data-handling/holiday-settings.store';
import { HolidayTemplateModel } from '../../../../core/models/settings/holiday/holiday-template.model';
import { cloneDeep } from 'lodash-es';
import { HolidayTemplatesHttpService } from '../../../../core/state/settings/holiday-templates/holiday-templates-http.service';
import { ShiveDropdownlistComponent } from '../../../../shared/components/form-components/shive-dropdownlist/shive-dropdownlist.component';
import { Actions, ofType } from '@ngrx/effects';
import * as fromHolidayTemplatesActions from '../../../../core/state/settings/holiday-templates/holiday-templates.actions';
import { generateDummyId } from '../../../../shared/functions/array-functions';
import { Logger } from '../../../../shared/logging/logger';
import { HolidaySettingsService } from './holiday-settings.service';

@Component({
  selector: 'app-holiday-settings',
  templateUrl: './holiday-settings.component.html',
  styleUrls: ['./holiday-settings.component.scss'],
})
export class HolidaySettingsComponent implements OnInit, OnDestroy {
  @ViewChild('shiveDropdownList') shiveDropdownList: ShiveDropdownlistComponent;
  public holidayTemplateOptions: readonly DropdownOption[] = [];
  public holidays: HolidayTemplateEntryModel[] = [];
  public members: UserModel[] = [];
  public selectedOption: DropdownOption;
  public UserOperation = UserOperation;

  private readonly subs = new SubSink();

  constructor(
    public holidayTemplatesHttpService: HolidayTemplatesHttpService,
    private readonly holidaySettingsStore: HolidaySettingsStore,
    private readonly holidaySettingsService: HolidaySettingsService,
    private readonly actions$: Actions,
    private readonly shiveDialogService: ShiveDialogService,
  ) {}

  ngOnInit(): void {
    this.initialize();
    this.handlePostResponse();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public upsertHolidayTemplate(
    userOperation: UserOperation,
    selectedHolidayTemplate?: DropdownOption,
  ): void {
    const dialogTitle =
      userOperation === UserOperation.Create
        ? 'Feiertagsliste erstellen'
        : 'Feiertagsliste bearbeiten';
    const ref = this.openHolidayTemplateDialog(dialogTitle);
    const holidayTemplateDialogComponent = ref.content.instance as HolidayTemplateDialogComponent;
    holidayTemplateDialogComponent.userOperation = userOperation;
    if (userOperation === UserOperation.Update) {
      holidayTemplateDialogComponent.holidayTemplateName = selectedHolidayTemplate.text;
    }

    this.subs.sink = ref.result
      .pipe(
        tap(() => (this.shiveDropdownList.userOperation = UserOperation.None)),
        filter((value) => value !== UserOperation.Cancel),
        combineLatestWith(this.holidaySettingsStore.selectHolidayTemplates$.pipe(take(1))),
      )
      .subscribe(([newHolidayTemplateName, allHolidayTemplates]) => {
        if (userOperation === UserOperation.Create) {
          const templateModel = this.buildHolidayTemplateModel(
            newHolidayTemplateName as string,
            allHolidayTemplates,
          );
          this.insertHolidayTemplate(templateModel);
          this.holidays = [];
          this.members = [];
        } else {
          this.updateHolidayTemplate(
            newHolidayTemplateName as string,
            allHolidayTemplates,
            selectedHolidayTemplate,
          );
          this.holidaySettingsStore.userOperationConducted.next(true);
        }
      });
  }

  public duplicateHolidayTemplate(selectedHolidayTemplate: DropdownOption): void {
    this.holidaySettingsStore.selectHolidayTemplates$
      .pipe(take(1))
      .subscribe((allHolidayTemplates) => {
        const holidayTemplateModel = this.holidaySettingsService.getHolidayTemplateClone(
          +selectedHolidayTemplate.value,
        );
        if (!holidayTemplateModel) {
          throw new Error(`Holiday template not found. id: ${selectedHolidayTemplate.value}`);
        }

        const newTemplateName = `${selectedHolidayTemplate.text} - Kopie`;
        const clonedHolidayTemplateModel = cloneDeep(holidayTemplateModel);
        clonedHolidayTemplateModel.holidayEntries = clonedHolidayTemplateModel.holidayEntries.map(
          (holiday, index) => {
            return {
              ...holiday,
              id: (index + 1) * -1,
            };
          },
        );
        const templateModel = this.buildHolidayTemplateModel(
          newTemplateName,
          allHolidayTemplates,
          clonedHolidayTemplateModel,
        );
        this.insertHolidayTemplate(templateModel);
        this.holidays = [...templateModel.holidayEntries];
        this.members = [...templateModel.members];
      });
  }

  public removeHolidayTemplate(toBeRemovedOption: DropdownOption): void {
    const holidayTemplate = this.holidaySettingsService.getHolidayTemplateClone(
      +toBeRemovedOption.value,
    );
    if (!holidayTemplate) {
      throw new Error(`Holiday template not found. id: ${toBeRemovedOption.value}`);
    }

    this.holidaySettingsStore.removeHolidayTemplate(holidayTemplate);
    this.updateHolidayTemplateOptions(toBeRemovedOption);
    this.shiveDropdownList.userOperation = UserOperation.None;
    if (this.holidayTemplateOptions.length === 0) {
      this.selectedOption = null;
      this.holidays = [];
      this.members = [];
      this.holidaySettingsStore.userOperationConducted.next(true);
      return;
    }

    if (toBeRemovedOption.value === this.selectedOption.value) {
      this.selectedOption = this.holidayTemplateOptions[0];
      this.selectionChange(this.selectedOption);
    }
    this.holidaySettingsStore.userOperationConducted.next(true);
  }

  @Autobind
  public selectionChange(selectedOption: DropdownOption): void {
    this.selectedOption = selectedOption;
    const holidayTemplate = this.holidaySettingsService.getHolidayTemplateClone(
      +selectedOption.value,
    );
    if (!holidayTemplate) {
      throw new Error(`Holiday template not found. id: ${+selectedOption.value}`);
    }
    this.holidays = [...holidayTemplate.holidayEntries];
    this.members = [...holidayTemplate.members];
  }

  private buildHolidayTemplateModel(
    templateName: string,
    allHolidayTemplates: HolidayTemplateModel[],
    clone?: HolidayTemplateModel,
  ): HolidayTemplateModel {
    let holidayTemplateModel = new HolidayTemplateModel();

    if (clone) {
      holidayTemplateModel = cloneDeep(clone);
    }

    holidayTemplateModel.id = generateDummyId(allHolidayTemplates);
    holidayTemplateModel.name = templateName;
    return holidayTemplateModel;
  }

  private initialize(): void {
    this.subs.sink = this.holidayTemplatesHttpService
      .getHolidayTemplatesForCurrentUser()
      .pipe(
        filter((templates) => templates.length > 0),
        tap((templates) => {
          const firstTemplate = templates[0];
          this.holidays = [...firstTemplate.holidayEntries];
          this.members = [...firstTemplate.members];
        }),
        map((templates) => {
          const dropdownOptions: DropdownOption[] = [];
          templates.forEach((template) => {
            Logger.debug('Template added in initalize()', template);
            this.holidaySettingsStore.addHolidayTemplate(template);
            const dropdownOption: DropdownOption = entityToDropdownOption({
              name: template.name,
              id: template.id,
            });
            dropdownOptions.push(dropdownOption);
          });
          return dropdownOptions;
        }),
        take(1),
      )
      .subscribe((dropdownOptions) => {
        this.holidayTemplateOptions = dropdownOptions;
        this.selectedOption = dropdownOptions[0];
      });
  }

  private openHolidayTemplateDialog(title: string): DialogRef {
    return this.shiveDialogService.open({
      content: HolidayTemplateDialogComponent,
      width: 572,
      title,
    });
  }

  private insertHolidayTemplate(newHolidayTemplate: HolidayTemplateModel) {
    Logger.debug('Template added in insertHolidayTemplate()', newHolidayTemplate);
    this.holidaySettingsStore.addHolidayTemplate(newHolidayTemplate);

    const newOption = entityToDropdownOption({
      name: newHolidayTemplate.name,
      id: newHolidayTemplate.id,
    });
    this.holidayTemplateOptions = [newOption, ...this.holidayTemplateOptions];
    this.selectedOption = newOption;
    this.holidaySettingsStore.userOperationConducted.next(true);
  }

  private updateHolidayTemplate(
    newHolidayTemplateName: string,
    allHolidayTemplates: HolidayTemplateModel[],
    selectedHolidayTemplate: DropdownOption,
  ) {
    const id = selectedHolidayTemplate.value;
    const holidayTemplateIndex = allHolidayTemplates.findIndex((template) => template.id === id);
    if (holidayTemplateIndex < 0) {
      throw new Error('Holiday template could not be found.');
    }
    const updatedHolidayTemplate = cloneDeep(allHolidayTemplates[holidayTemplateIndex]);
    updatedHolidayTemplate.name = newHolidayTemplateName;
    Logger.debug('Template updated in upsertHoliday()', updatedHolidayTemplate);
    this.holidaySettingsStore.updateHolidayTemplate(updatedHolidayTemplate);
    this.selectedOption = {
      value: id,
      text: newHolidayTemplateName,
    };
    this.holidayTemplateOptions.find((option) => option.value === id).text = newHolidayTemplateName;
  }

  private handlePostResponse() {
    this.actions$
      .pipe(
        ofType(fromHolidayTemplatesActions.holidayTemplateAdded),
        map((response) => response.payload),
      )
      .subscribe((payload) => {
        Logger.debug('Template added in handlePostResponse()', payload.newlyPersistedTemplate);
        this.holidaySettingsStore.addHolidayTemplate(payload.newlyPersistedTemplate);
        const toBeRemovedOption: DropdownOption = {
          value: payload.dummyTemplate.id,
          text: payload.dummyTemplate.name,
        };

        const newOption: DropdownOption = {
          value: payload.newlyPersistedTemplate.id,
          text: payload.newlyPersistedTemplate.name,
        };
        this.updateHolidayTemplateOptions(toBeRemovedOption, newOption);
        if (toBeRemovedOption.value === this.selectedOption.value) {
          this.selectedOption = newOption;
        }
      });
  }

  private updateHolidayTemplateOptions(
    toBeRemovedOption: DropdownOption,
    newOption?: DropdownOption,
  ): void {
    const copiedHolidayTemplateOptions = [...this.holidayTemplateOptions];
    const index = copiedHolidayTemplateOptions.findIndex(
      (option) => option.value === toBeRemovedOption.value,
    );
    if (index < 0) {
      throw new Error('Existing holiday template option not found.');
    }

    if (newOption) {
      copiedHolidayTemplateOptions.splice(index, 1, newOption);
    } else {
      copiedHolidayTemplateOptions.splice(index, 1);
    }
    this.holidayTemplateOptions = copiedHolidayTemplateOptions;
  }
}
