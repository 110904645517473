import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { SubSink } from 'subsink';
import { BaseCalendarModel } from '../../../../core/models/calendar/base-calendar.model';
import { CalendarService } from '../../services/calendar.service';
import { DateTimeHelper } from '../../util/date-time-helper';
import * as svgIcons from '@progress/kendo-svg-icons';
import { CalendarEvents, CalendarView } from '../../../../shared/data-types/calendar-types';
import { DropdownOption } from '../../../../shared/data-types/dropdown-option';
import { CalendarServiceHelper } from '../../services/calendar-service-helper';
import { faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import { ProjectMemberService } from '../../../project/detail-project/edit-project/services/project-member.service';
import { ControlBarService } from './control-bar.service';
import { CalendarInteractionService } from '../../interaction/calendar-interaction.service';
import { ActiveTimeBlockTimerService } from '../time-block/active/active-time-block-timer.service';
import { SharedActiveTimeBlockService } from '../time-block/active/shared-active-time-block.service';

@Component({
  selector: 'app-shive-control-bar',
  templateUrl: './control-bar.component.html',
  styleUrls: ['./control-bar.component.scss'],
})
export class ControlBarComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() calendarViewMode: CalendarView;
  public chevronLeftIcon = svgIcons.chevronLeftIcon;
  public chevronRightIcon = svgIcons.chevronRightIcon;
  public weekIndex: number;
  public trackerActive: boolean | null = null;
  public CalendarView = CalendarView;
  public calendarModel: BaseCalendarModel;
  public accumulatedTimeMap = new Map<string, number>();
  public viewOptions: readonly DropdownOption[] = [
    { text: 'Tag', value: CalendarView.DayGrid },
    { text: 'Woche', value: CalendarView.WeekGrid },
    { text: 'Monat', value: CalendarView.MonthGrid },
  ];
  public readonly faPlay = faPlay;
  public readonly faStop = faStop;
  public isLoadingUsers = false;
  // timerActiveTimeBlockId === null => loading
  // timerActiveTimeBlockId < 0 => no active time block
  // timerActiveTimeBlockId >= 0 => active time block exists
  public timerActiveTimeBlockId = null;
  // trackerActiveTimeBlockId < 0 => no user input detected
  // tracerActiveTimeBlockId >= 0 => user input detected
  public trackerActiveTimeBlockId = -1;

  private readonly subs = new SubSink();

  constructor(
    public calendarInteractionService: CalendarInteractionService,
    public readonly calendarService: CalendarService,
    public readonly sharedActiveTimeBlockService: SharedActiveTimeBlockService,
    public readonly activeTimeBlockTimerService: ActiveTimeBlockTimerService,
    public readonly controlBarService: ControlBarService,
    private readonly projectMemberService: ProjectMemberService,
    private readonly cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initCalendarEvents();
    this.initTimeTrackingEvents();
  }

  ngAfterViewInit(): void {
    this.controlBarService.activeCalendarView = this.calendarService.model.calendarViewMode;
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private initCalendarEvents(): void {
    const targetEvents = [
      CalendarEvents.Start,
      CalendarEvents.SwitchedCalendarViewMode,
      CalendarEvents.ChangedStartEndDates,
      CalendarEvents.CalculatedWorkingTimeDurations,
      CalendarEvents.UpdatedShiveTrackerStatus,
    ];

    const callback = (
      calendarModel: BaseCalendarModel,
      calendarEvents: CalendarEvents[],
      meta: Map<string, number>,
    ): void => {
      // Calculate work time durations.
      if (calendarEvents.includes(CalendarEvents.CalculatedWorkingTimeDurations)) {
        this.accumulatedTimeMap = meta;
        this.cd.detectChanges();
        return;
      }

      // Toggle start / stop button visibility depending on tracker status
      if (calendarEvents.includes(CalendarEvents.UpdatedShiveTrackerStatus)) {
        this.trackerActive = !!calendarModel.shiveTrackerActive;
        this.cd.detectChanges();
      }

      // Calculate week index.
      this.calendarModel = calendarModel;
      if (
        calendarModel.calendarViewMode === CalendarView.DayGrid ||
        calendarModel.calendarViewMode === CalendarView.WeekGrid
      ) {
        this.weekIndex = DateTimeHelper.weekNumber(
          calendarModel.calendarProperties.visibleStartDate,
        );
      }
    };

    this.subs.sink = CalendarServiceHelper.calendarModelUpdated(
      this.calendarService,
      callback,
      targetEvents,
    );
  }

  private initTimeTrackingEvents() {
    this.subs.sink = this.sharedActiveTimeBlockService.timerActiveTimeBlockIdChanged$.subscribe(
      (timerActiveTimeBlockId) => {
        this.timerActiveTimeBlockId = timerActiveTimeBlockId;
        this.cd.detectChanges();
      },
    );

    this.subs.sink = this.sharedActiveTimeBlockService.trackerActiveTimeBlockIdChanged$.subscribe(
      (trackerActiveTimeBlockId) => {
        this.trackerActiveTimeBlockId = trackerActiveTimeBlockId;
      },
    );
  }
}
