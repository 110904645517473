<app-shive-grid #gridWrapperComponent [grid]="grid">
  <kendo-grid
    kendoGridSelectBy="id"
    scrollable="none"
    size="medium"
    [kendoGridBinding]="members"
    [pageSize]="20"
    [selectable]="{ enabled: true, checkboxOnly: true }"
    [columnMenu]="{ filter: true }"
    (add)="insertMember()"
    #grid
  >
    <ng-template kendoGridToolbarTemplate>
      <div class="k-d-flex k-justify-content-end k-w-full">
        <button
          kendoGridAddCommand
          themeColor="secondary"
          [disabled]="gridWrapperComponent.userOperation !== UserOperation.None"
        >
          <span class="custom-symbol user turquoise k-mr-3"></span>
          <span>Benutzer hinzufügen</span>
        </button>
      </div>
    </ng-template>

    <kendo-grid-checkbox-column
      [width]="40"
      [headerClass]="{ 'k-text-center': true }"
      class="list-selection-check"
      [resizable]="false"
      [columnMenu]="false"
      [showSelectAll]="true"
    >
    </kendo-grid-checkbox-column>

    <kendo-grid-column field="member_name" title="Name" [class]="'cell-with-avatar'">
      <ng-template kendoGridCellTemplate let-member>
        <app-shared-member-template [user]="member"></app-shared-member-template>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="context_menu" title="" [columnMenu]="false" [width]="40">
      <ng-template kendoGridCellTemplate let-dataItem>
        <app-context-menu-anchor
          [dataItem]="dataItem"
          [gridWrapperComponent]="gridWrapperComponent"
        />
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</app-shive-grid>
