<div
  id="shive-calendar-control-bar"
  [ngClass]="CalendarView[calendarViewMode]"
  class="k-d-flex k-justify-content-between k-align-items-center"
>
  <div id="month-and-year" class="invert-scale k-w-full k-display-flex k-align-items-center">
    <h3 class="font-weight-bold k-m-0 text" [innerHTML]="calendarModel | monthAndYear"></h3>
  </div>

  <div id="time-and-controls" class="k-d-flex k-align-items-center invert-scale">
    <div class="k-d-flex k-mr-20">
      <span class="small k-align-self-end k-mr-3" style="line-height: 1.5em">
        {{ controlBarService.getTitle() }}
      </span>
      <span id="total-time" class="k-ml-2">{{
        accumulatedTimeMap | timeBlockAccumulatedWork
      }}</span>
    </div>

    <div id="controls" class="k-d-flex">
      <button
        kendoButton
        rounded="full"
        size="large"
        themeColor="secondary"
        (click)="calendarInteractionService.prev()"
        class="k-mr-2.5"
        [svgIcon]="chevronLeftIcon"
      ></button>

      @if (calendarService.model.calendarViewMode === CalendarView.WeekGrid) {
        <div id="week-of-year" class="k-ml-3 k-mr-3 k-text-center">KW {{ weekIndex }}</div>
      }

      <button
        kendoButton
        rounded="full"
        size="large"
        themeColor="secondary"
        (click)="calendarInteractionService.next()"
        [svgIcon]="chevronRightIcon"
        class="k-ml-3"
      ></button>

      <button
        kendoButton
        (click)="calendarInteractionService.today()"
        size="large"
        themeColor="secondary"
        class="k-ml-5.5 k-mr-5.5"
      >
        Heute
      </button>

      <kendo-dropdownlist
        [data]="viewOptions"
        [value]="controlBarService.activeCalendarView"
        [valuePrimitive]="true"
        textField="text"
        valueField="value"
        (selectionChange)="controlBarService.switchView($event)"
        class="height-inherit !k-w-auto"
      ></kendo-dropdownlist>
    </div>
    <div id="tracking-controls" class="k-d-flex k-align-self-stretch k-ml-5 k-justify-content-end">
      @if (trackerActive === true) {
        <ng-container [ngTemplateOutlet]="tracker"></ng-container>
      } @else if (trackerActive === false) {
        <ng-container [ngTemplateOutlet]="timer"></ng-container>
      } @else {
        <!-- Waiting for websocket check-->
        <div class="k-ml-2" style="white-space: nowrap">Waiting for tracker...</div>
      }
    </div>
  </div>
</div>

<ng-template #timer>
  @if (timerActiveTimeBlockId === null) {
    loading...
  }
  @if (timerActiveTimeBlockId !== null) {
    <button
      kendoButton
      id="toggle-timer"
      (click)="activeTimeBlockTimerService.toggleActiveTimeBlock()"
      themeColor="base"
      class="k-ml-22 !k-pr-4"
      [class.k-button-solid-error-two]="timerActiveTimeBlockId >= 0"
      size="large"
    >
      @if (timerActiveTimeBlockId < 0) {
        <fa-icon class="add text-white k-mr-3" [icon]="faPlay" [size]="'xs'"></fa-icon>
        <span class="k-button-text">Start</span>
      }
      @if (timerActiveTimeBlockId >= 0) {
        <fa-icon class="add text-white k-mr-3" [icon]="faStop" [size]="'xs'"></fa-icon>
        <span class="k-button-text">Stop</span>
      }
    </button>
  }
</ng-template>

<ng-template #tracker>
  <div
    id="tracker-active"
    class="k-ml-2 k-w-full"
    [ngClass]="trackerActiveTimeBlockId < 0 ? 'user-idle' : 'user-active'"
  ></div>
</ng-template>
