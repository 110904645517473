import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewEncapsulation,
  signal,
} from '@angular/core';
import { CalendarEvents, CalendarView } from '../../shared/data-types/calendar-types';
import { BaseCalendarModel } from '../../core/models/calendar/base-calendar.model';
import { SubSink } from 'subsink';
import { CalendarService } from './services/calendar.service';
import { CalendarScrollbarService } from './services/calendar-scrollbar.service';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, take } from 'rxjs';
import { TimeBlockContainerDirective } from './views/time-block-container.directive';
import { ShiveNotificationService } from '../../core/services/controls/shive-notification.service';
import { InteractHandlerService } from './components/time-block/interaction/movement/interact-handler.service';
import { CalendarServiceHelper } from './services/calendar-service-helper';
import { NowIndicatorCSSId } from '../../core/data-repository/css-constants';
import { DateTimeHelper } from './util/date-time-helper';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { TimeCoordinateMappingService } from './time-mapping/time-coordinate-mapping.service';
import { NewTimeBlockGenerationService } from './components/time-block/generation/new-time-block-generation.service';
import Autobind from '../../shared/typescript-decorators/autobind.decorator';
import { CalendarCronJobsService } from './services/calendar-cron-jobs.service';
import { calendarCronjobsInterval } from '../../../assets/config/config-constants';
import { ActiveTimeBlockService } from './components/time-block/active/active-time-block.service';
import { selectHolidayDates } from '../../core/state/settings/holiday-templates/holiday-templates.selectors';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-shive-calendar',
  templateUrl: './calendar.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  // Set ActiveTimeBlockService here to reset the updateActiveTimeBlock$ BehaviorSubject every time the calendar is rendered.
  providers: [ActiveTimeBlockService],
})
export class CalendarComponent implements OnInit, OnDestroy {
  public CalendarView = CalendarView;
  public calendarViewMode: CalendarView;
  public fulldayLanesLoaded$ = new BehaviorSubject<
    [QueryList<TimeBlockContainerDirective>, QueryList<ElementRef>]
  >(null);
  public finishedLoading = signal<boolean>(false);
  private readonly subs = new SubSink();
  private initialDate: Date;

  constructor(
    public calendarService: CalendarService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly newTimeBlockGenerationService: NewTimeBlockGenerationService,
    private readonly notificationService: ShiveNotificationService,
    private readonly interactHandlerService: InteractHandlerService,
    private readonly store: Store,
    private readonly activeTimeBlockService: ActiveTimeBlockService, // Do not remove
    private readonly timeCoordinateMapperService: TimeCoordinateMappingService,
    private readonly calendarScrollbarService: CalendarScrollbarService,
    private readonly calendarCronJobsService: CalendarCronJobsService,
  ) {}

  ngOnInit(): void {
    this.calendarService.initConfigData(this.activatedRoute);
    this.calendarViewMode = this.calendarService.model.calendarViewMode;
    this.initEvents();
    this.initOvernightInterval();

    // Load all calendar specific user data (e.g. holiday templates).
    const data$ = this.calendarService.initUserRelatedCalendarData().data$;
    data$
      .pipe(
        take(1),
        switchMap(() => {
          return this.store.select(selectHolidayDates());
        }),
      )
      .subscribe((holidays) => {
        const calendarEvents = [CalendarEvents.Start];
        const today = new Date();
        const calendarModel = this.calendarService.model;
        calendarModel.holidays = holidays;
        this.calendarService.updateCalendarData(today, calendarEvents, calendarModel);
        this.finishedLoading.set(true);
      });
  }

  ngOnDestroy(): void {
    this.interactHandlerService.deregisterHandlers();
    this.calendarScrollbarService.destroyScrollbars();
    this.subs.unsubscribe();
  }

  private initEvents(): void {
    const targetEvents = [
      CalendarEvents.SwitchedCalendarViewMode,
      CalendarEvents.InitializedCalendarView,
      CalendarEvents.ChangedStartEndDates,
      CalendarEvents.RenderedTimeBlocks,
    ];
    const callback = (calendarModel: BaseCalendarModel, events: CalendarEvents[]): void => {
      if (events.includes(CalendarEvents.RenderedTimeBlocks)) {
        this.scrollToNowIndicator();
      } else {
        if (calendarModel.calendarViewMode === CalendarView.MonthGrid) {
          this.calendarScrollbarService.disableScrollbars(true);
        } else {
          this.calendarScrollbarService.calendarHeaderScrollbar.disable(true);
          this.calendarScrollbarService.calendarBodyScrollbar.disable(false);
        }
        this.calendarViewMode = calendarModel.calendarViewMode;
        this.notificationService.hide();
        this.interactHandlerService.deregisterHandlers();
      }

      // We need to wait until scrolling has been finished. There is no scrollend event for the smooth scrollbar, unfortunately.
      // So we wait a bit until scrolling should be finished.
      window.setTimeout(() => {
        this.newTimeBlockGenerationService.readyForGeneration = true;
      }, 500);
    };
    this.subs.sink = CalendarServiceHelper.calendarModelUpdated(
      this.calendarService,
      callback,
      targetEvents,
    );
  }

  private scrollToNowIndicator(): void {
    this.subs.sink = this.calendarScrollbarService.calendarBodyScrollbarInitialized$
      .pipe(
        filter((scrollbar) => scrollbar !== null),
        take(1),
      )
      .subscribe(() => {
        const firstVisibleDay = this.calendarService.model.calendarProperties.visibleStartDate;
        const weekStartsOn = this.calendarService.model.calendarProperties.weekStartsOn;
        if (
          !document.querySelector(NowIndicatorCSSId) ||
          !DateTimeHelper.isSameWeek(firstVisibleDay, new Date(), { weekStartsOn })
        ) {
          return;
        }

        const calendarBodyHeight =
          this.calendarService.model.geometryData.calendarBodyVisibleHeight;
        this.calendarScrollbarService.calendarBodyScrollbar.scrollIntoView(
          document.querySelector(NowIndicatorCSSId),
          {
            alignToTop: false,
            onlyScrollIfNeeded: false,
            offsetBottom: calendarBodyHeight / 2,
          },
        );
      });
  }

  private initOvernightInterval() {
    this.initialDate = new Date();
    this.calendarCronJobsService.registerJob(this.checkForDayChange, calendarCronjobsInterval);
  }

  /**
   * Check if the day changed (i.e. now indicator passed midnight).
   */
  @Autobind
  private checkForDayChange(): void {
    this.subs.sink = this.timeCoordinateMapperService.pixelsPerMinuteCalculated$
      .pipe(
        filter((ppm) => !!ppm),
        debounceTime(10), // For the very rare case that a user refreshes the page at exactly 00:00.
      )
      .subscribe(() => {
        const now = new Date();
        if (!DateTimeHelper.isSameDay(this.initialDate, now)) {
          this.initialDate = now;
          this.calendarService.emitCalendarChange(
            this.calendarService.model,
            CalendarEvents.NowIndicatorPassedMidnight,
          );
        }
      });
  }
}
