<div class="shive-row shive-widget-header">
  <table>
    <colgroup>
      <col class="fixed-lane" />
    </colgroup>
    <thead>
      @switch (calendarView) {
        @case (CalendarView.DayGrid) {
          <ng-container [ngTemplateOutlet]="dayHeader"></ng-container>
        }
        @case (CalendarView.WeekGrid) {
          <ng-container [ngTemplateOutlet]="weekHeader"></ng-container>
        }
        @case (CalendarView.MonthGrid) {
          <ng-container [ngTemplateOutlet]="monthHeader"></ng-container>
        }
      }
    </thead>
  </table>

  <!-- Fullday lane for day and week view -->
  @if (calendarView === CalendarView.DayGrid || calendarView === CalendarView.WeekGrid) {
    <table
      id="full-day"
      class="darker-border"
      (transitionend)="toggleFuldayContainerHeightFinished($event)"
      [ngClass]="calendarHeaderService.fulldayContainerExpanded ? 'expanded' : 'collapsed'"
    >
      <colgroup>
        <col class="fixed-lane" />
      </colgroup>
      <tbody>
        <tr>
          <td class="fixed-col k-text-right">
            <div
              class="fixed-col-wrapper k-h-full k-justify-content-between k-d-flex k-flex-col k-align-items-center"
            >
              <span class="label k-mt-0.5 noselect">Ganztags</span>
              <button
                kendoButton
                class="k-mb-2"
                rounded="full"
                size="small"
                themeColor="secondary"
                (click)="calendarHeaderService.toggleDayOrWeekViewFulldayContainer()"
                [svgIcon]="
                  calendarHeaderService.fulldayContainerExpanded ? chevronUpIcon : chevronDownIcon
                "
                id="toggle-fullday-container"
              ></button>
            </div>
          </td>
          <table class="k-pos-relative k-overflow-hidden">
            <tbody>
              <div id="calendar-header-scroll-container" class="scrollable">
                <table class="full-day-item-container k-pos-relative" #fulldayItemContainer>
                  <tbody>
                    <tr>
                      @for (day of fulldayComposites; track day.theDate; let i = $index) {
                        <td
                          appEmptyCalendarSpaceInteraction
                          class="weekday lane fullday"
                          [class.outside-of-view]="day.outsideOfView"
                          [class.inside-of-view]="!day.outsideOfView"
                          [class.first-visible-day]="day.isFirstVisibleDay"
                          [class.last-visible-day]="day.isLastVisibleDay"
                          [class.is-holiday]="day.isHoliday"
                          #timeBlockLanes
                          [isFulldayLane]="true"
                          [calendarView]="calendarView"
                          [id]="i"
                          [attr.name]="
                            DateTimeHelper.isSameDay(
                              day.theDate,
                              calendarService.model.calendarProperties.visibleStartDate
                            )
                              ? 'first'
                              : DateTimeHelper.isSameDay(
                                    day.theDate,
                                    calendarService.model.calendarProperties.visibleEndDate
                                  )
                                ? ' last'
                                : ''
                          "
                          [attr.date]="DateTimeHelper.format(day.theDate)"
                        >
                          <div class="fullday-lane-bucket">
                            <ng-container appTimeBlockContainer></ng-container>
                          </div>
                        </td>
                      }
                    </tr>
                  </tbody>
                </table>
              </div>
            </tbody>
          </table>
        </tr>
      </tbody>
    </table>
  }
</div>

<!-- Fullday lane for week view -->
<ng-template #weekHeader>
  <tr>
    <th class="timegrid-axis fixed-col"></th>
    @for (day of fulldayComposites; track day.theDate) {
      <th
        class="day-and-date"
        [class.first-visible-day]="day.isFirstVisibleDay"
        [class.last-visible-day]="day.isLastVisibleDay"
        [class.inside-of-view]="!day.outsideOfView"
        [class.outside-of-view]="day.outsideOfView"
        [class.is-holiday]="day.isHoliday"
      >
        <div class="day-wrapper k-pos-relative">
          <span class="weekday">{{ day.theDate | date: "ccc" | uppercase }}</span>
          &nbsp;
          <span class="weekdate">{{ day.theDate | date: "dd" }}</span>
          @if (day.isHoliday) {
            <fa-icon
              class="icon k-pos-absolute k-right-0 k-mr-2.5"
              [icon]="faTreeChristmas"
            ></fa-icon>
          }
        </div>
        <div class="daily-tracked-time k-d-flex k-justify-content-center">
          <div class="absolute-time">
            <span>{{ accumulatedTimeMap | timeBlockAccumulatedWork: day.theDate }}</span>
          </div>
        </div>
      </th>
    }
  </tr>
</ng-template>

<!-- Fullday lane for day view -->
<ng-template #dayHeader>
  <tr>
    <th class="timegrid-axis fixed-col"></th>
    @for (day of fulldayComposites; track day) {
      <th
        class="day-and-date"
        [class.first-visible-day]="day.isFirstVisibleDay"
        [class.last-visible-day]="day.isLastVisibleDay"
        [class.inside-of-view]="!day.outsideOfView"
        [class.outside-of-view]="day.outsideOfView"
      >
        <div class="day-wrapper">
          <div class="weekday-full">{{ day.theDate | date: "EEEE" }}</div>
        </div>
      </th>
    }
  </tr>
</ng-template>

<!-- Labels for month view -->
<ng-template #monthHeader>
  <tr>
    <th class="timegrid-axis fixed-col text-center"></th>
    @for (day of monthViewHeaderDays; track day) {
      <th
        class="day-and-date"
        [class.first-visible-day]="day.isFirstVisibleDay"
        [class.last-visible-day]="day.isLastVisibleDay"
        [class.inside-of-view]="!day.outsideOfView"
        [class.outside-of-view]="day.outsideOfView"
      >
        <div class="day-wrapper">
          <div class="weekday">{{ day.theDate | date: "ccc" | uppercase }}</div>
        </div>
      </th>
    }
  </tr>
</ng-template>
