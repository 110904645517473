import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DropdownOption } from '../../../../../shared/data-types/dropdown-option';
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { UserOperation } from '../../../../../core/enums/user-operation';
import { faCameraRetro } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-user-profile-personal-data',
  templateUrl: './user-profile-personal-data.component.html',
  styleUrl: './user-profile-personal-data.component.scss',
})
export class UserProfilePersonalDataComponent implements OnInit {
  public personalDataForm: FormGroup;
  public formControlNames: { [key: string]: string } = {
    Gender: 'gender',
    Title: 'title',
    FirstName: 'firstName',
    LastName: 'lastName',
    Email: 'email',
    Telephone: 'telephone',
    Birthday: 'birthday',
    Role: 'role',
  };
  public readonly genderTypes: DropdownOption[] = [
    { text: 'Herr', value: 0 },
    { text: 'Frau', value: 1 },
    { text: 'Divers', value: 2 },
    { text: 'Keine Angabe', value: 3 },
  ];
  public readonly roles: DropdownOption[] = [
    { text: 'Administrator', value: 0 },
    { text: 'Projektleiter', value: 1 },
    { text: 'Projektmitglied', value: 2 },
  ];
  public readonly faCirclePlus = faCirclePlus;
  public readonly UserOperation = UserOperation;
  public readonly faCameraRetro = faCameraRetro;

  private readonly formBuilder = inject(FormBuilder);

  ngOnInit(): void {
    this.personalDataForm = this.formBuilder.group({
      gender: [],
      title: [],
      firstName: [],
      lastName: [],
      email: [],
      telephone: [],
      birthday: [new Date()],
      role: [],
    });
  }

  public changePicture(): void {}
}
