import { createReducer, on } from '@ngrx/store';
import {
  purgeTimeBlockStore,
  setInitialTimeBlocks,
  timeBlockAdded,
  timeBlockDeleted,
  timeBlockUpdated,
} from './time-blocks.actions';
import * as _ from 'lodash';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { ITimeBlockComponentItem } from '../../../features/calendar/components/time-block/time-block-component-items';

export interface TimeBlocksState extends EntityState<ITimeBlockComponentItem> {}

export const timeBlockAdapter: EntityAdapter<ITimeBlockComponentItem> =
  createEntityAdapter<ITimeBlockComponentItem>();
export const initialState: TimeBlocksState = timeBlockAdapter.getInitialState();

export const timeBlocksReducer = createReducer(
  initialState,
  on(timeBlockAdded, (state, { payload }) => {
    const addedTimeBlock = payload.modifiedTimeBlockItems[0];
    const clone = _.cloneDeep(addedTimeBlock);
    return timeBlockAdapter.addOne(clone, state);
  }),
  on(timeBlockUpdated, (state, { payload }) => {
    const updatedTimeBlocks = payload.modifiedTimeBlockItems;
    const clonedTimeBlocks = updatedTimeBlocks.map((block) => _.cloneDeep(block));
    return timeBlockAdapter.setMany(clonedTimeBlocks, state);
  }),
  on(timeBlockDeleted, (state, { payload }) => {
    const id = payload.deletedTimeBlockId;
    return timeBlockAdapter.removeOne(id, state);
  }),
  on(setInitialTimeBlocks, (state, { payload }) => {
    const clonedTimeBlocks = payload.map((item) => _.cloneDeep(item));
    return timeBlockAdapter.addMany([...clonedTimeBlocks], state);
  }),
  on(purgeTimeBlockStore, (state) => {
    return timeBlockAdapter.removeAll(state);
  }),
);
