import { Injectable } from '@angular/core';
import { TimeBlockRenderService } from '../rendering/time-block-render.service';
import { SubSink } from 'subsink';
import { Observable } from 'rxjs';
import { CalendarService } from '../../../services/calendar.service';
import { TimeCoordinateMappingService } from '../../../time-mapping/time-coordinate-mapping.service';
import { CalendarEvents } from '../../../../../shared/data-types/calendar-types';
import { filter, map } from 'rxjs/operators';
import { ITimeBlockComponentItem } from '../time-block-component-items';
import { CalendarServiceHelper } from '../../../services/calendar-service-helper';
import { DateTimeHelper } from '../../../util/date-time-helper';
import { TimeBlockDurationCalculationService } from '../calculation/duration/time-block-duration-calculation.service';
import { TimeBlockContentType } from '../../../../../shared/data-types/time-block-types';
import { TimeBlockProjectModel } from '../../../../../core/models/timeblock/time-block.model';

@Injectable()
export class TimeBlockService {
  private readonly subs = new SubSink();

  constructor(
    private readonly calendarService: CalendarService,
    private readonly timeBlockDurationCalculationService: TimeBlockDurationCalculationService,
    private readonly timeBlockRenderService: TimeBlockRenderService,
    private readonly timeCoordinateMapperService: TimeCoordinateMappingService,
  ) {
    this.initEvents();
  }

  public getMinimumBlockHeight(): Observable<number> {
    return this.timeCoordinateMapperService.pixelsPerMinuteCalculated$.pipe(
      filter((ppm) => !!ppm),
      map((ppm) => {
        const minimumTimeBlockDuration =
          this.calendarService.model.calendarProperties.minimumTimeBlockDuration;
        if (ppm && minimumTimeBlockDuration) {
          return ppm * minimumTimeBlockDuration;
        }
        return null;
      }),
    );
  }

  public isTimeBlockOutsideOfView(timeBlockComponentItem: ITimeBlockComponentItem): boolean {
    const timeBlockModel = timeBlockComponentItem.timeBlockModel;
    const visibleCalendarStart = this.calendarService.model.calendarProperties.visibleStartDate;
    const visibleCalendarEnd = this.calendarService.model.calendarProperties.visibleEndDate;

    return (
      (DateTimeHelper.isBefore(timeBlockModel.start, visibleCalendarStart) &&
        DateTimeHelper.isBefore(timeBlockModel.end, visibleCalendarStart)) ||
      (DateTimeHelper.isAfter(timeBlockModel.start, visibleCalendarEnd) &&
        DateTimeHelper.isAfter(timeBlockModel.end, visibleCalendarEnd))
    );
  }

  public getTimeBlockVisiblePartCount(timeBlockComponentItem: ITimeBlockComponentItem): number {
    const visibleCalendarStart = this.calendarService.model.calendarProperties.visibleStartDate;
    const visibleCalendarEnd = this.calendarService.model.calendarProperties.visibleEndDate;

    const timeBlockModel = timeBlockComponentItem.timeBlockModel;
    const visibleTimeBlockStart = DateTimeHelper.isBefore(
      timeBlockModel.start,
      visibleCalendarStart,
    )
      ? visibleCalendarStart
      : timeBlockModel.start;
    const visibleTimeBlockEnd = DateTimeHelper.isAfter(timeBlockModel.end, visibleCalendarEnd)
      ? visibleCalendarEnd
      : timeBlockModel.end;

    return DateTimeHelper.differenceInDays(visibleTimeBlockEnd, visibleTimeBlockStart);
  }

  /**
   * A default time block is a time block with project of null (could be a tracked time block, too).
   * @param timeBlock
   */
  public isDefaultTimeBlock(timeBlock: ITimeBlockComponentItem): boolean {
    return (
      timeBlock.timeBlockContentType === TimeBlockContentType.Project &&
      !(timeBlock.timeBlockModel as TimeBlockProjectModel).project
    );
  }

  private initEvents(): void {
    const targetEvents = [CalendarEvents.AddedTimeBlock, CalendarEvents.ReplacedTimeBlock];
    const callback = (_: any, _1: CalendarEvents[], timeBlock: ITimeBlockComponentItem): void => {
      if (
        timeBlock?.timeBlockModel.componentRef &&
        typeof timeBlock.timeBlockModel.timeBlockViewType.geometryData?.top !== 'undefined'
      ) {
        this.timeBlockRenderService.renderTimeBlock(timeBlock);
      }
    };
    this.subs.sink = CalendarServiceHelper.calendarModelUpdated(
      this.calendarService,
      callback,
      targetEvents,
    );

    this.timeBlockDurationCalculationService.initEvents();
  }
}
