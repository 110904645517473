import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserModel } from '../../../core/models/user/user.model';
import { take } from 'rxjs/operators';
import { UsersHttpService } from '../../../core/state/users/users-http.service';

@Injectable({
  providedIn: 'root',
})
export class ListTeamDataService {
  public gridData$: Observable<UserModel[]> = null;

  constructor(private readonly usersHttpService: UsersHttpService) {}

  public loadGridData(): void {
    this.gridData$ = this.usersHttpService.getUsers(true).pipe(take(1));
  }
}
