import { Injectable } from '@angular/core';
import { UsersHttpService } from '../../../../../core/state/users/users-http.service';
import { UserModel } from '../../../../../core/models/user/user.model';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BillingEntry, MemberGridRow } from '../../../../../shared/data-types/project-types';
import { ProjectModel } from '../../../../../core/models/project/project.model';
import { ProjectService } from '../../../services/project.service';
import { assert } from '../../../../../core/assert/assert';
import {
  BillableRateOptions,
  BudgetOptions,
} from '../../../../../core/data-repository/dropdown-constants';
import { ProjectBillingBudgetTransformerService } from './project-billing-budget-transformer.service';
import { BillingEntryService } from '../manage-project/project-calculation-form/services/billing-entry.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectMemberService {
  constructor(
    private readonly usersHttpService: UsersHttpService,
    private readonly projectService: ProjectService,
    private readonly projectBillingBudgetTransformer: ProjectBillingBudgetTransformerService,
    private readonly billingEntryService: BillingEntryService,
  ) {}

  public loadAllProjectMembers(filterSelf?: boolean): Observable<readonly UserModel[]> {
    return this.usersHttpService.getUsers(filterSelf).pipe(take(1));
  }

  public removeMember(member: UserModel, gridData: MemberGridRow[]): MemberGridRow[] {
    return gridData.filter((memberGridRow) => memberGridRow.member.id !== member.id);
  }

  public resetBilling(gridData: MemberGridRow[], dateFrom: Date): void {
    gridData.forEach((row, index) => {
      row.billingRateHistory = [this.billingEntryService.createBillingEntry(0, dateFrom, index)];
    });
  }

  public resetBudget(gridData: MemberGridRow[]): void {
    gridData.forEach((row) => {
      row.budgetHours = 0;
    });
  }

  public getHistoryByMemberId(gridData: MemberGridRow[], memberId: number): BillingEntry[] {
    const memberGridRow = gridData.find((x) => x.member.id === memberId);
    assert(memberGridRow !== undefined);
    return memberGridRow.billingRateHistory;
  }

  public setHistoryForMemberId(
    gridData: MemberGridRow[],
    memberId: number,
    billingRateHistory: BillingEntry[],
  ): void {
    const memberGridRow = gridData.find((memberGridRow) => memberGridRow.member.id === memberId);
    assert(memberGridRow !== undefined);
    memberGridRow.billingRateHistory = billingRateHistory;
  }

  public syncProjectModel(gridData: MemberGridRow[], project: ProjectModel): void {
    const [billingEntries, budgetEntries] =
      this.projectBillingBudgetTransformer.transformMemberGridDataToBillingAndBudgetEntries(
        gridData,
      );
    if (project.billingType === BillableRateOptions.BillableHourlyValuePerMember) {
      project.projectBillingEntries = billingEntries;
    }
    if (project.budgetType === BudgetOptions.HoursPerMember) {
      project.projectBudgetEntries = budgetEntries;
    }
    project.members = gridData.map((row) => row.member);
    this.projectService.updateProject(project);
  }
}
