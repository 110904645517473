import { TimeBlockCrudService } from '../../../../crud/time-block-crud.service';
import { CalendarScrollbarService } from '../../../../../../services/calendar-scrollbar.service';
import { filter, take } from 'rxjs/operators';
import { TimeBlockDragResizeControllerService } from '../time-block-drag-resize-handling/time-block-drag-resize-controller.service';
import { TimeBlockType } from '../../../../../../../../shared/data-types/time-block-types';
import { TimeBlockDialogService } from '../../../../services/time-block-dialog.service';

export abstract class AbstractResizeController {
  protected constructor(
    private readonly tbDragResizeControllerService: TimeBlockDragResizeControllerService,
    private readonly tbDialogService: TimeBlockDialogService,
    private readonly tbCrudService: TimeBlockCrudService,
  ) {}

  protected abstract updateTimeBlockSchedule(): void;

  public afterResizing(): void {
    const resizedTimeBlock = this.tbDragResizeControllerService.TransformationTimeBlock;

    if (resizedTimeBlock.timeBlockModel.type === TimeBlockType.NonExistingBlock) {
      // If a new non existing time block is created via resizing a dummy block, the geometry data has to be updated in the calendar model.
      // This needs to be done since the current geometry data of the time block is needed after clicking on "Save" button in dialog.
      this.tbCrudService.replaceTimeBlock(resizedTimeBlock);
      this.openNewTimeBlockDialog();
    } else {
      // Remove the dragging block from the calendar model.
      this.tbCrudService.removeTimeBlock(resizedTimeBlock, TimeBlockType.TransformingBlock);
      // Insert a new time block item into the calendar.
      resizedTimeBlock.timeBlockModel.type = TimeBlockType.ExistingBlock;
      resizedTimeBlock.timeBlockModel.timeBlockViewType.geometryData.containerLeftEdgeToTimeBlockLeftEdge = 0;
      this.tbCrudService.insertTimeBlock(resizedTimeBlock);
    }

    // Remove transparent indicator block (ghost block)
    if (this.tbDragResizeControllerService.GhostTimeBlock) {
      this.tbCrudService.removeTimeBlock(
        this.tbDragResizeControllerService.GhostTimeBlock,
        TimeBlockType.GhostBlock,
      );
      this.tbDragResizeControllerService.GhostTimeBlock = null;
    }
  }

  /**
   * Called when creating a new time block. Wait until scrolling has been finished, since the highlighted time block geometry would be
   * incorrect otherwise.
   */
  private openNewTimeBlockDialog(): void {
    CalendarScrollbarService.ScrollState$.pipe(
      filter((state) => !state),
      take(1),
    ).subscribe(() => {
      this.tbDialogService.openNewTimeBlockDialog(
        this.tbDragResizeControllerService.TransformationTimeBlock,
      );
    });
  }
}
