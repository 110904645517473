import { Injectable } from '@angular/core';
import Autobind from '../../../../../../../../../shared/typescript-decorators/autobind.decorator';
import { TimeBlockElementSelectorService } from '../../../../../rendering/time-block-element-selector.service';
import { TimeBlockDragResizeControllerService } from '../../time-block-drag-resize-handling/time-block-drag-resize-controller.service';
import { TimeBlockRenderService } from '../../../../../rendering/time-block-render.service';
import { TimeBlockCrudService } from '../../../../../crud/time-block-crud.service';
import { TimeBlockStructureService } from '../../../../../time-block-structure/time-block-structure.service';
import { CalendarService } from '../../../../../../../services/calendar.service';
import { CalendarMouseHandlerService } from '../../../../../../../mouse/calendar-mouse-handler.service';
import { CalendarServiceHelper } from '../../../../../../../services/calendar-service-helper';
import { CalendarGeometryService } from '../../../../../../../services/calendar-geometry.service';
import { AbstractDragController } from '../drag-and-drop/abstract-drag-controller';
import { DragAndDropHandlerService } from '../drag-and-drop/drag-and-drop-handler.service';
import { DragDropPositioningService } from '../drag-and-drop/drag-drop-positioning.service';
import { IDragTimeBlock } from '../../../../../../../../../shared/data-types/time-block-types';
import { TimeBlockService } from '../../../../../services/time-block.service';
import { SharedHorizontalDragResizeService } from '../../time-block-drag-resize-handling/shared-horizontal-drag-resize.service';
import {
  FulldayContainerTopSpacing,
  HorizontalTimeBlockGapPx,
} from '../../../../../../../../../core/data-repository/css-constants';

@Injectable()
export class HorizontalDayOrWeekFullDayDragControllerService
  extends AbstractDragController
  implements IDragTimeBlock
{
  constructor(
    private readonly timeBlockDragResizeControllerService: TimeBlockDragResizeControllerService,
    private readonly dragAndDropHandlerService: DragAndDropHandlerService,
    private readonly calendarService: CalendarService,
    private readonly calendarMouseHandlerService: CalendarMouseHandlerService,
    private readonly calendarGeometryService: CalendarGeometryService,
    private readonly dragDropPositioningService: DragDropPositioningService,
    private readonly timeBlockCrudService: TimeBlockCrudService,
    private readonly timeBlockStructureService: TimeBlockStructureService,
    private readonly timeBlockService: TimeBlockService,
    private readonly sharedDraggingResizingService: SharedHorizontalDragResizeService,
    private readonly timeBlockRenderService: TimeBlockRenderService,
  ) {
    super(
      timeBlockCrudService,
      timeBlockStructureService,
      dragDropPositioningService,
      timeBlockRenderService,
      timeBlockDragResizeControllerService,
      timeBlockService,
      dragAndDropHandlerService,
      sharedDraggingResizingService,
    );
  }

  @Autobind
  public drag(): void {
    const draggingBlock = this.timeBlockDragResizeControllerService.TransformationTimeBlock;
    if (!draggingBlock?.timeBlockModel) {
      return;
    }
    const calendarModel = this.calendarService.getCalendarModelByTimeBlockType(draggingBlock);

    let mouseLaneIndex = this.calendarMouseHandlerService.calcVisibleMouseLaneIndex(
      this.calendarMouseHandlerService.MouseMovePositionWrapper.mouseCalendarPosition.x,
      null,
      true,
    );
    mouseLaneIndex = CalendarServiceHelper.toInvisibleLaneIndex(calendarModel, mouseLaneIndex);

    if (mouseLaneIndex !== this.sharedDraggingResizingService.lastMouseLaneIndex) {
      const laneDiff = mouseLaneIndex - this.sharedDraggingResizingService.lastMouseLaneIndex;
      this.dragAndDropHandlerService.targetContainerId += laneDiff;

      const transformationTimeBlock =
        this.timeBlockDragResizeControllerService.TransformationTimeBlock;
      const timeBlockHTMLWrapper =
        TimeBlockElementSelectorService.getTimeBlockHTMLWrapper(transformationTimeBlock);

      const laneWidth = this.calendarGeometryService.getDayOrWeekFulldayLaneWidth();
      const timeBlockLength = laneWidth * laneDiff;
      this.dXAbs += timeBlockLength;
      const moveTop = FulldayContainerTopSpacing + HorizontalTimeBlockGapPx;
      this.timeBlockRenderService.moveElement(timeBlockHTMLWrapper, this.dXAbs, moveTop);

      this.sharedDraggingResizingService.lastMouseLaneIndex = mouseLaneIndex;
    }
  }
}
