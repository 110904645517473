@switch (type) {
  @case (NotificationType.Success) {
    <ng-container *ngTemplateOutlet="successTemplate; context: { content: content }">
    </ng-container>
  }
  @case (NotificationType.Warning) {
    <ng-container *ngTemplateOutlet="warningTemplate; context: { content: content }">
    </ng-container>
  }
  @case (NotificationType.Error) {
    <ng-container *ngTemplateOutlet="errorTemplate; context: { content: content }"> </ng-container>
  }
  @case (NotificationType.Info) {
    <ng-container *ngTemplateOutlet="infoTemplate; context: { content: content }"> </ng-container>
  }
  @default {
    <ng-container *ngTemplateOutlet="noneTemplate; context: { content: content }"> </ng-container>
  }
}

<div id="progress-bar-container" class="k-w-full">
  <div id="progress-bar" class="k-w-full"></div>
</div>

<ng-template #successTemplate>
  <div class="k-d-flex">
    <span class="custom-symbol notification-success k-mr-2.5"></span>
    <span class="k-mr-3.5">Gespeichert!</span>
    <a class="revert">Rückgängig machen</a>

    <ng-template [ngTemplateOutlet]="closeBtn"></ng-template>
  </div>
</ng-template>

<ng-template #warningTemplate let-content="content">
  <div class="k-d-flex">
    <span class="custom-symbol notification-warning k-mr-4"></span>
    <span class="k-font-bold k-mr-1.5">Warning:</span>
    <span>{{ content }}</span>
  </div>
</ng-template>

<ng-template #errorTemplate let-content="content">
  <div class="k-d-flex">
    <span class="custom-symbol notification-error k-mr-4"></span>
    <span class="k-font-bold k-mr-1.5">Error:</span>
    <span>{{ content }}</span>

    <ng-template [ngTemplateOutlet]="closeBtn"></ng-template>
  </div>
</ng-template>

<ng-template #infoTemplate let-content="content">
  <div class="k-d-flex">
    <span class="custom-symbol notification-info k-mr-4"></span>
    <span class="k-font-bold k-mr-1.5">Info:</span>
    <span>{{ content }}</span>

    <ng-template [ngTemplateOutlet]="closeBtn"></ng-template>
  </div>
</ng-template>

<ng-template #noneTemplate let-content="content">
  <div class="k-d-flex">
    <span>{{ content }}</span>

    <ng-template [ngTemplateOutlet]="closeBtn"></ng-template>
  </div>
</ng-template>

<ng-template #closeBtn>
  <div
    id="notification-close"
    (click)="notificationRef.hide()"
    style="cursor: pointer"
    class="k-ml-5"
  >
    <span class="k-icon k-i-close"></span>
  </div>
</ng-template>
