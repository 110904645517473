import { UrlSegmentDelimiter } from '../../data-repository/css-constants';

export class ShiveRoutes {
  // ////// Generic routes
  public static readonly Create = 'create';
  public static readonly Edit = 'edit';
  public static readonly Detail = 'detail';
  public static readonly Status = 'status';
  public static readonly Overview = 'overview';

  // Shared Routes
  public static readonly Attachments = 'attachments';
  public static readonly Logs = 'logs';
  public static readonly BaseData = 'base-data';
  public static readonly AnyId = '*';

  // Dashboard
  public static readonly Dashboard = 'dashboard';

  // Calendar
  public static readonly TimeTracking = 'time-tracking';

  // Projects
  public static readonly Projects = 'projects';
  public static readonly ProjectOverview =
    ShiveRoutes.Projects + UrlSegmentDelimiter + ShiveRoutes.Overview;
  public static readonly ProjectAdd =
    ShiveRoutes.Projects + UrlSegmentDelimiter + ShiveRoutes.Create;
  public static readonly ProjectStatus =
    ShiveRoutes.Projects +
    UrlSegmentDelimiter +
    ShiveRoutes.Detail +
    UrlSegmentDelimiter +
    ShiveRoutes.Status;
  public static readonly ProjectEdit =
    ShiveRoutes.Projects +
    UrlSegmentDelimiter +
    ShiveRoutes.Detail +
    UrlSegmentDelimiter +
    ShiveRoutes.Edit;
  public static readonly ProjectAttachments =
    ShiveRoutes.Projects +
    UrlSegmentDelimiter +
    ShiveRoutes.Detail +
    UrlSegmentDelimiter +
    ShiveRoutes.Attachments;
  public static readonly ProjectLogs =
    ShiveRoutes.Projects +
    UrlSegmentDelimiter +
    ShiveRoutes.Detail +
    UrlSegmentDelimiter +
    ShiveRoutes.Logs;

  // Clients
  public static readonly Clients = 'clients';
  public static readonly ClientList =
    ShiveRoutes.Clients + UrlSegmentDelimiter + ShiveRoutes.Overview;
  public static readonly ClientAdd = ShiveRoutes.Clients + UrlSegmentDelimiter + ShiveRoutes.Create;
  public static readonly ClientEdit = ShiveRoutes.Clients + UrlSegmentDelimiter + ShiveRoutes.Edit;

  // Team
  public static readonly Team = 'team';
  public static readonly Absences = 'absences';
  public static readonly Planner = 'planner';
  public static readonly VacationQuota = 'vacation-quota';
  public static readonly LeaveRequests = 'leave-requests';

  public static readonly TeamOverview =
    ShiveRoutes.Team + UrlSegmentDelimiter + ShiveRoutes.Overview;
  public static readonly TeamAbsences = `${ShiveRoutes.Team + UrlSegmentDelimiter + ShiveRoutes.Absences}`;
  public static readonly TeamAbsencePlanner = `${ShiveRoutes.Team + UrlSegmentDelimiter + ShiveRoutes.Absences + UrlSegmentDelimiter + ShiveRoutes.Planner}`;
  public static readonly TeamVacationQuota = `${ShiveRoutes.Team + UrlSegmentDelimiter + ShiveRoutes.Absences + UrlSegmentDelimiter + ShiveRoutes.VacationQuota}`;
  public static readonly TeamLeaveRequests = `${ShiveRoutes.Team + UrlSegmentDelimiter + ShiveRoutes.Absences + UrlSegmentDelimiter + ShiveRoutes.LeaveRequests}`;

  // User
  public static readonly UserProfile = 'user-profile';
  public static readonly UserProfileBaseData =
    ShiveRoutes.UserProfile + UrlSegmentDelimiter + ShiveRoutes.BaseData;
  public static readonly UserProfileAttachments =
    ShiveRoutes.UserProfile + UrlSegmentDelimiter + ShiveRoutes.Attachments;

  // /////// Settings
  public static readonly Settings = 'settings';

  // Time Off
  public static readonly TimeOff = 'time-off';
  public static readonly SettingsNonworkingTime =
    ShiveRoutes.Settings + UrlSegmentDelimiter + ShiveRoutes.TimeOff;
}
