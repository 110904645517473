import { Injectable } from '@angular/core';
import { assert } from '../../../core/assert/assert';

type CronJob = {
  func: () => void;
  interval: number;
  lastExecution: number;
};

@Injectable()
export class CalendarCronJobsService {
  private readonly jobs: CronJob[] = [];
  constructor() {
    this.executeCronjobs();
  }

  registerJob(func: () => void, interval: number): number {
    this.jobs.push({
      func,
      interval,
      lastExecution: Date.now(),
    });
    return this.jobs.length - 1;
  }

  deregisterJob(cronJobIndex: number): void {
    assert(cronJobIndex >= 0 && cronJobIndex <= this.jobs.length - 1, 'Index out of bound.');
    this.jobs.splice(cronJobIndex, 1);
  }

  private executeCronjobs(): void {
    window.setInterval(() => {
      const now = Date.now();
      this.jobs.forEach((cronJob) => {
        if (now - cronJob.lastExecution >= cronJob.interval) {
          cronJob.func();
          cronJob.lastExecution = now;
        }
      });
    }, 100);
  }
}
