import { Component, ElementRef, HostListener, TemplateRef } from '@angular/core';
import { ContextMenuItem } from '../../../data-types/context-menu-item-type';
import { ShivePopupService } from '../../../../core/services/controls/shive-popup.service';

@Component({
  selector: 'app-shive-context-menu',
  templateUrl: './shive-context-menu.component.html',
})
export class ShiveContextMenuComponent {
  public items: ContextMenuItem[] = [];
  public data: any;
  public name = 'Item';
  public header: TemplateRef<HTMLElement> = null;
  public footer: TemplateRef<HTMLElement> = null;

  constructor(private readonly shivePopupService: ShivePopupService) {}

  @HostListener('document:keydown', ['$event'])
  public keydown(event: KeyboardEvent): void {
    if (event.code === 'Escape') {
      this.shivePopupService.popupRef.close();
    }
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: KeyboardEvent): void {
    if (!this.contains(event.target as Node)) {
      this.shivePopupService.popupRef.close();
    }
  }

  public click(item: ContextMenuItem): void {
    item.callback(this.data);
    this.shivePopupService.popupRef.close();
  }

  private contains(target: Node): boolean {
    const popup = this.shivePopupService.popupRef.popup.location;
    const anchorRef = this.shivePopupService.anchor;
    const anchor = anchorRef instanceof ElementRef ? anchorRef.nativeElement : anchorRef;

    return anchor.contains(target) || (popup ? popup.nativeElement.contains(target) : false);
  }
}
