@if (projectBaseForm !== null) {
  <div class="form-wrapper">
    <form [formGroup]="projectBaseForm" class="k-form two-column-layout k-d-grid">
      <div class="col">
        <kendo-formfield orientation="horizontal">
          <kendo-label class="!k-align-items-start" [for]="name" text="Projektname"></kendo-label>
          <kendo-textbox
            showErrorIcon="initial"
            [id]="formControlNames.ProjectName"
            [formControlName]="formControlNames.ProjectName"
            (valueChange)="formValueChanged([$event, formControlNames.ProjectName])"
            #name
          >
          </kendo-textbox>
          <kendo-formerror>Error: Username is required</kendo-formerror>
        </kendo-formfield>

        <kendo-formfield orientation="horizontal">
          <kendo-label class="!k-align-items-start" [for]="projectId" text="Projekt ID">
          </kendo-label>
          <kendo-textbox
            showErrorIcon="initial"
            [id]="formControlNames.ProjectId"
            [formControlName]="formControlNames.ProjectId"
            (valueChange)="formValueChanged([$event, formControlNames.ProjectId])"
            #projectId
          >
          </kendo-textbox>
          <kendo-formerror>Error: Username is required</kendo-formerror>
        </kendo-formfield>

        <kendo-formfield orientation="horizontal">
          <kendo-label class="!k-align-items-start" [for]="projectId" text="Projektmanager">
          </kendo-label>
          <kendo-textbox
            showErrorIcon="initial"
            [id]="formControlNames.ProjectId"
            [formControlName]="formControlNames.ProjectId"
            (valueChange)="formValueChanged([$event, formControlNames.ProjectId])"
            #projectId
          >
          </kendo-textbox>
          <kendo-formerror>Error: Username is required</kendo-formerror>
        </kendo-formfield>

        <app-shive-dropdownlist
          [data]="clients"
          [label]="'Kunde'"
          [selectedValue]="selectedClient"
          (addBtnClicked)="openClientDialog()"
          (afterValueChanged)="formValueChanged([$event, formControlNames.Client])"
          [permittedUserOperations]="[UserOperation.Create]"
        >
        </app-shive-dropdownlist>

        <kendo-formfield orientation="horizontal">
          <kendo-label text="Währung" [for]="currency"></kendo-label>
          <kendo-dropdownlist
            textField="text"
            valueField="value"
            [data]="clients"
            [id]="formControlNames.Currency"
            [formControlName]="formControlNames.Currency"
            (valueChange)="formValueChanged([$event, formControlNames.Currency])"
            #currency
          >
          </kendo-dropdownlist>
        </kendo-formfield>
      </div>

      <div class="col">
        <div id="project-start-end" class="k-d-grid k-align-items-center">
          <kendo-label [for]="startsAt" text="Laufzeit"></kendo-label>
          <kendo-formfield orientation="horizontal" class="!k-d-block">
            <kendo-datepicker
              [id]="formControlNames.StartsAt"
              [formControlName]="formControlNames.StartsAt"
              (valueChange)="formValueChanged([$event, formControlNames.StartsAt])"
              #startsAt
            >
            </kendo-datepicker>
          </kendo-formfield>

          <span class="k-mx-2.5">bis</span>

          <kendo-formfield orientation="horizontal" class="!k-d-block">
            <kendo-datepicker
              [id]="formControlNames.EndsAt"
              [formControlName]="formControlNames.EndsAt"
              (valueChange)="formValueChanged([$event, formControlNames.EndsAt])"
            >
            </kendo-datepicker>
          </kendo-formfield>
        </div>

        <kendo-formfield orientation="horizontal">
          <kendo-label [for]="status" text="Status"></kendo-label>
          <kendo-dropdownlist
            textField="text"
            valueField="value"
            [data]="statusOptions"
            [id]="formControlNames.Status"
            [formControlName]="formControlNames.Status"
            (valueChange)="formValueChanged([$event, formControlNames.ProjectStatus])"
            #status
          >
          </kendo-dropdownlist>
        </kendo-formfield>

        <kendo-formfield orientation="horizontal">
          <kendo-label text="Projektfarbe" [for]="projectColor"></kendo-label>
          <kendo-colorpicker
            [format]="'hex'"
            class="k-w-24"
            [paletteSettings]="{ palette }"
            [id]="formControlNames.ProjectColor"
            [formControlName]="formControlNames.ProjectColor"
            (valueChange)="formValueChanged([$event, formControlNames.ProjectColor])"
            [views]="['palette']"
            #projectColor
          >
          </kendo-colorpicker>
        </kendo-formfield>

        <kendo-formfield orientation="horizontal">
          <kendo-label
            [for]="remark"
            class="align-self-baseline k-pt-3"
            text="Anmerkungen"
          ></kendo-label>
          <kendo-textarea
            [rows]="3"
            [id]="formControlNames.Remark"
            [formControlName]="formControlNames.Remark"
            (valueChange)="formValueChanged([$event, formControlNames.Remark])"
            resizable="none"
            #remark
          >
          </kendo-textarea>
        </kendo-formfield>
      </div>
    </form>
  </div>
}
