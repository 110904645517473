import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  BillableRateOptions,
  BudgetOptions,
} from 'src/app/core/data-repository/dropdown-constants';
import { cloneDeep } from 'lodash-es';
import { ProjectService } from '../../../../services/project.service';

@Component({
  selector: 'app-project-calculation-form',
  templateUrl: './project-calculation-form.component.html',
})
export class ProjectCalculationFormComponent implements OnInit {
  @Output() readonly selectedBillableRateOptionChanged$ = new EventEmitter();
  @Output() readonly selectedBudgetOptionChanged$ = new EventEmitter<BudgetOptions>();
  public selectedBillableRateOption: BillableRateOptions;
  public readonly BillableRateOptions = BillableRateOptions;

  constructor(private readonly projectService: ProjectService) {}

  ngOnInit(): void {
    const projectClone = this.projectService.getProjectClone();
    this.selectedBillableRateOption = projectClone.billingType;
  }

  public billableRateOptionChanged($event: BillableRateOptions): void {
    const project = this.projectService.getProjectClone();
    const clonedProject = cloneDeep(project);
    this.selectedBillableRateOption = $event;
    clonedProject.billingType = this.selectedBillableRateOption;

    this.selectedBillableRateOptionChanged$.next($event);
    this.projectService.updateProject(clonedProject);
    this.projectService.userOperationConducted$.next(true);
  }

  public budgetOptionChanged($event: BudgetOptions): void {
    const project = this.projectService.getProjectClone();
    const clonedProject = cloneDeep(project);
    clonedProject.budgetType = $event;

    this.selectedBudgetOptionChanged$.next($event);
    this.projectService.updateProject(clonedProject);
    this.projectService.userOperationConducted$.next(true);
  }
}
