import { Component, ElementRef, Input, ViewChild, signal } from '@angular/core';
import { faCirclePlus, faWaveTriangle } from '@fortawesome/pro-regular-svg-icons';
import { BillingEntry } from '../../data-types/project-types';

@Component({
  selector: 'app-history-time-line',
  templateUrl: './history-time-line.component.html',
  styleUrl: './history-time-line.component.scss',
})
export class HistoryTimeLineComponent {
  @Input({ required: true }) billingRateHistory!: BillingEntry[];
  @ViewChild('historyTimeLine') historyTimeLine: ElementRef<HTMLUListElement>;
  public readonly faCirclePlus = faCirclePlus;
  public readonly faWaveTriangle = faWaveTriangle;
  public readonly maxVisibleItems = 3;
  public collapsed = signal(true);

  public toggleHistory() {
    this.collapsed.update((value) => !value);
  }
}
