import { Injectable } from '@angular/core';
import { DropdownOption } from '../../../../shared/data-types/dropdown-option';
import { CalendarView } from '../../../../shared/data-types/calendar-types';
import { CalendarInteractionService } from '../../interaction/calendar-interaction.service';

@Injectable()
export class ControlBarService {
  public activeCalendarView: CalendarView;

  constructor(public calendarInteractionService: CalendarInteractionService) {}

  public getTitle(): string {
    switch (this.activeCalendarView) {
      case CalendarView.DayGrid:
        return 'Tagesarbeitszeit';
      case CalendarView.WeekGrid:
        return 'Wochenarbeitszeit';
      case CalendarView.MonthGrid:
        return 'Monatsarbeitszeit';
      default:
        return '';
    }
  }

  public switchView(option: DropdownOption): void {
    this.activeCalendarView = CalendarView[CalendarView[+option.value]];
    this.calendarInteractionService.switchCalendarView(this.activeCalendarView);
  }
}
