import { ElementRef, Injectable, QueryList, inject } from '@angular/core';
import { ITimeBlockComponentItem } from '../time-block-component-items';
import {
  TimeBlockContentType,
  TimeBlockType,
} from '../../../../../shared/data-types/time-block-types';
import { DateTimeHelper } from '../../../util/date-time-helper';
import { TimeBlockAbsenceModel } from '../../../../../core/models/timeblock/time-block.model';
import { makeSafeForCSS } from '../../../../../shared/functions/string-functions';
import { TimeBlockRenderService } from '../rendering/time-block-render.service';
import { CalendarService } from '../../../services/calendar.service';
import { CSSHasNextPart, CSSHasPrevPart } from '../../../../../core/data-repository/css-constants';

@Injectable({
  providedIn: 'root',
})
export class TimeBlockCSSService {
  private readonly timeBlockRenderService = inject(TimeBlockRenderService);
  private readonly calendarService = inject(CalendarService);

  public configureFulldayOverflowIndicators(
    timeBlockComponentItem: ITimeBlockComponentItem,
    overflowIndicators: QueryList<ElementRef<HTMLElement>>,
  ): void {
    const timeBlockModel = timeBlockComponentItem.timeBlockModel;
    if (
      timeBlockModel.partNumber === 0 &&
      timeBlockModel.isFullday &&
      timeBlockModel.type === TimeBlockType.ExistingBlock
    ) {
      const calendarModel =
        this.calendarService.getCalendarModelByTimeBlockType(timeBlockComponentItem);
      if (
        DateTimeHelper.isBefore(
          timeBlockComponentItem.timeBlockModel.start,
          calendarModel.calendarProperties.visibleStartDate,
        )
      ) {
        const leftIndicatorHTMLEl = overflowIndicators.first.nativeElement;
        this.timeBlockRenderService.removeClass(leftIndicatorHTMLEl, 'k-d-none');
      }

      if (
        DateTimeHelper.isAfter(
          timeBlockComponentItem.timeBlockModel.end,
          calendarModel.calendarProperties.visibleEndDate,
        )
      ) {
        const rightIndicatorHTMLEl = overflowIndicators.last.nativeElement;
        this.timeBlockRenderService.removeClass(rightIndicatorHTMLEl, 'k-d-none');
      }
    }
  }

  /**
   * Previous and next classes apply only for day and week inner day time blocks.
   */
  public setTimeBlockPrevNextCSSClasses(
    timeBlockComponentItem: ITimeBlockComponentItem,
    timeBlockHtmlWrapper: ElementRef<HTMLElement>,
  ): void {
    const partNr = timeBlockComponentItem.timeBlockModel.partNumber;
    const partCount = timeBlockComponentItem.timeBlockModel.partCount;
    if (partNr === 0) {
      this.timeBlockRenderService.removeClass(timeBlockHtmlWrapper.nativeElement, CSSHasPrevPart);
    }

    if (partNr === partCount - 1) {
      this.timeBlockRenderService.removeClass(timeBlockHtmlWrapper.nativeElement, CSSHasNextPart);
    }
  }

  public getTimeBlockContentTypeClass(timeBlockComponentItem: ITimeBlockComponentItem): string {
    if (timeBlockComponentItem.timeBlockContentType === TimeBlockContentType.Absence) {
      const absenceReason = (timeBlockComponentItem.timeBlockModel as TimeBlockAbsenceModel).reason;
      return typeof absenceReason !== 'string' ? '' : makeSafeForCSS(absenceReason);
    }

    return '';
  }
}
