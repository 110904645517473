import { Routes } from '@angular/router';
import { CalendarComponent } from '../../../features/calendar/calendar.component';
import { DashboardComponent } from '../../../features/dashboard/dashboard.component';
import { NotFoundComponent } from '../../../shared/components/not-found/not-found.component';
import { pageMap } from '../mapping/page-mapping';
import { ProjectComponent } from '../../../features/project/project.component';
import { ClientComponent } from '../../../features/client/client.component';
import { CreateEditClientComponent } from '../../../features/client/create-edit-client/create-edit-client.component';
import { ShiveRoutes } from '../segments/route-segments';
import { ListClientComponent } from '../../../features/client/list-client/list-client.component';
import { UrlSegmentDelimiter } from '../../data-repository/css-constants';
import { SettingsComponent } from '../../../features/settings/settings.component';
import { TimeOffComponent } from '../../../features/settings/time-off/time-off.component';
import { PendingChangesGuard } from '../../guards/pending-changes.guard';
import { TeamComponent } from '../../../features/team/team.component';
import { ProjectRoutes } from './project.routes';
import { UserProfileComponent } from '../../../features/user/user-profile/user-profile.component';
import { PageKey } from '../page-keys';
import { UserProfileRoutes } from './user-profile.routes';
import { userProfileResolver } from '../../resolver/user/user-profile.resolver';
import { TeamRoutes } from './team.routes';

export const AdminLayoutRoutes: Routes = [
  {
    path: '',
    component: DashboardComponent,
  },
  {
    path: pageMap.getRoute(PageKey.DashboardPageKeys.Dashboard),
    component: DashboardComponent,
  },
  {
    path: pageMap.getRoute(PageKey.CalendarPageKeys.Calendar),
    component: CalendarComponent,
  },
  {
    path: pageMap.getRoute(PageKey.ProjectPageKeys.Projects),
    component: ProjectComponent,
    children: ProjectRoutes,
  },
  {
    path: pageMap.getRoute(PageKey.ClientPageKeys.Clients),
    component: ClientComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: ShiveRoutes.ClientList,
      },
      {
        path: ShiveRoutes.Overview,
        component: ListClientComponent,
      },
      {
        path: ShiveRoutes.Create,
        component: CreateEditClientComponent,
      },
      {
        path: `${ShiveRoutes.Edit + UrlSegmentDelimiter}:id`,
        component: CreateEditClientComponent,
      },
    ],
  },
  {
    path: pageMap.getRoute(PageKey.TeamPageKeys.Team),
    component: TeamComponent,
    children: TeamRoutes,
  },
  {
    path: pageMap.getRoute(PageKey.UserProfilePageKeys.UserProfile),
    component: UserProfileComponent,
    children: UserProfileRoutes,
    resolve: {
      user: userProfileResolver,
    },
  },
  {
    path: pageMap.getRoute(PageKey.SettingsPageKeys.Settings),
    component: SettingsComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: ShiveRoutes.SettingsNonworkingTime,
      },
      {
        path: ShiveRoutes.TimeOff,
        component: TimeOffComponent,
        canDeactivate: [PendingChangesGuard],
      },
    ],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
