import { createFeatureSelector, createSelector } from '@ngrx/store';
import { clientAdapter } from './clients.reducer';

export const selectClients = createFeatureSelector('clients');

export const selectAllClients = createSelector(
  createFeatureSelector('clients'),
  clientAdapter.getSelectors().selectAll,
);

export const selectClientById = (clientId: number) =>
  createSelector(selectAllClients, (clients) => clients.find((client) => client.id === clientId));
