import { Routes } from '@angular/router';
import { ShiveRoutes } from '../segments/route-segments';
import { ListTeamComponent } from '../../../features/team/list-team/list-team.component';
import { DetailTeamMemberComponent } from '../../../features/team/detail-team-member/detail-team-member.component';
import { UserProfileBaseDataComponent } from '../../../features/user/user-profile/user-profile-base-data/user-profile-base-data.component';
import { UrlSegmentDelimiter } from '../../data-repository/css-constants';
import { AbsencePlannerComponent } from '../../../features/team/absences/absence-planner/absence-planner.component';
import { AbsencesComponent } from '../../../features/team/absences/absences.component';
import { VacationQuotaComponent } from '../../../features/team/absences/vacation-insights/vacation-quota.component';
import { LeaveRequestsComponent } from '../../../features/team/absences/leave-requests/leave-requests.component';

export const TeamRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: ShiveRoutes.Overview,
  },
  {
    path: ShiveRoutes.Overview,
    component: ListTeamComponent,
  },
  {
    path: ShiveRoutes.Detail,
    component: DetailTeamMemberComponent,
    children: [
      {
        path: `${ShiveRoutes.UserProfile + UrlSegmentDelimiter}:id`,
        component: UserProfileBaseDataComponent,
      },
    ],
  },
  {
    path: ShiveRoutes.Absences,
    component: AbsencesComponent,
    children: [
      {
        path: `${ShiveRoutes.Planner}`,
        component: AbsencePlannerComponent,
      },
      {
        path: `${ShiveRoutes.VacationQuota}`,
        component: VacationQuotaComponent,
      },
      {
        path: `${ShiveRoutes.LeaveRequests}`,
        component: LeaveRequestsComponent,
      },
    ],
  },
];
