import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, combineLatest } from 'rxjs';
import { DayOrWeekCalendarModel } from '../../../core/models/calendar/day-or-week-calendar.model';
import { AppConfigService } from '../../../core/services/app-config.service';
import { BaseCalendarModel } from '../../../core/models/calendar/base-calendar.model';
import {
  CalendarView,
  DayComposite,
  MonthOrdinal,
  UserRelatedCalendarData,
} from '../../../shared/data-types/calendar-types';
import { DateTimeHelper } from '../util/date-time-helper';
import { MonthCalendarModel } from '../../../core/models/calendar/month-calendar.model';
import { cloneDeep } from 'lodash-es';
import { DayCompositeConverter } from '../converter/day-composite-converter';
import { ITimeBlockComponentItem } from '../components/time-block/time-block-component-items';
import { CalendarConfigModel } from '../../../core/models/app-config/calendar-config.model';
import { AuthService } from '../../../auth/auth.service';
import { HolidayTemplatesHttpService } from '../../../core/state/settings/holiday-templates/holiday-templates-http.service';

@Injectable()
export class CalendarViewHandlerService {
  public reRenderFulldayContainerDays$ = new Subject();
  private calendarOptions: CalendarConfigModel;

  constructor(
    private readonly appConfigService: AppConfigService,
    private readonly authService: AuthService,
    private readonly holidayTemplatesHttpService: HolidayTemplatesHttpService,
  ) {}

  /**
   * Loads everything synchronously from cache since all the data has already been fetched.
   */
  public initConfigData(activatedRoute: ActivatedRoute): BaseCalendarModel {
    let calendarModel: BaseCalendarModel;
    combineLatest([
      activatedRoute.data,
      this.appConfigService.getCalendarConfigData(),
      this.authService.getLoggedInUser(),
    ]).subscribe(([, calendarOptions, loggedInUser]) => {
      if (calendarOptions.minimumTimeBlockDuration < 5) {
        throw new Error('Minimum block duration must not be less than 5 minutes.');
      }
      this.calendarOptions = calendarOptions;
      calendarModel = this.buildCalendarModel(calendarOptions.defaultCalendarView);
      calendarModel.calendarProperties.selectedUserId = loggedInUser.id;
    });
    return calendarModel;
  }

  /**
   * Load all necessary data that is user related (currently just the holidays).
   */
  public initUserRelatedCalendarData(): UserRelatedCalendarData {
    return { data$: this.holidayTemplatesHttpService.getHolidayTemplatesForCurrentUser() }; // Todo: Remove from server and here.
  }

  /**
   * Calculate the necessary calendar model data after initialization or user interaction (e.g. changed week, month, etc.).
   */
  public updateCalendarData(date: Date, model: BaseCalendarModel): BaseCalendarModel {
    let updatedModel: DayOrWeekCalendarModel | MonthCalendarModel = null;

    if (model.calendarViewMode === CalendarView.DayGrid) {
      updatedModel = this.updateDayAndWeekCalendarModel(
        date,
        model as DayOrWeekCalendarModel,
        CalendarView.DayGrid,
      );

      const today = new Date();
      updatedModel.calendarProperties.days = DayCompositeConverter.toDayComposites(
        [today],
        model.holidays,
      );
      updatedModel.calendarProperties.visibleStartDate = today;
      updatedModel.calendarProperties.visibleEndDate = today;
    } else if (model.calendarViewMode === CalendarView.WeekGrid) {
      updatedModel = this.updateDayAndWeekCalendarModel(
        date,
        model as DayOrWeekCalendarModel,
        CalendarView.WeekGrid,
      );

      const start = DateTimeHelper.startOfWeek(date, {
        weekStartsOn: model.calendarProperties.weekStartsOn,
      });
      const end = DateTimeHelper.endOfWeek(date, {
        weekStartsOn: model.calendarProperties.weekStartsOn,
      });
      updatedModel.calendarProperties.visibleStartDate = start;
      updatedModel.calendarProperties.visibleEndDate = end;

      const offsetStartDate = DateTimeHelper.subWeeks(
        updatedModel.calendarProperties.visibleStartDate,
        1,
      );
      const offsetEndDate = DateTimeHelper.addWeeks(
        updatedModel.calendarProperties.visibleEndDate,
        1,
      );
      updatedModel.calendarProperties.offsetStartDate = offsetStartDate;
      updatedModel.calendarProperties.offsetEndDate =
        updatedModel.fulldayCalendarModel.calendarProperties.offsetEndDate = offsetEndDate;

      const days = DateTimeHelper.eachDayOfInterval(offsetStartDate, offsetEndDate);
      updatedModel.calendarProperties.days = DayCompositeConverter.toDayComposites(
        days,
        model.holidays,
        start,
        end,
      );
      updatedModel.calendarProperties.selectedUserId = model.calendarProperties.selectedUserId;
    } else if (model.calendarViewMode === CalendarView.MonthGrid) {
      updatedModel = this.updateMonthCalendarModel(
        date,
        model as MonthCalendarModel,
        CalendarView.MonthGrid,
      );
    }

    if (!updatedModel) {
      throw new Error('Calendar model is undefined.');
    }

    updatedModel.calendarViewMode = model.calendarViewMode;
    updatedModel.fulldayCalendarModel.calendarViewMode = model.calendarViewMode;
    updatedModel.fulldayCalendarModel.calendarProperties = cloneDeep(
      updatedModel.calendarProperties,
    );
    updatedModel.fulldayCalendarModel.holidays = model.holidays;
    updatedModel.fulldayCalendarModel.geometryData = cloneDeep(
      updatedModel.fulldayCalendarModel.geometryData,
    );
    updatedModel.calendarProperties.selectedUserId = model.calendarProperties.selectedUserId;

    return updatedModel;
  }

  /**
   * Update the month view calendar model
   */
  public updateMonthCalendarModel(
    date: Date,
    model: BaseCalendarModel,
    view: CalendarView,
  ): MonthCalendarModel {
    const monthCalendarModel = this.buildCalendarModel(
      view,
      model as MonthCalendarModel,
    ) as MonthCalendarModel;
    const startOfMonth = DateTimeHelper.startOfMonth(date);
    const endOfMonth = DateTimeHelper.endOfMonth(date);

    const firstDayInView = DateTimeHelper.startOfWeek(startOfMonth, {
      weekStartsOn: model.calendarProperties.weekStartsOn,
    });
    const lastDayInView = DateTimeHelper.endOfWeek(endOfMonth, {
      weekStartsOn: model.calendarProperties.weekStartsOn,
    });

    monthCalendarModel.calendarProperties.visibleStartDate = firstDayInView;
    monthCalendarModel.calendarProperties.visibleEndDate = lastDayInView;
    monthCalendarModel.calendarProperties.offsetStartDate = firstDayInView;
    monthCalendarModel.calendarProperties.offsetEndDate = lastDayInView;
    monthCalendarModel.calendarProperties.month = date.getMonth() as MonthOrdinal;
    monthCalendarModel.calendarProperties.year = date.getFullYear();
    monthCalendarModel.calendarProperties.days = this.toMonthCompositeDays(
      monthCalendarModel,
      startOfMonth,
      endOfMonth,
    );

    return monthCalendarModel;
  }

  private buildCalendarModel(
    calendarView: number,
    curModel?: DayOrWeekCalendarModel | MonthCalendarModel,
  ): BaseCalendarModel {
    let calendarModel: DayOrWeekCalendarModel | MonthCalendarModel;
    if (calendarView === CalendarView.DayGrid || calendarView === CalendarView.WeekGrid) {
      calendarModel = new DayOrWeekCalendarModel(this.calendarOptions);
    } else {
      // Month view
      calendarModel = new MonthCalendarModel(this.calendarOptions);
    }

    // Hydrate newly created calendar model with existing model data.
    if (curModel) {
      calendarModel.fulldayCalendarModel = curModel.fulldayCalendarModel;
      calendarModel.fulldayCalendarModel.timeBlocks = new Map<string, ITimeBlockComponentItem[]>();
      calendarModel.geometryData = curModel.geometryData;
      calendarModel.holidays = curModel.holidays;
    }

    calendarModel.calendarViewMode =
      CalendarView[CalendarView[this.calendarOptions.defaultCalendarView]];
    calendarModel.fulldayCalendarModel.calendarViewMode =
      CalendarView[CalendarView[this.calendarOptions.defaultCalendarView]];
    return calendarModel;
  }

  /**
   * Update the day or week calendar model with included full day calendar model
   */
  private updateDayAndWeekCalendarModel(
    date: Date,
    model: DayOrWeekCalendarModel,
    view: CalendarView,
  ): DayOrWeekCalendarModel {
    const dayOrWeekCalendarModel = this.buildCalendarModel(view, model) as DayOrWeekCalendarModel;
    dayOrWeekCalendarModel.calendarProperties.month = date.getMonth() as MonthOrdinal;
    dayOrWeekCalendarModel.calendarProperties.year = date.getFullYear();
    return dayOrWeekCalendarModel;
  }

  private toMonthCompositeDays(model: MonthCalendarModel, startOfMonth: Date, endOfMonth: Date) {
    const firstDayInView = DateTimeHelper.startOfWeek(startOfMonth, {
      weekStartsOn: model.calendarProperties.weekStartsOn,
    });
    const lastDayInView = DateTimeHelper.endOfWeek(endOfMonth, {
      weekStartsOn: model.calendarProperties.weekStartsOn,
    });

    const allWeeks = DateTimeHelper.eachWeekOfInterval(startOfMonth, endOfMonth, {
      weekStartsOn: model.calendarProperties.weekStartsOn,
    }); // Assuming Monday is the first day of the week

    const dayComposites: DayComposite[] = [];
    allWeeks.forEach((week) => {
      const startDay = DateTimeHelper.startOfWeek(week, {
        weekStartsOn: model.calendarProperties.weekStartsOn,
      });
      const endDay = DateTimeHelper.endOfWeek(week, {
        weekStartsOn: model.calendarProperties.weekStartsOn,
      });
      const daysOfWeek = DateTimeHelper.eachDayOfInterval(startDay, endDay);
      const convertedDays = DayCompositeConverter.toDayComposites(
        daysOfWeek,
        model.holidays,
        firstDayInView,
        lastDayInView,
      );
      dayComposites.push(...convertedDays);
    });

    return dayComposites;
  }
}
