import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SecurityContext,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserOperation } from 'src/app/core/enums/user-operation';
import { ShiveDialogService } from '../../../../core/services/controls/shive-dialog.service';
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { UserModel } from '../../../../core/models/user/user.model';
import { SubSink } from 'subsink';
import { switchMap } from 'rxjs/operators';
import { UserService } from '../../../../features/user/services/user.service';
import { of } from 'rxjs';
import { ProjectMemberService } from '../../../../features/project/detail-project/edit-project/services/project-member.service';

@Component({
  selector: 'app-holiday-settings-member-dialog',
  templateUrl: './add-member-dialog.component.html',
})
export class AddMemberDialogComponent implements OnInit, OnDestroy {
  @ViewChild('dialogFooter') dialogFooter: TemplateRef<unknown>;
  @Input() selectedUser: UserModel;
  @Input() membersInGrid: UserModel[];
  @Input() filterSelf? = null;
  public UserOperation = UserOperation;
  public userForm: FormGroup;
  public filteredMembers: UserModel[] = [];
  public isLoadingUsers = false;
  public readonly faCirclePlus = faCirclePlus;
  public readonly SecurityContext = SecurityContext;
  private readonly subs = new SubSink();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly projectMemberService: ProjectMemberService,
    private readonly userService: UserService,
    private readonly shiveDialogService: ShiveDialogService,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.loadUsers();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public submit(): void {
    const selectedUser = this.userForm.get('user').value;
    this.shiveDialogService.close(selectedUser);
  }

  public close(): void {
    this.shiveDialogService.close(UserOperation.Cancel);
  }

  private initForm(): void {
    this.userForm = this.formBuilder.group({
      user: [null, Validators.required],
    });
  }

  private loadUsers(): void {
    this.isLoadingUsers = true;
    this.subs.sink = this.projectMemberService
      .loadAllProjectMembers(this.filterSelf)
      .pipe(
        switchMap((allMembers) => {
          const filteredMembers = this.userService.filterAlreadyAddedMembers(
            allMembers,
            this.membersInGrid,
          );
          return of(filteredMembers);
        }),
      )
      .subscribe((filteredMembers) => {
        this.filteredMembers = [...filteredMembers];
        this.isLoadingUsers = false;
      });
  }
}
