import { ClientModel } from '../../models/client/client.model';
import { createReducer, on } from '@ngrx/store';
import {
  clientAdded,
  clientDeleted,
  clientUpdated,
  setClients,
  setSingleClient,
} from './clients.actions';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';

export interface ClientState extends EntityState<ClientModel> {}

export const clientAdapter: EntityAdapter<ClientModel> = createEntityAdapter<ClientModel>();
const initialState: ClientState = clientAdapter.getInitialState();

export const clientsReducer = createReducer(
  initialState,
  on(setClients, (state, { payload }): ClientState => {
    return clientAdapter.setMany(payload, state);
  }),
  on(setSingleClient, (state, { payload }): ClientState => {
    return clientAdapter.setOne(payload, state);
  }),
  on(clientAdded, (state, { payload }): ClientState => {
    if (Array.isArray(payload)) {
      return clientAdapter.addMany(payload, state);
    }
    return clientAdapter.addOne(payload, state);
  }),
  on(clientUpdated, (state, { payload }): ClientState => {
    if (Array.isArray(payload)) {
      return clientAdapter.upsertMany(payload, state);
    }
    return clientAdapter.upsertOne(payload, state);
  }),
  on(clientDeleted, (state, { payload }): ClientState => {
    if (Array.isArray(payload)) {
      return clientAdapter.removeMany(
        payload.map((t) => t.id),
        state,
      );
    }
    return clientAdapter.removeOne(payload.id, state);
  }),
);
