import { Routes } from '@angular/router';
import { ShiveRoutes } from '../segments/route-segments';
import { ListProjectComponent } from '../../../features/project/list-project/list-project.component';
import { ManageProjectComponent } from '../../../features/project/detail-project/edit-project/manage-project/manage-project.component';
import { DetailProjectComponent } from '../../../features/project/detail-project/detail-project.component';
import { PendingChangesGuard } from '../../guards/pending-changes.guard';
import { UrlSegmentDelimiter } from '../../data-repository/css-constants';
import { StatusProjectComponent } from '../../../features/project/detail-project/status-project/status-project.component';
import { ProjectDataResolver } from '../../resolver/project/project-data.resolver';
import { EditProjectComponent } from '../../../features/project/detail-project/edit-project/edit-project.component';
import { AttachmentProjectComponent } from '../../../features/project/detail-project/attachment-project/attachment-project.component';
import { ActivityLogProjectComponent } from '../../../features/project/detail-project/activity-log-project/activity-log-project.component';
import { ProjectStatusResolver } from '../../resolver/project/project-status.resolver';

export const ProjectRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: ShiveRoutes.ProjectOverview,
  },
  {
    path: ShiveRoutes.Overview,
    component: ListProjectComponent,
  },
  {
    path: ShiveRoutes.Create,
    component: ManageProjectComponent,
    canDeactivate: [PendingChangesGuard],
    resolve: {
      projectStatuses: ProjectStatusResolver,
    },
  },
  {
    path: ShiveRoutes.Detail,
    component: DetailProjectComponent,
    resolve: {
      project: ProjectDataResolver,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: ShiveRoutes.Status,
      },
      {
        path: `${ShiveRoutes.Status + UrlSegmentDelimiter}:id`,
        component: StatusProjectComponent,
      },
      {
        path: `${ShiveRoutes.Edit + UrlSegmentDelimiter}:id`,
        component: EditProjectComponent,
        canDeactivate: [PendingChangesGuard],
        resolve: {
          projectStatuses: ProjectStatusResolver,
        },
      },
      {
        path: `${ShiveRoutes.Attachments + UrlSegmentDelimiter}:id`,
        component: AttachmentProjectComponent,
      },
      {
        path: `${ShiveRoutes.Logs + UrlSegmentDelimiter}:id`,
        component: ActivityLogProjectComponent,
      },
    ],
  },
];
