import { HolidayTemplateModel } from '../../core/models/settings/holiday/holiday-template.model';
import { Observable } from 'rxjs';

export type WeekOrdinal = 0 | 1 | 2 | 3 | 4 | 5 | 6;
export type MonthOrdinal = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

export type DayComposite = {
  theDate: Date;
  epoch: Epoch;
  outsideOfView: boolean;
  isFirstVisibleDay: boolean;
  isLastVisibleDay: boolean;
  isHoliday: boolean;
};

export type MonthViewDayData = {
  isWithinMonth: boolean;
};

export type DayCompositeOfMonth = DayComposite & MonthViewDayData;

export type WeekComposite = {
  days: DayCompositeOfMonth[];
  weekIndex: number;
};

export type Epoch = 'today' | 'future' | 'past' | 'unknown';

export type UserRelatedCalendarData = {
  data$: Observable<readonly HolidayTemplateModel[]>;
};

/**
 * These events are triggered on calendar level.
 */
export enum CalendarEvents {
  Start,
  SwitchedCalendarViewMode,
  InitializedCalendarView,
  ChangedStartEndDates,
  AddedTimeBlock,
  ReplacedTimeBlock,
  RemovedTimeBlock,
  RenderedTimeBlocks,
  CalculatedWorkingTimeDurations,
  NowIndicatorPassedMidnight,
  UpdatedShiveTrackerStatus,
}

export enum CalendarView {
  DayGrid,
  WeekGrid,
  MonthGrid,
}

export enum FulldayDuration {
  fromWorkTimeStartToWorkTimeEnd,
  fromMidnightToMidnight,
  Custom,
}

export enum CalendarTimerStatus {
  Start = 'start',
  Stop = 'stop',
}
