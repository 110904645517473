import { Injectable } from '@angular/core';
import { ITimeBlockComponentItem } from '../time-block-component-items';
import { TimeBlockMappingService } from '../time-block-structure/time-block-mapping.service';
import { SharedActiveTimeBlockService } from './shared-active-time-block.service';
import { TimerHttpService } from '../../../../../core/state/time-blocks/timer/timer-http.service';
import { Actions, ofType } from '@ngrx/effects';
import * as fromTimerActions from '../../../../../core/state/time-blocks/timer/timer.actions';
import { map } from 'rxjs/operators';
import { calendarCronjobsInterval } from '../../../../../../assets/config/config-constants';
import { IActiveTimeBlock } from './active-time-block-interfaces';
import { CalendarCronJobsService } from '../../../services/calendar-cron-jobs.service';
import { CalendarService } from '../../../services/calendar.service';
import { DateTimeHelper } from '../../../util/date-time-helper';
import { CalendarTimerStatus } from '../../../../../shared/data-types/calendar-types';

@Injectable()
export class ActiveTimeBlockTimerService implements IActiveTimeBlock {
  private activeTimeBlockId: number = null;

  constructor(
    private readonly timeBlockMappingService: TimeBlockMappingService,
    private readonly sharedActiveTimeBlockService: SharedActiveTimeBlockService,
    private readonly timerHttpService: TimerHttpService,
    private readonly calendarCronJobsService: CalendarCronJobsService,
    private readonly calendarService: CalendarService,
    private readonly actions$: Actions,
  ) {}

  public registerTimeTracking(): void {
    this.initUserEvents();
    this.activeTimeBlockExists();

    const updateActiveTimeBlock = () => {
      // Set the current value of the manual active time block since it could have been changed after the Tracker was disabled.
      this.activeTimeBlockId =
        this.sharedActiveTimeBlockService.timerActiveTimeBlockIdChanged$.value;
      this.updateActiveTimeBlock(this.activeTimeBlockId);
    };

    updateActiveTimeBlock();
    this.calendarCronJobsService.registerJob(updateActiveTimeBlock, calendarCronjobsInterval);
  }

  /** After a user presses "Start", insert the active time block into the calendar view.
   * Remark: There is no need to conduct a http post request since the timer-endpoint inserts a time block into the database automatically.
   */
  public insertActiveTimeBlock(generatedTimeBlock: ITimeBlockComponentItem): number {
    return this.sharedActiveTimeBlockService.insertActiveTimeBlock(generatedTimeBlock);
  }

  /** After a user presses "Stop", replace the tracked time block with a new one to initialize dragging / resizing.
   * Remark: There is no need to conduct a http put request since the timer endpoint updates the time block automatically on the server.
   */
  public replaceActiveTimeBlock(): number {
    return this.sharedActiveTimeBlockService.replaceActiveTimeBlock(this.activeTimeBlockId);
  }

  public toggleActiveTimeBlock(): void {
    let status: CalendarTimerStatus;
    if (this.activeTimeBlockId >= 0) {
      status = CalendarTimerStatus.Stop;
    } else {
      status = CalendarTimerStatus.Start;
    }
    this.timerHttpService.postTimer(status);
  }

  public updateActiveTimeBlock(activeTimeBlockId: number): void {
    if (
      !this.sharedActiveTimeBlockService.canUpdate(activeTimeBlockId) ||
      this.calendarService.model.shiveTrackerActive
    ) {
      return;
    }

    const now = DateTimeHelper.setSecondsAndMillisToZero(new Date());

    this.sharedActiveTimeBlockService.updateActiveTimeBlock(this.activeTimeBlockId, now);
  }

  private activeTimeBlockExists(): boolean {
    let activeTimeBlockExists = false;
    const innerdayTimeBlocks = this.timeBlockMappingService.timeBlockInnerdayMapToArray();
    innerdayTimeBlocks.forEach((timeBlocks) => {
      timeBlocks.forEach((timeBlock) => {
        if (timeBlock.timeBlockModel.isActive) {
          this.activeTimeBlockId = timeBlock.id;
          activeTimeBlockExists = true;
          this.sharedActiveTimeBlockService.timerActiveTimeBlockIdChanged$.next(
            this.activeTimeBlockId,
          );
        }
      });
    });

    // If no active time block could be found, change the id to -1.
    if (this.activeTimeBlockId === null) {
      this.activeTimeBlockId = -1;
      this.sharedActiveTimeBlockService.timerActiveTimeBlockIdChanged$.next(-1);
    }

    return activeTimeBlockExists;
  }

  private initUserEvents() {
    this.actions$
      .pipe(
        ofType(fromTimerActions.timerPosted),
        map((action) => action.payload),
      )
      .subscribe((payload) => {
        const timerStatus = payload.status;

        if (timerStatus === CalendarTimerStatus.Start) {
          this.activeTimeBlockId = this.insertActiveTimeBlock(payload.generatedTimeBlock);
        } else {
          this.activeTimeBlockId = this.replaceActiveTimeBlock();
        }
        this.sharedActiveTimeBlockService.timerActiveTimeBlockIdChanged$.next(
          this.activeTimeBlockId,
        );
      });
  }
}
