import { NavigationWrapper } from '../../navigation-data';
import { ShiveRoutes } from '../../segments/route-segments';

export const Settings: NavigationWrapper = {
  title: 'Einstellungen',
  route: ShiveRoutes.Settings,
};

export const SettingsNonworkingTime: NavigationWrapper = {
  title: 'Nichtarbeitszeit',
  route: ShiveRoutes.SettingsNonworkingTime,
};
