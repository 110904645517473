import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShiveRoutes } from '../../../core/routing/segments/route-segments';
import { SubSink } from 'subsink';
import { listAnimation } from '../../../core/animations/animations';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ShiveContextMenuComponent } from '../../../shared/components/context/shive-context-menu/shive-context-menu.component';
import { ListClientDataService } from './data-handling/list-client-data.service';
import { ContextMenuItem } from '../../../shared/data-types/context-menu-item-type';
import { ClientModel } from '../../../core/models/client/client.model';
import { ShivePopupService } from '../../../core/services/controls/shive-popup.service';

@Component({
  selector: 'app-list-client',
  templateUrl: './list-client.component.html',
  animations: [listAnimation],
})
export class ListClientComponent implements OnInit, OnDestroy {
  @ViewChild('grid') grid: GridComponent;
  @ViewChild('contextMenu') contextMenu: ShiveContextMenuComponent;
  public ShiveRoutes = ShiveRoutes;
  private readonly items: ContextMenuItem[] = [
    // {
    //   text: 'Bearbeiten',
    //   iconColorClass: 'turquoise',
    //   faIcon: 'fa-pen',
    //   callback: (clientModel: ClientModel) => {
    //     void this.router.navigate(['../', ShiveRoutes.Edit, clientModel.id], {
    //       relativeTo: this.route,
    //     });
    //   },
    // },
    // {
    //   text: 'Duplizieren',
    //   iconColorClass: 'turquoise',
    //   callback: () => {},
    // },
    // {
    //   text: 'Archivieren',
    //   iconColorClass: 'turquoise',
    //   callback: () => {},
    // },
    // {
    //   text: 'Löschen',
    //   iconColorClass: 'turquoise',
    //   callback: (clientModel: ClientModel) => {
    //     this.listClientDataService.openClientDeleteDialog(clientModel);
    //   },
    // },
  ];
  private readonly subs = new SubSink();

  constructor(
    public listClientDataService: ListClientDataService,
    private readonly route: ActivatedRoute,
    private readonly shivePopupService: ShivePopupService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.listClientDataService.loadGridData();
    this.listClientDataService.handleClientHttpCallback();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public onCellClick(e): void {
    if (e.columnIndex === this.grid.columns.length - 1) {
      return;
    }
    const clientModel = e.dataItem as ClientModel;
    void this.router.navigate(['../', ShiveRoutes.Edit, clientModel.id], {
      relativeTo: this.route,
    });
  }

  public openContextMenu(anchor: HTMLDivElement, client: ClientModel): void {
    if (this.shivePopupService.popupRef) {
      this.shivePopupService.popupRef.close();
    }

    const popupRef = this.shivePopupService.open({
      content: ShiveContextMenuComponent,
      anchor,
    });
    const contextMenuComponent = popupRef.content.instance as ShiveContextMenuComponent;
    contextMenuComponent.items = this.items;
    contextMenuComponent.data = client;
  }
}
