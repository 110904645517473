import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { HolidaySettingsService } from './holiday/holiday-settings.service';
import {
  NotificationType,
  ShiveNotificationService,
} from '../../../core/services/controls/shive-notification.service';
import { SubSink } from 'subsink';
import { Observable, merge, take } from 'rxjs';
import { ComponentCanDeactivate } from '../../../core/guards/pending-changes.guard';
import { AbsenceReasonSettingsService } from './absence-reason-settings/absence-reason-settings.service';
import * as fromHolidayTemplateActions from '../../../core/state/settings/holiday-templates/holiday-templates.actions';
import * as fromAbsenceReasonActions from '../../../core/state/settings/absence-reasons/absence-reasons.actions';
import { Router } from '@angular/router';

/**
 * Nichtarbeitszeit
 */
@Component({
  selector: 'app-time-off',
  templateUrl: './time-off.component.html',
})
export class TimeOffComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
  public saveButtonDisabled = true;
  private readonly subs = new SubSink();

  constructor(
    private readonly holidaySettingsService: HolidaySettingsService,
    private readonly absenceReasonSettingsService: AbsenceReasonSettingsService,
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly shiveNotificationService: ShiveNotificationService,
  ) {}

  ngOnInit(): void {
    this.initEvents();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.absenceReasonSettingsService.absenceReasonsStore.reset();
    this.holidaySettingsService.holidaySettingsStore.reset();
  }

  @HostListener('window:beforeunload')
  public canDeactivate(): boolean | Observable<boolean> {
    return this.saveButtonDisabled;
  }

  public save(): void {
    this.holidaySettingsService.emitHolidaySettingsHttpRequests();
    this.absenceReasonSettingsService.emitAbsenceReasonsHttpRequests();
    this.initHttpResponse();
    this.saveButtonDisabled = true;
  }

  public cancel(): void {
    void this.router.navigate(['/time-tracking']); // Todo: Set correct page after cancelling entered time-off data.
  }

  public initHttpResponse(): void {
    this.subs.sink = this.actions$
      .pipe(
        ofType(
          fromHolidayTemplateActions.holidayTemplateAdded,
          fromHolidayTemplateActions.holidayTemplateUpdated,
          fromHolidayTemplateActions.holidayTemplateDeleted,
          fromAbsenceReasonActions.absenceReasonAdded,
          fromAbsenceReasonActions.absenceReasonUpdated,
          fromAbsenceReasonActions.absenceReasonDeleted,
        ),
        take(1),
      )
      .subscribe(() => {
        this.shiveNotificationService.show(NotificationType.Success);
      });
  }

  private initEvents(): void {
    this.subs.sink = merge(
      this.holidaySettingsService.holidaySettingsStore.userOperationConducted,
      this.absenceReasonSettingsService.absenceReasonsStore.userOperationConducted,
    ).subscribe(() => {
      this.saveButtonDisabled = false;
    });
  }
}
