import { PageMap } from '../navigation-data';
import { PageKey } from '../page-keys';
import { Dashboard } from './page-navigation/dashboard-navigation';
import { Calendar } from './page-navigation/calendar-navigation';
import {
  ProjectAdd,
  ProjectAttachments,
  ProjectEdit,
  ProjectLogs,
  ProjectOverview,
  ProjectStatus,
  Projects,
} from './page-navigation/projects-navigation';
import { ClientAdd, ClientEdit, ClientList, Clients } from './page-navigation/clients-navigation';
import {
  Team,
  TeamAbsencePlanner,
  TeamAbsences,
  TeamLeaveRequests,
  TeamOverview,
  TeamVacationQuota,
} from './page-navigation/team-navigation';
import {
  UserProfile,
  UserProfileAttachments,
  UserProfileBaseData,
} from './page-navigation/user-profile-navigation';
import { Settings, SettingsNonworkingTime } from './page-navigation/settings-navigation';

export const pageMap = new PageMap([
  [PageKey.DashboardPageKeys.Dashboard, Dashboard],
  [PageKey.CalendarPageKeys.Calendar, Calendar],
  // Projects
  [PageKey.ProjectPageKeys.Projects, Projects],
  [PageKey.ProjectPageKeys.ProjectOverview, ProjectOverview],
  [PageKey.ProjectPageKeys.ProjectAdd, ProjectAdd],
  [PageKey.ProjectPageKeys.ProjectStatus, ProjectStatus],
  [PageKey.ProjectPageKeys.ProjectEdit, ProjectEdit],
  [PageKey.ProjectPageKeys.ProjectAttachments, ProjectAttachments],
  [PageKey.ProjectPageKeys.ProjectLogs, ProjectLogs],
  // Clients
  [PageKey.ClientPageKeys.Clients, Clients],
  [PageKey.ClientPageKeys.ClientList, ClientList],
  [PageKey.ClientPageKeys.ClientAdd, ClientAdd],
  [PageKey.ClientPageKeys.ClientEdit, ClientEdit],
  // Team
  [PageKey.TeamPageKeys.Team, Team],
  [PageKey.TeamPageKeys.TeamOverview, TeamOverview],
  [PageKey.TeamPageKeys.TeamAbsences, TeamAbsences],
  [PageKey.TeamPageKeys.TeamAbsencePlanner, TeamAbsencePlanner],
  [PageKey.TeamPageKeys.TeamVacationQuota, TeamVacationQuota],
  [PageKey.TeamPageKeys.TeamLeaveRequests, TeamLeaveRequests],
  // User Profile
  [PageKey.UserProfilePageKeys.UserProfile, UserProfile],
  [PageKey.UserProfilePageKeys.UserProfileBaseData, UserProfileBaseData],
  [PageKey.UserProfilePageKeys.UserProfileAttachments, UserProfileAttachments],
  // Settings
  [PageKey.SettingsPageKeys.Settings, Settings],
  [PageKey.SettingsPageKeys.SettingsNonworkingTime, SettingsNonworkingTime],
]);
