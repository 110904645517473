import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UserOperation } from '../../../../core/enums/user-operation';
import { ITimeBlockComponentItem } from '../../components/time-block/time-block-component-items';
import { TimeBlockHighlightingService } from '../../components/time-block/highlighted/time-block-highlighting.service';
import { Subject } from 'rxjs';
import {
  TimeBlockContentType,
  TimeBlockType,
  TimeBlockUserOperation,
} from '../../../../shared/data-types/time-block-types';
import { WINDOW } from '../../util/browser-window-ref';
import { CardinalPoints } from '../../components/time-block/interaction/movement/drag-and-resize/drag-part';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { ShiveDialogService } from '../../../../core/services/controls/shive-dialog.service';
import {
  TabStripDialogContentCSSSelector,
  TimeBlockDialogCSSSelector,
} from '../../../../core/data-repository/css-constants';

@Component({
  selector: 'app-time-entry-dialog',
  templateUrl: './time-entry-dialog.component.html',
  styleUrls: ['./time-entry-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TimeEntryDialogComponent implements AfterViewInit {
  @ViewChild('tabstrip') public tabstrip: TabStripComponent;
  @Input() selectedHTMLElementBlock: HTMLElement;
  @Input() selectedTimeBlockComponent: ITimeBlockComponentItem;
  @Input() dialogRef: DialogRef;
  public readonly timeBlockComponentItem: ITimeBlockComponentItem;
  public actionEmitted$ = new Subject<TimeBlockUserOperation>();
  public UserOperation = UserOperation;
  public TimeBlockType = TimeBlockType;
  public TimeBlockContentType = TimeBlockContentType;
  public selectedTab: TimeBlockContentType;
  private readonly gap = 27;

  constructor(
    private readonly timeBlockHighlightingService: TimeBlockHighlightingService,
    @Inject(WINDOW) private readonly window: Window,
    private readonly shiveDialogService: ShiveDialogService,
    private readonly renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    if (this.selectedHTMLElementBlock) {
      window.setTimeout(() => {
        this.selectedTab = this.selectedTimeBlockComponent.timeBlockContentType;
        // Set tab
        this.tabstrip.selectTab(this.selectedTab);
        this.timeBlockHighlightingService.createHighlightedTimeBlockClone(
          this.selectedTimeBlockComponent,
        );
        this.calcOffsetAndArrowPos(this.selectedHTMLElementBlock);
        this.renderer.removeClass(
          this.dialogRef.dialog.instance.dialog.nativeElement.parentElement,
          'opacity-0',
        );
      });
    }
  }

  public sendAction(operation: UserOperation): void {
    this.actionEmitted$.next({
      operation,
      timeBlockItem: this.selectedTimeBlockComponent,
    });
  }

  public tabHidden(contentType: TimeBlockContentType): boolean {
    if (!this.selectedTimeBlockComponent) {
      throw new Error('No time block set.');
    }

    // For new time blocks, show all tabs.
    if (this.selectedTimeBlockComponent.timeBlockModel.type === TimeBlockType.NonExistingBlock) {
      return false;
    }

    return contentType !== this.selectedTimeBlockComponent.timeBlockContentType;
  }

  public cancel(): void {
    this.shiveDialogService.close(UserOperation.Cancel);
  }

  // Todo: Outsource
  private calcOffsetAndArrowPos(highlightedClone: HTMLElement): void {
    const dialog = document.querySelector(TimeBlockDialogCSSSelector);
    const dialogContent = document.querySelector(TabStripDialogContentCSSSelector);

    // Calculate horizontal offset
    const dialogHorizontalAlignment = this.calcHorizontalOffset(
      highlightedClone,
      dialog as HTMLElement,
    );

    if (dialogHorizontalAlignment === CardinalPoints.None) {
      return;
    }

    // Create triangle
    const triangle = this.renderer.createElement('div');
    this.renderer.addClass(triangle, 'triangle');
    this.renderer.addClass(triangle, 'large');

    // Append triangle and set horizontal position
    this.appendTriangle(triangle, dialog as HTMLElement, dialogHorizontalAlignment);
    // Calculate arrow position
    this.setVerticalArrowPos(
      dialogHorizontalAlignment,
      dialogContent as HTMLElement,
      highlightedClone,
      triangle,
    );
  }

  private calcHorizontalOffset(highlightedClone: HTMLElement, dialog: HTMLElement): CardinalPoints {
    const dialogWidth = dialog.clientWidth;

    const tbLeftToLeft = highlightedClone.getBoundingClientRect().left;
    const tbRightToRight = this.window.innerWidth - highlightedClone.getBoundingClientRect().right;

    if (tbLeftToLeft > dialogWidth + this.gap) {
      const offsetX = tbLeftToLeft - dialogWidth - this.gap;
      this.renderer.setStyle(dialog, 'left', `${offsetX}px`);
      return CardinalPoints.West;
    } else if (tbRightToRight > dialogWidth + this.gap) {
      const offsetX = tbRightToRight - dialogWidth - this.gap;
      this.renderer.setStyle(dialog, 'right', `${offsetX}px`);
      return CardinalPoints.East;
    }
    return CardinalPoints.None;
  }

  private setVerticalArrowPos(
    dialogHorizontalAlignment: CardinalPoints,
    dialogContent: HTMLElement,
    highlightedClone: HTMLElement,
    triangle: HTMLElement,
  ): void {
    const triangleHead =
      highlightedClone.getBoundingClientRect().bottom - highlightedClone.clientHeight / 2;

    const triangleHeight = triangle.clientHeight;
    const triangleHalfHeight = triangleHeight / 2;

    const dialogTop = dialogContent.getBoundingClientRect().top;
    const dialogBottom = dialogContent.getBoundingClientRect().bottom;
    if (
      triangleHead - triangleHalfHeight > dialogTop &&
      triangleHead + triangleHalfHeight < dialogBottom
    ) {
      if (dialogHorizontalAlignment === CardinalPoints.West) {
        this.renderer.removeClass(triangle, 'flipped');
      } else {
        this.renderer.addClass(triangle, 'flipped');
      }

      const triangleTop = triangleHead - 27;
      this.renderer.setStyle(triangle, 'top', `${triangleTop}px`);
      this.renderer.removeClass(triangle, '!k-display-none');
    } else {
      this.renderer.addClass(triangle, '!k-display-none');
    }
  }

  private appendTriangle(
    triangle: unknown,
    dialog: HTMLElement,
    dialogHorizontalAlignment: CardinalPoints,
  ): void {
    this.renderer.appendChild(dialog.parentElement, triangle);
    const offsetX =
      dialogHorizontalAlignment === CardinalPoints.West
        ? dialog.getBoundingClientRect().right - 37
        : dialog.getBoundingClientRect().left + 18;
    this.renderer.setStyle(triangle, 'left', `${offsetX}px`);
  }
}
