import { ElementRef, Injectable } from '@angular/core';
import { DayOrWeekCalendarModel } from '../../../core/models/calendar/day-or-week-calendar.model';
import { CalendarServiceHelper } from './calendar-service-helper';
import { CalendarService } from './calendar.service';

@Injectable()
export class CalendarGeometryService {
  constructor(private readonly calendarService: CalendarService) {}

  /**
   * Get cell width for day and week view full day lanes including padding and border.
   */
  public getDayOrWeekFulldayLaneWidth(): number {
    const model = this.calendarService.model as DayOrWeekCalendarModel;
    const fulldayItemContainer = model.fulldayCalendarModel.geometryData.calendarBodyElementRef
      .nativeElement as HTMLTableElement;
    const firstFulldayCell = fulldayItemContainer.querySelector('.weekday.first-visible-day');
    if (!firstFulldayCell) {
      throw new Error('Header cell not visible.');
    }
    return firstFulldayCell.getBoundingClientRect().width;
  }

  public getDayOrWeekInnerdayLaneWidth(): number {
    const firstLaneIndex = CalendarServiceHelper.getFirstVisibleLaneIndex(
      this.calendarService.model,
    );
    const laneElement = this.calendarService.model.timeBlockHtmlContainers[firstLaneIndex];
    return laneElement.nativeElement.clientWidth + this.getLaneRightBorderWidth();
  }

  /**
   * Border is included
   */
  public getMonthFulldayLaneWidth(): number {
    const monthItemContainer = this.calendarService.model.timeBlockHtmlContainers;
    const firstCell = monthItemContainer[0].nativeElement;
    return firstCell.getBoundingClientRect().width;
  }

  public getMonthFulldayLaneHeight(): number {
    const monthItemContainer = this.calendarService.model.timeBlockHtmlContainers;
    const firstCell = monthItemContainer[0].nativeElement;
    return firstCell.getBoundingClientRect().height;
  }

  public getLaneLeftBorderWidth(laneElement?: HTMLElement | ElementRef<HTMLElement>): number {
    if (!laneElement) {
      const firstLaneIndex = CalendarServiceHelper.getFirstVisibleLaneIndex(
        this.calendarService.model,
      );
      laneElement = this.calendarService.model.timeBlockHtmlContainers[firstLaneIndex];
    }

    if (!(laneElement instanceof HTMLElement)) {
      laneElement = laneElement.nativeElement;
    }

    return parseFloat(getComputedStyle(laneElement).getPropertyValue('border-left-width'));
  }

  public getLaneRightBorderWidth(laneElement?: HTMLElement | ElementRef<HTMLElement>): number {
    if (!laneElement) {
      const firstLaneIndex = CalendarServiceHelper.getFirstVisibleLaneIndex(
        this.calendarService.model,
      );
      laneElement = this.calendarService.model.timeBlockHtmlContainers[firstLaneIndex];
    }

    if (!(laneElement instanceof HTMLElement)) {
      laneElement = laneElement.nativeElement;
    }

    return parseFloat(getComputedStyle(laneElement, null).getPropertyValue('border-right-width'));
  }
}
