import { Component } from '@angular/core';
import { ShiveRoutes } from 'src/app/core/routing/segments/route-segments';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
})
export class TeamComponent {
  public ShiveRoutes = ShiveRoutes;
}
