import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { listAnimation } from '../../../core/animations/animations';
import { ShiveRoutes } from 'src/app/core/routing/segments/route-segments';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectModel } from '../../../core/models/project/project.model';
import { ShiveContextMenuComponent } from '../../../shared/components/context/shive-context-menu/shive-context-menu.component';
import { CellClickEvent, GridComponent } from '@progress/kendo-angular-grid';
import { ListProjectDataService } from './list-project-data.service';
import { ShiveGridComponent } from '../../../shared/components/form-components/shive-grid/shive-grid.component';

@Component({
  selector: 'app-list-project',
  templateUrl: './list-project.component.html',
  styleUrls: ['./list-project.component.scss'],
  animations: [listAnimation],
})
export class ListProjectComponent implements OnInit, AfterViewInit {
  @ViewChild('grid') grid: GridComponent;
  @ViewChild('gridWrapperComponent') gridWrapperComponent: ShiveGridComponent;
  @ViewChild('contextMenu') contextMenu: ShiveContextMenuComponent;
  public ShiveRoutes = ShiveRoutes;

  constructor(
    public readonly listProjectDataService: ListProjectDataService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.listProjectDataService.loadGridData();
    this.listProjectDataService.handleProjectHttpCallback();
  }

  ngAfterViewInit(): void {
    this.initContextMenuCallbacks();
  }

  public onCellClick(e: CellClickEvent): void {
    if (e.columnIndex === this.grid.columns.length - 1) {
      return;
    }
    const projectModel = e.dataItem as ProjectModel;
    void this.router.navigate(['../', ShiveRoutes.Detail, ShiveRoutes.Status, projectModel.id], {
      relativeTo: this.route,
    });
  }

  private initContextMenuCallbacks(): void {
    this.gridWrapperComponent.contextMenuDeleteFn = (projectModel: ProjectModel) => {
      this.listProjectDataService.deleteProject(projectModel);
    };

    this.gridWrapperComponent.setContextMenuItems();
  }
}
