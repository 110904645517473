import { Component } from '@angular/core';
import { SkeletonModule } from '@progress/kendo-angular-indicators';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'app-calendar-skeleton-week-view',
  standalone: true,
  imports: [SkeletonModule, NgStyle],
  templateUrl: './calendar-skeleton-week-view.component.html',
})
export class CalendarSkeletonWeekViewComponent {}
