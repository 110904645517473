import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'shiveCurrency',
})
export class ShiveCurrencyPipe implements PipeTransform {
  constructor(
    private readonly currencyPipe: CurrencyPipe,
    @Inject(LOCALE_ID) private readonly locale: string,
  ) {}

  transform(value: number, locale: string = 'en-US', digitsInfo?: string): string | null {
    const activeLocale = this.locale || locale;

    // Determine the currency based on the locale
    let currencyCode: string;
    let currencySymbol: string;

    switch (activeLocale) {
      case 'en-US':
        currencyCode = 'USD';
        currencySymbol = '$';
        break;
      case 'en-GB':
      case 'en-IE':
      case 'de-DE':
      case 'fr-FR':
        currencyCode = 'EUR';
        currencySymbol = '€';
        break;
      // Add more locales as needed
      default:
        currencyCode = 'USD';
        currencySymbol = '$';
        break;
    }

    const formattedValue = this.currencyPipe.transform(
      value,
      currencyCode,
      'symbol',
      digitsInfo,
      activeLocale,
    );
    if (formattedValue) {
      // Manually move the currency symbol to the front
      return `${currencySymbol} ${formattedValue.replace(currencySymbol, '').trim()}`;
    }
    return null;
  }
}
