import { ResolveFn } from '@angular/router';
import { UsersHttpService } from '../../state/users/users-http.service';
import { inject } from '@angular/core';
import { UserModel } from '../../models/user/user.model';
import { assert } from '../../assert/assert';

export const userProfileResolver: ResolveFn<UserModel> = (route, state) => {
  const usersHttpService = inject(UsersHttpService);
  const userId = +state.url.split('/').pop();
  assert(typeof userId !== 'undefined', 'User id is invalid.');
  return usersHttpService.getUser();
};
