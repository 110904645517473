import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-user-profile-employment-data',
  templateUrl: './user-profile-employment-data.component.html',
  styleUrl: './user-profile-employment-data.component.scss',
})
export class UserProfileEmploymentDataComponent implements OnInit {
  public employmentDataForm: FormGroup;
  public formControlNames: { [key: string]: string } = {
    EmployedSince: 'employedSince',
    WorkingHours: 'workingHours',
    Monday: 'monday',
    Tuesday: 'tuesday',
    Wednesday: 'wednesday',
    Thursday: 'thursday',
    Friday: 'friday',
    Saturday: 'saturday',
    Sunday: 'sunday',
  };
  private readonly formBuilder = inject(FormBuilder);

  ngOnInit(): void {
    this.employmentDataForm = this.formBuilder.group({
      [this.formControlNames.EmployedSince]: [],
      [this.formControlNames.WorkingHours]: this.formBuilder.group({
        [this.formControlNames.Monday]: [],
        [this.formControlNames.Tuesday]: [],
        [this.formControlNames.Wednesday]: [],
        [this.formControlNames.Thursday]: [],
        [this.formControlNames.Friday]: [],
        [this.formControlNames.Saturday]: [],
        [this.formControlNames.Sunday]: [],
      }),
    });
  }
}
