import { Injectable } from '@angular/core';
import { CalendarService } from '../../../../services/calendar.service';
import { ITimeBlockComponentItem } from '../../time-block-component-items';
import { ParallelTimeBlockWeekViewInnerdayCalculatorService } from './parallel-time-block-geometry/parallel-time-block-week-view-innerday-calculator-service';
import { ParallelTimeBlockWeekViewFulldayCalculatorService } from './parallel-time-block-geometry/parallel-time-block-week-view-fullday-calculator.service';
import { TimeBlockCrudService } from '../../crud/time-block-crud.service';
import { CalendarView } from '../../../../../../shared/data-types/calendar-types';
import { ParallelTimeBlockMonthViewCalculatorService } from './parallel-time-block-geometry/parallel-time-block-month-view-calculator.service';
import {
  IFulldayViewType,
  TimeBlockType,
} from '../../../../../../shared/data-types/time-block-types';
import { TimeBlockStructureService } from '../../time-block-structure/time-block-structure.service';
import { TimeBlockItemBuilderService } from '../../generation/time-block-item-builder.service';

@Injectable()
export class ParallelTimeBlockGeometryCalculatorService {
  constructor(
    private readonly calendarService: CalendarService,
    private readonly timeBlockStructureService: TimeBlockStructureService,
    private readonly timeBlockItemBuilderService: TimeBlockItemBuilderService,
    private readonly timeBlockCrudService: TimeBlockCrudService,
    private readonly parallelTimeBlockWeekViewInnerdayCalculatorService: ParallelTimeBlockWeekViewInnerdayCalculatorService,
    private readonly parallelTimeBlockWeekViewFulldayCalculatorService: ParallelTimeBlockWeekViewFulldayCalculatorService,
    private readonly parallelTimeBlockMonthViewCalculator: ParallelTimeBlockMonthViewCalculatorService,
  ) {}

  public updateParallelTimeBlockGeometries(isFullday?: boolean): ITimeBlockComponentItem[] {
    const viewMode = this.calendarService.model.calendarViewMode;

    let updatedTimeBlocks: ITimeBlockComponentItem[];
    if (viewMode === CalendarView.DayGrid || viewMode === CalendarView.WeekGrid) {
      updatedTimeBlocks = isFullday
        ? this.updateWeekViewFulldayGeometry()
        : this.updateWeekViewInnerdayGeometry();
    } else {
      updatedTimeBlocks = this.updateMonthViewInnerdayAndFulldayGeometry();
    }

    if (updatedTimeBlocks && updatedTimeBlocks.length > 0) {
      updatedTimeBlocks.forEach((updatedTimeBlock) => {
        // If the time block is a full day time block, set the new parallel index also for the corresponding time block parts.
        if (viewMode === CalendarView.MonthGrid && updatedTimeBlock.timeBlockModel.isFullday) {
          const timeBlockParts = this.timeBlockStructureService.retrieveTimeBlockPart(
            updatedTimeBlock.id,
            TimeBlockType.ExistingBlock,
            -1,
          ) as ITimeBlockComponentItem[];
          timeBlockParts.forEach((timeBlockPart) => {
            const clone = timeBlockPart.clone(this.timeBlockItemBuilderService);
            (clone.timeBlockModel.timeBlockViewType as IFulldayViewType).parallelIndex = (
              updatedTimeBlock.timeBlockModel.timeBlockViewType as IFulldayViewType
            ).parallelIndex;
            this.timeBlockCrudService.replaceTimeBlock(clone);
          });
        } else {
          // Don't set the parallel index for inner day time blocks
          this.timeBlockCrudService.replaceTimeBlock(updatedTimeBlock);
        }
      });
    }

    return updatedTimeBlocks;
  }

  private updateWeekViewFulldayGeometry(): ITimeBlockComponentItem[] {
    return this.parallelTimeBlockWeekViewFulldayCalculatorService.calculate();
  }

  private updateWeekViewInnerdayGeometry(): ITimeBlockComponentItem[] {
    return this.parallelTimeBlockWeekViewInnerdayCalculatorService.calculate();
  }

  private updateMonthViewInnerdayAndFulldayGeometry(): ITimeBlockComponentItem[] {
    return this.parallelTimeBlockMonthViewCalculator.calculate();
  }
}
