import { Injectable } from '@angular/core';
import { WebSocketService } from '../../../../../core/services/web-socket.service';
import { CalendarCronJobsService } from '../../../services/calendar-cron-jobs.service';
import { calendarTrackerStatusInterval } from '../../../../../../assets/config/config-constants';
import { CustomError } from '../../../../../shared/data-types/client-error';
import { plainToInstance } from 'class-transformer';
import { TrackerResponseModel } from '../../../../../core/models/tracker/tracker-response.model';
import {
  TimeBlockModel,
  TimeBlockProjectModel,
} from '../../../../../core/models/timeblock/time-block.model';
import { TimeBlockItemBuilderService } from '../generation/time-block-item-builder.service';
import { TimeBlockContentType } from '../../../../../shared/data-types/time-block-types';
import { ITimeBlockComponentItem } from '../time-block-component-items';
import { Observable, Subject } from 'rxjs';
import { IActiveTimeBlock } from './active-time-block-interfaces';
import { SharedActiveTimeBlockService } from './shared-active-time-block.service';
import { TimeBlockCrudService } from '../crud/time-block-crud.service';
import { CalendarEvents } from '../../../../../shared/data-types/calendar-types';
import { CalendarServiceHelper } from '../../../services/calendar-service-helper';
import { CalendarService } from '../../../services/calendar.service';
import { assert } from '../../../../../core/assert/assert';

@Injectable()
export class ActiveTimeBlockTrackerService implements IActiveTimeBlock {
  public trackerAliveChanged$ = new Subject<boolean>();
  private trackerAlive = false;
  private activeTimeBlock: ITimeBlockComponentItem | null = null;
  private timerOperation = TimerOperation.None;

  constructor(
    private readonly webSocketService: WebSocketService,
    private readonly calendarCronJobsService: CalendarCronJobsService,
    private readonly calendarService: CalendarService,
    private readonly sharedActiveTimeBlockService: SharedActiveTimeBlockService,
    private readonly timeBlockItemBuilderService: TimeBlockItemBuilderService,
    private readonly timeBlockCrudService: TimeBlockCrudService,
  ) {
    this.calendarCronJobsService.registerJob(
      this.webSocketService.sendMessage,
      calendarTrackerStatusInterval,
    );
    this.initCallback();
  }

  public registerTimeTracking(): void {
    this.webSocketService.webSocket$.subscribe({
      next: (trackerResponse) => this.processResponse(trackerResponse), // Called whenever there is a message from the server.
      error: (err) => {
        throw new CustomError('Error', err);
      }, // Called if at any point WebSocket API signals some kind of error.
      complete: () => {
        throw new CustomError('Error', 'The connection closed unexpectedly.');
      }, // Called when connection is closed (for whatever reason).
    });
  }

  public insertActiveTimeBlock(generatedTimeBlock: ITimeBlockComponentItem): number {
    return this.sharedActiveTimeBlockService.insertActiveTimeBlock(generatedTimeBlock);
  }

  public updateActiveTimeBlock(activeTimeBlock: ITimeBlockComponentItem): void {
    if (!this.sharedActiveTimeBlockService.canUpdate(activeTimeBlock.id)) {
      return;
    }

    this.sharedActiveTimeBlockService.updateActiveTimeBlock(
      activeTimeBlock.timeBlockModel.id,
      activeTimeBlock.timeBlockModel.end,
    );
  }

  public getTrackerObservable(): Observable<unknown> {
    return this.webSocketService.webSocket$;
  }

  public sendMessage(): void {
    this.webSocketService.sendMessage();
  }

  private processResponse(trackerResponse: unknown): void {
    const trackerResponseModel = plainToInstance(TrackerResponseModel, trackerResponse);
    const timeBlockModel = trackerResponseModel.timeBlockModel;

    // Handle non-running or transition states
    if (this.shouldSkipProcessing()) {
      return;
    }

    if (this.isStartingNewTimeBlock(timeBlockModel)) {
      this.startOrUpdateTimeBlock(timeBlockModel);
    } else if (this.activeTimeBlock !== null) {
      this.stopTimeBlock();
    }

    // Update the tracker state which is reflected in the calendar control bar.
    this.updateTrackerState(trackerResponseModel.isTrackerAlive);
  }

  private shouldSkipProcessing(): boolean {
    return (
      this.timerOperation === TimerOperation.Start || this.timerOperation === TimerOperation.Stop
    );
  }

  private isStartingNewTimeBlock(timeBlockModel: TimeBlockModel | null): boolean {
    return (
      timeBlockModel &&
      (this.timerOperation === TimerOperation.None ||
        this.timerOperation === TimerOperation.Running ||
        this.activeTimeBlock.id !== timeBlockModel.id)
    );
  }

  private startOrUpdateTimeBlock(timeBlockModel: TimeBlockModel): void {
    const activeTimeBlockModel = plainToInstance(TimeBlockProjectModel, timeBlockModel);
    const activeTimeBlock = this.timeBlockItemBuilderService.buildFromExisting(
      activeTimeBlockModel,
      TimeBlockContentType.Project,
    );

    if (!this.activeTimeBlock) {
      this.startTimeBlock(activeTimeBlock);
    } else {
      this.updateActiveTimeBlock(activeTimeBlock);
    }
  }

  private startTimeBlock(activeTimeBlock: ITimeBlockComponentItem): void {
    this.timerOperation = TimerOperation.Start;
    this.timeBlockCrudService.reloadTimeBlocks$.next(true);
    this.activeTimeBlock = activeTimeBlock;
    this.sharedActiveTimeBlockService.trackerActiveTimeBlockIdChanged$.next(activeTimeBlock.id);
  }

  private stopTimeBlock(): void {
    this.timerOperation = TimerOperation.Stop;
    this.timeBlockCrudService.reloadTimeBlocks$.next(true);
    this.activeTimeBlock = null;
    this.sharedActiveTimeBlockService.trackerActiveTimeBlockIdChanged$.next(-1);
  }

  private updateTrackerState(isTrackerAlive: boolean): void {
    if (this.trackerAlive === isTrackerAlive) {
      return;
    }

    this.trackerAliveChanged$.next(isTrackerAlive);
    this.trackerAlive = isTrackerAlive;
    this.sharedActiveTimeBlockService.timerActiveTimeBlockIdChanged$.next(-1);

    if (!this.trackerAlive) {
      this.timerOperation = TimerOperation.None;
    }
  }

  private initCallback(): void {
    const targetEvents = [CalendarEvents.RenderedTimeBlocks];
    const callback = (): void => {
      if (this.timerOperation === TimerOperation.Start) {
        assert(this.activeTimeBlock !== null, 'Active time block is invalid.');
        this.insertActiveTimeBlock(this.activeTimeBlock);
        this.timerOperation = TimerOperation.Running;
      } else if (this.timerOperation === TimerOperation.Running && this.activeTimeBlock) {
        // Viewport was resized.
        this.insertActiveTimeBlock(this.activeTimeBlock);
      }

      // After an active time block was stopped, set timer operation to running to wait for the next active time block
      if (this.timerOperation === TimerOperation.Stop) {
        this.timerOperation = TimerOperation.Running;
      }
    };

    CalendarServiceHelper.calendarModelUpdated(this.calendarService, callback, targetEvents);
  }
}

enum TimerOperation {
  Start,
  Running,
  Stop,
  None,
}
