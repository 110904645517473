import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { pageMap } from '../../../core/routing/mapping/page-mapping';
import { NavigationWrapper } from '../../../core/routing/navigation-data';
import { PageKey } from '../../../core/routing/page-keys';
import { take } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserModel } from '../../../core/models/user/user.model';
import { UserService } from '../services/user.service';
import { ShiveRoutes } from '../../../core/routing/segments/route-segments';
import { faBusinessTime, faChairOffice, faLocationDot } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
  public tabs: NavigationWrapper[] = [];
  public userFullName = '';
  public readonly faChairOffice = faChairOffice;
  public readonly faLocationDot = faLocationDot;
  public readonly faBusinessTime = faBusinessTime;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly userService: UserService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data
      .pipe(
        take(1),
        map((data) => data.user),
      )
      .subscribe((user: UserModel) => {
        this.userService.addUser(user);
        this.userFullName = `${user.firstName} ${user.lastName}`; // Todo: Put this into a pipe.
      });

    this.activatedRoute.firstChild.params.pipe(take(1)).subscribe((params) => {
      const id = params.id;

      const navBaseData = pageMap.get(PageKey.UserProfilePageKeys.UserProfileBaseData);
      const navAttachments = pageMap.get(PageKey.UserProfilePageKeys.UserProfileAttachments);

      const baseDataNavItem = {
        ...navBaseData,
        route: navBaseData.route.replace(ShiveRoutes.AnyId, id),
      };

      const attachmentsNavItem = {
        ...navAttachments,
        route: navAttachments.route.replace(ShiveRoutes.AnyId, id),
      };

      this.tabs = [baseDataNavItem, attachmentsNavItem];
    });
  }
}
