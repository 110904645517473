import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserOperation } from 'src/app/core/enums/user-operation';
import { ShiveDialogService } from '../../../../../../core/services/controls/shive-dialog.service';
import { faCirclePlus, faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { CustomSameDateValidator } from '../../../../../../shared/validators/custom-same-date.validator';
import { HolidayTemplateEntryModel } from '../../../../../../core/models/settings/holiday/holiday-template-entry.model';

@Component({
  selector: 'app-holiday-settings-holiday-dialog',
  templateUrl: './holiday-settings-holiday-dialog.component.html',
})
export class HolidaySettingsHolidayDialogComponent implements OnInit {
  @ViewChild('dialogFooter') dialogFooter: TemplateRef<unknown>;
  @Input() holidayId = null;
  @Input() holidayName = '';
  @Input() holidayDate = new Date();
  @Input() userOperation: UserOperation;
  @Input() holidays: HolidayTemplateEntryModel[] = [];

  public UserOperation = UserOperation;
  public holidayForm: FormGroup;
  public readonly faCirclePlus = faCirclePlus;
  public readonly faPenToSquare = faPenToSquare;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly shiveDialogService: ShiveDialogService,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  public submit(): void {
    if (this.holidayForm.valid) {
      const holidayName = this.holidayForm.get('holidayName').value;
      const holidayDate = this.holidayForm.get('holidayDate').value;
      this.shiveDialogService.close([holidayName, holidayDate]);
    }
  }

  public close(): void {
    this.shiveDialogService.close(UserOperation.Cancel);
  }

  private initForm(): void {
    if (this.holidayId) {
      const currentIndex = this.holidays.findIndex((holiday) => holiday.id === this.holidayId);
      this.holidays.splice(currentIndex, 1);
    }

    this.holidayForm = this.formBuilder.group({
      holidayName: [this.holidayName, Validators.required],
      holidayDate: [
        this.holidayDate,
        [
          Validators.required,
          CustomSameDateValidator.uniqueHolidayDateInList(
            this.holidays.map((holiday) => holiday.date),
          ),
        ],
      ],
    });
  }
}
