import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { Position } from 'src/app/shared/data-structures/position';
import { ITimeBlockComponentItem } from '../components/time-block/time-block-component-items';
import { CalendarGeometryService } from '../services/calendar-geometry.service';
import { CalendarService } from '../services/calendar.service';
import { TimeCoordinateMappingService } from '../time-mapping/time-coordinate-mapping.service';
import { CalendarView } from '../../../shared/data-types/calendar-types';
import { DateTimeHelper } from '../util/date-time-helper';

@Injectable()
export class CalendarMouseHandlerService {
  public timeBlockPressed$ = new Subject<ITimeBlockComponentItem>();
  public mousePosChangedSubj$ = new BehaviorSubject<MousePositionWrapper>(null);
  public timeBlockPressedSub: Subscription;

  /**
   * Return the mouse position after a mouse move event
   */
  public get MouseMovePositionWrapper(): MousePositionWrapper {
    return this.mouseMovePositionWrapper;
  }

  /**
   * Return the mouse position after a click event
   */
  public get MouseClickPositionWrapper(): MousePositionWrapper {
    return this.mouseClickPositionWrapper;
  }

  // The mouse position after the mouse was moved
  private mouseMovePositionWrapper: MousePositionWrapper = {
    mouseCalendarPosition: {
      x: -1,
      y: -1,
    },
    mouseViewportPosition: {
      x: -1,
      y: -1,
    },
  };

  // The mouse position when the mouse was clicked
  private mouseClickPositionWrapper: MousePositionWrapper = {
    mouseCalendarPosition: {
      x: -1,
      y: -1,
    },
    mouseViewportPosition: {
      x: -1,
      y: -1,
    },
  };

  constructor(
    private readonly timeCoordinateMappingService: TimeCoordinateMappingService,
    private readonly calendarService: CalendarService,
    private readonly calendarGeometryService: CalendarGeometryService,
  ) {}

  /**
   *
   * @param x The mouse x distance to the left edge of the browser window
   * @param y The mouse y distance to the top edge of the browser window. Includes scrolling.
   * @param type The type of event, e.g. "click", "hashchange", or "submit".
   */
  public mousePosChanged(x: number, y: number, type: string): void {
    const mouseViewportPosition = {
      x,
      y,
    };

    const mouseCalendarPosition = this.calcMouseCalendarPos(mouseViewportPosition);

    if (type.toLowerCase() === 'mousedown') {
      this.mouseClickPositionWrapper = {
        mouseCalendarPosition,
        mouseViewportPosition,
      };
      this.mousePosChangedSubj$.next(this.mouseClickPositionWrapper);
    } else {
      this.mouseMovePositionWrapper = {
        mouseCalendarPosition,
        mouseViewportPosition,
      };
      this.mousePosChangedSubj$.next(this.mouseMovePositionWrapper);
    }
  }

  /**
   * Map the mouse viewport position to the calendar position.
   */
  public calcMouseCalendarPos(mouseViewportPosition: Position): Position {
    return this.timeCoordinateMappingService.toCalendarCoordinate({
      x: mouseViewportPosition.x,
      y: mouseViewportPosition.y,
    });
  }

  /**
   * Return the lane the mouse cursor is positioned in. A lane is a container that holds the time blocks.
   * For the month view, the lanes are structured as a grid. So the cell index is effectively returned
   */
  public calcVisibleMouseLaneIndex(
    calendarMousePosX: number,
    calendarMousePosY: number | null,
    fulldayLane: boolean,
  ): number {
    const viewType = this.calendarService.model.calendarViewMode;
    let mouseLaneIndex = -1;
    if (viewType === CalendarView.DayGrid || viewType === CalendarView.WeekGrid) {
      const cellWidth = fulldayLane
        ? this.calendarGeometryService.getDayOrWeekFulldayLaneWidth()
        : this.calendarGeometryService.getDayOrWeekInnerdayLaneWidth();
      mouseLaneIndex = Math.floor(calendarMousePosX / cellWidth);
    } else {
      if (calendarMousePosY === null) {
        throw new Error('Calendar mouse pos y is null.');
      }
      const cellWidth = this.calendarGeometryService.getMonthFulldayLaneWidth();
      const columnIndex = Math.floor(calendarMousePosX / cellWidth);

      const cellHeight = this.calendarGeometryService.getMonthFulldayLaneHeight();
      const rowIndex = Math.floor(calendarMousePosY / cellHeight);
      const columnCount = DateTimeHelper.daysInWeek(); // Should be 7
      mouseLaneIndex = rowIndex * columnCount + columnIndex;
    }
    return mouseLaneIndex;
  }
}

export interface MousePositionWrapper {
  mouseViewportPosition: Position; // The position of the mouse cursor with respect to the browser window, regardless scrolling.
  mouseCalendarPosition: Position; // The position of the mouse cursor with respect to the calendar's edges. Includes scrolling.
}

export interface ScrollData {
  scrollPosY: number;
  scrollDelta: number;
}

export enum MouseEventType {
  Scroll,
  Drag,
}
