import { NavigationWrapper } from '../../navigation-data';
import { ShiveRoutes } from '../../segments/route-segments';
import { UrlSegmentDelimiter } from '../../../data-repository/css-constants';

export const UserProfile: NavigationWrapper = {
  title: 'User',
  route: ShiveRoutes.UserProfile,
};

export const UserProfileBaseData: NavigationWrapper = {
  title: 'Benutzerdaten',
  route: ShiveRoutes.UserProfileBaseData + UrlSegmentDelimiter + ShiveRoutes.AnyId,
};

export const UserProfileAttachments: NavigationWrapper = {
  title: 'Anhänge',
  route: ShiveRoutes.UserProfileAttachments + UrlSegmentDelimiter + ShiveRoutes.AnyId,
};
