import { Component, OnInit } from '@angular/core';
import { NavigationWrapper } from '../../../core/routing/navigation-data';
import { pageMap } from '../../../core/routing/mapping/page-mapping';
import { ActivatedRoute } from '@angular/router';
import { PageKey } from '../../../core/routing/page-keys';
import { map, take } from 'rxjs/operators';
import { ProjectModel } from '../../../core/models/project/project.model';
import { ProjectService } from '../services/project.service';
import { ShiveRoutes } from '../../../core/routing/segments/route-segments';

@Component({
  selector: 'app-detail-project',
  templateUrl: './detail-project.component.html',
})
export class DetailProjectComponent implements OnInit {
  public tabs: NavigationWrapper[] = [];
  public title = '';

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly projectService: ProjectService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data
      .pipe(
        take(1),
        map((data) => data.project),
      )
      .subscribe((project: ProjectModel) => {
        this.projectService.addProject(project);
        this.title = project.name;
      });

    this.initTabs();
  }

  private initTabs(): void {
    this.activatedRoute.firstChild.params.pipe(take(1)).subscribe((params) => {
      const id = params.id;

      const navStatus = pageMap.get(PageKey.ProjectPageKeys.ProjectStatus);
      const navEdit = pageMap.get(PageKey.ProjectPageKeys.ProjectEdit);
      const navAttachments = pageMap.get(PageKey.ProjectPageKeys.ProjectAttachments);
      const navLogs = pageMap.get(PageKey.ProjectPageKeys.ProjectLogs);

      const statusNavItem: NavigationWrapper = {
        ...navStatus,
        route: navStatus.route.replace(ShiveRoutes.AnyId, id),
      };

      const editNavItem: NavigationWrapper = {
        ...navEdit,
        route: navEdit.route.replace(ShiveRoutes.AnyId, id),
      };

      const attachmentsNavItem: NavigationWrapper = {
        ...navAttachments,
        route: navAttachments.route.replace(ShiveRoutes.AnyId, id),
      };

      const logsNavItem: NavigationWrapper = {
        ...navLogs,
        route: navLogs.route.replace(ShiveRoutes.AnyId, id),
      };

      this.tabs = [statusNavItem, editNavItem, attachmentsNavItem, logsNavItem];
    });
  }
}
