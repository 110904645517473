import { ViewHandler } from './view-handler';
import { CalendarService } from '../../services/calendar.service';
import { TimeBlockCrudService } from '../../components/time-block/crud/time-block-crud.service';
import { TimeCoordinateMappingService } from '../../time-mapping/time-coordinate-mapping.service';
import {
  CalendarEvents,
  CalendarView,
  WeekComposite,
} from '../../../../shared/data-types/calendar-types';
import { BaseCalendarModel } from '../../../../core/models/calendar/base-calendar.model';
import { MonthCalendarModel } from '../../../../core/models/calendar/month-calendar.model';
import { SidebarService } from '../../../../core/services/ui/sidebar.service';
import { CalendarServiceHelper } from '../../services/calendar-service-helper';
import { CalendarViewHandlerService } from '../../services/calendar-view-handler.service';
import { DayCompositeConverter } from '../../converter/day-composite-converter';

export abstract class MonthViewHandler extends ViewHandler {
  public weekComposites: WeekComposite[] = [];

  protected constructor(
    calendarService: CalendarService,
    timeBlockCrudService: TimeBlockCrudService,
    timeCoordinateMappingService: TimeCoordinateMappingService,
    sidebarService: SidebarService,
    calendarViewHandlerService: CalendarViewHandlerService,
  ) {
    super(
      calendarService,
      timeBlockCrudService,
      timeCoordinateMappingService,
      sidebarService,
      calendarViewHandlerService,
    );
  }

  protected initEvents(): void {
    const targetEvents = [
      CalendarEvents.ChangedStartEndDates,
      CalendarEvents.InitializedCalendarView,
    ];
    const callback = (calendarModel: BaseCalendarModel): void => {
      if (!(calendarModel instanceof MonthCalendarModel)) {
        return;
      }

      // Purge store
      this.tbCrudService.purgeTimeBlockStore();

      // Initialize days after loading of calendar or changing week.
      this.weekComposites = DayCompositeConverter.toWeekComposite(calendarModel);
      this.tbCrudService.reloadTimeBlocks$.next(true);
    };
    this.sendTimeBlocksToView();

    // Execute callback when calendar model was updated
    this.subs.sink = CalendarServiceHelper.calendarModelUpdated(
      this.calService,
      callback,
      targetEvents,
    );
  }

  protected checkIfOvernight(): void {
    const targetEvents = [CalendarEvents.NowIndicatorPassedMidnight];
    const callback = (calendarModel: BaseCalendarModel, calendarEvents: CalendarEvents[]): void => {
      if (calendarEvents.includes(CalendarEvents.NowIndicatorPassedMidnight)) {
        this.calViewHandlerService.updateMonthCalendarModel(
          new Date(),
          calendarModel,
          CalendarView.MonthGrid,
        );
        this.weekComposites = DayCompositeConverter.toWeekComposite(
          calendarModel as MonthCalendarModel,
        );
        this.tbCrudService.reloadTimeBlocks$.next(true);
        this.calViewHandlerService.reRenderFulldayContainerDays$.next(null);
      }
    };

    this.subs.sink = CalendarServiceHelper.calendarModelUpdated(
      this.calService,
      callback,
      targetEvents,
    );
  }
}
