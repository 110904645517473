import { Component } from '@angular/core';
import { mapToDropdownOptions } from '../../../../shared/functions/dropdown-functions';
import { TimeUnitOptionLiterals } from '../../../../core/text-db/units.text';
import { Observable } from 'rxjs';
import { DropdownOption } from '../../../../shared/data-types/dropdown-option';
import { RenderEvent } from '@progress/kendo-angular-charts';
import { Rect } from '@progress/kendo-drawing/geometry';
import { Group, Path, Text } from '@progress/kendo-drawing';

@Component({
  selector: 'app-status-project',
  templateUrl: './status-project.component.html',
})
export class StatusProjectComponent {
  public viewOptions$: Observable<DropdownOption[]>;
  private readonly plotValue = 100;

  constructor() {
    this.initEvents();
  }

  public onRender = (args: RenderEvent): void => {
    const chart = args.sender;

    // get the axes
    const valueAxis = chart.findAxisByName('valueAxis');
    const categoryAxis = chart.findAxisByName('categoryAxis');

    // get the coordinates of the value at which the plot band will be rendered
    const valueSlot = valueAxis.slot(this.plotValue) as Rect;

    // get the coordinates of the entire category axis range
    const range = categoryAxis.range();
    const categorySlot = categoryAxis.slot(range.min as number, range.max as number) as Rect;

    // draw the plot band based on the found coordinates
    const line = new Path({
      stroke: {
        color: 'red',
        width: 3,
      },
    })
      .moveTo(valueSlot.origin)
      .lineTo(categorySlot.topRight().x, valueSlot.origin.y);

    const label = new Text('MAX', [0, 0], {
      fill: {
        color: 'red',
      },
      font: '14px sans',
    });
    const bbox = label.bbox();
    label.position([
      categorySlot.topRight().x - bbox.size.width,
      valueSlot.origin.y - bbox.size.height,
    ]);

    const group = new Group();
    group.append(line, label);

    // Draw on the Chart surface to overlay the series
    chart.surface.draw(group);

    // Or as a first element in the pane to appear behind the series
    // chart.findPaneByIndex(0).visual.insert(0, group);
  };

  private initEvents(): void {
    this.viewOptions$ = mapToDropdownOptions(TimeUnitOptionLiterals);
  }
}
