import { createReducer, on } from '@ngrx/store';
import { setAppConfigData } from './app-config.actions';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { AppConfigModel } from '../../models/app-config/app-config.model';

export interface AppConfigState extends EntityState<AppConfigModel> {}

export const appConfigAdapter: EntityAdapter<AppConfigModel> =
  createEntityAdapter<AppConfigModel>();
const initialState: AppConfigState = appConfigAdapter.getInitialState();

export const appConfigReducer = createReducer(
  initialState,
  on(setAppConfigData, (state, { payload }): AppConfigState => {
    return appConfigAdapter.addOne(payload, state);
  }),
);
