import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  SecurityContext,
  ViewChild,
} from '@angular/core';
import { UserModel } from '../../../../../core/models/user/user.model';
import { UserOperation } from '../../../../../core/enums/user-operation';
import { HolidaySettingsStore } from '../../state-and-data-handling/holiday-settings.store';
import { ShiveDialogService } from '../../../../../core/services/controls/shive-dialog.service';
import { filter, take } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { AddMemberDialogComponent } from '../../../../../shared/components/dialogs/add-member-dialog/add-member-dialog.component';
import { cloneDeep } from 'lodash-es';
import { ShiveGridComponent } from '../../../../../shared/components/form-components/shive-grid/shive-grid.component';
import { Logger } from '../../../../../shared/logging/logger';
import { HolidaySettingsService } from '../holiday-settings.service';

@Component({
  selector: 'app-holiday-settings-user-grid',
  templateUrl: './holiday-settings-member-grid.component.html',
})
export class HolidaySettingsMemberGridComponent implements AfterViewInit, OnDestroy {
  @Input() members: UserModel[];
  @Input() holidayListId: number;
  @ViewChild('gridWrapperComponent') gridWrapperComponent: ShiveGridComponent;
  public readonly SecurityContext = SecurityContext;
  public readonly UserOperation = UserOperation;
  private readonly subs = new SubSink();

  constructor(
    private readonly holidaySettingsStore: HolidaySettingsStore,
    private readonly holidaySettingsService: HolidaySettingsService,
    private readonly shiveDialogService: ShiveDialogService,
  ) {}

  ngAfterViewInit(): void {
    this.initContextMenuCallbacks();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public insertMember(): void {
    const dialogRef = this.buildDialog();
    const holidayTemplateModel = this.holidaySettingsService.getHolidayTemplateClone(
      this.holidayListId,
    );

    if (!holidayTemplateModel) {
      throw new Error(`Holiday template could not be found. id: ${this.holidayListId}`);
    }

    const membersInGrid = holidayTemplateModel.members;
    const addMemberDialogComponent = dialogRef.content.instance as AddMemberDialogComponent;
    addMemberDialogComponent.membersInGrid = [...membersInGrid];
    addMemberDialogComponent.filterSelf = false;

    this.subs.sink = dialogRef.result
      .pipe(
        filter((value) => value !== UserOperation.Cancel),
        take(1),
      )
      .subscribe((result) => {
        const newUsers = result as UserModel[];
        holidayTemplateModel.members = [...newUsers, ...holidayTemplateModel.members];
        this.members = [...holidayTemplateModel.members];

        Logger.debug('Template updated in upsertHoliday()', holidayTemplateModel);
        this.holidaySettingsStore.updateHolidayTemplate(holidayTemplateModel);
        this.holidaySettingsStore.userOperationConducted.next(true);
      });
  }

  private initContextMenuCallbacks(): void {
    this.gridWrapperComponent.contextMenuDeleteFn = (user: UserModel) => {
      this.removeMember(user.id);
    };

    this.gridWrapperComponent.setContextMenuItems();
  }

  private removeMember(userId: number) {
    this.subs.sink = this.holidaySettingsStore
      .selectSingleHolidayTemplate(this.holidayListId)
      .pipe(take(1))
      .subscribe((holidayTemplateModel) => {
        const clonedHolidayTemplateModel = cloneDeep(holidayTemplateModel);
        const clonedmembers = cloneDeep([...clonedHolidayTemplateModel.members]);
        const removedUserIndex = clonedHolidayTemplateModel.members.findIndex(
          (user) => user.id === userId,
        );
        if (removedUserIndex < 0) {
          throw new Error('Holiday could not be found.');
        }
        clonedmembers.splice(removedUserIndex, 1);
        clonedHolidayTemplateModel.members = clonedmembers;
        this.members = [...clonedHolidayTemplateModel.members];
        Logger.debug('Template updated in upsertHoliday()', clonedHolidayTemplateModel);
        this.holidaySettingsStore.updateHolidayTemplate(clonedHolidayTemplateModel);
        this.holidaySettingsStore.userOperationConducted.next(true);
      });
  }

  private buildDialog() {
    return this.shiveDialogService.open({
      content: AddMemberDialogComponent,
      width: 572,
      title: 'Benutzer hinzufügen',
    });
  }
}
