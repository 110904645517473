import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ListTeamDataService } from './list-team-data.service';
import { ShiveRoutes } from '../../../core/routing/segments/route-segments';
import { CellClickEvent, GridComponent } from '@progress/kendo-angular-grid';
import { ActivatedRoute, Router } from '@angular/router';
import { UserModel } from '../../../core/models/user/user.model';
import { ShiveContextMenuComponent } from '../../../shared/components/context/shive-context-menu/shive-context-menu.component';
import { ShiveGridComponent } from '../../../shared/components/form-components/shive-grid/shive-grid.component';

@Component({
  selector: 'app-list-team',
  templateUrl: './list-team.component.html',
})
export class ListTeamComponent implements OnInit, AfterViewInit {
  @ViewChild('grid') grid: GridComponent;
  @ViewChild('gridWrapperComponent') gridWrapperComponent: ShiveGridComponent;
  @ViewChild('contextMenu') contextMenu: ShiveContextMenuComponent;
  public readonly ShiveRoutes = ShiveRoutes;

  constructor(
    public readonly listTeamDataService: ListTeamDataService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.listTeamDataService.loadGridData();
  }

  ngAfterViewInit(): void {
    this.initContextMenuCallbacks();
  }

  public onCellClick(e: CellClickEvent): void {
    if (e.columnIndex === this.grid.columns.length - 1) {
      return;
    }
    const userModel = e.dataItem as UserModel;
    void this.router.navigate(['../', ShiveRoutes.Detail, ShiveRoutes.UserProfile, userModel.id], {
      relativeTo: this.route,
    });
  }

  private initContextMenuCallbacks(): void {
    this.gridWrapperComponent.contextMenuEditFn = () => {};
    this.gridWrapperComponent.contextMenuDeleteFn = () => {};
    this.gridWrapperComponent.setContextMenuItems();
  }
}
