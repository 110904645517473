enum DashboardPageKeys {
  Dashboard = 0,
}

enum CalendarPageKeys {
  Calendar = 100,
}

enum ProjectPageKeys {
  Projects = 200,
  ProjectOverview,
  ProjectAdd,
  ProjectEdit,
  ProjectStatus,
  ProjectAttachments,
  ProjectLogs,
}

enum ClientPageKeys {
  Clients = 300,
  ClientList,
  ClientAdd,
  ClientEdit,
}

enum TeamPageKeys {
  Team = 400,
  TeamOverview,
  TeamAbsences,
  TeamAbsencePlanner,
  TeamVacationQuota,
  TeamLeaveRequests,
}

enum UserProfilePageKeys {
  UserProfile = 500,
  UserProfileBaseData,
  UserProfileAttachments,
}

enum SettingsPageKeys {
  Settings = 600,
  SettingsNonworkingTime,
}

export const PageKey = {
  DashboardPageKeys,
  CalendarPageKeys,
  ProjectPageKeys,
  ClientPageKeys,
  TeamPageKeys,
  UserProfilePageKeys,
  SettingsPageKeys,
};
