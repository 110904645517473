import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import * as fromTimeBlockActions from '../../../../../core/state/time-blocks/time-blocks.actions';
import { TimeBlockCrudService } from '../crud/time-block-crud.service';
import { TimeBlockStructureService } from '../time-block-structure/time-block-structure.service';
import { LocalizationService } from '../../../../../core/services/localization.service';
import { UndoRedoService } from '../../../../../core/state/undo-redo/undo-redo.service';
import {
  NotificationType,
  ShiveNotificationService,
} from '../../../../../core/services/controls/shive-notification.service';
import { UserOperation } from '../../../../../core/enums/user-operation';
import { TimeBlockModifiedResponse } from '../../../../../shared/data-types/http-response-types';
import { TimeBlockType } from '../../../../../shared/data-types/time-block-types';
import { TimeBlockItemBuilderService } from '../generation/time-block-item-builder.service';
import { ITimeBlockComponentItem } from '../time-block-component-items';
import { TimeBlockService } from '../services/time-block.service';
import { ParallelTimeBlockGeometryCalculatorService } from '../calculation/geometry/parallel-time-block-geometry-calculator.service';
import { TimeBlockDurationCalculationService } from '../calculation/duration/time-block-duration-calculation.service';
import { CalendarHeaderService } from '../../calendar-header/calendar-header.service';
import { CalendarService } from '../../../services/calendar.service';
import { CalendarView } from '../../../../../shared/data-types/calendar-types';
import { TimeBlockMonthViewContainerRendererService } from '../rendering/time-block-month-view-container-renderer.service';

@Injectable({
  providedIn: 'root',
})
export class TimeBlockHttpCallbackService {
  constructor(
    private readonly actions$: Actions,
    private readonly localizationService: LocalizationService,
    private readonly historyService: UndoRedoService,
    private readonly calendarService: CalendarService,
    private readonly timeBlockContainerRendererService: TimeBlockMonthViewContainerRendererService,
    private readonly calendarHeaderService: CalendarHeaderService,
    private readonly timeBlockDurationCalculationService: TimeBlockDurationCalculationService,
    private readonly timeBlockService: TimeBlockService,
    private readonly parallelTimeBlockGeometryCalculatorService: ParallelTimeBlockGeometryCalculatorService,
    private readonly timeBlockItemBuilderService: TimeBlockItemBuilderService,
    private readonly timeBlockStructureService: TimeBlockStructureService,
    private readonly shiveNotificationService: ShiveNotificationService,
    private readonly timeBlockCrudService: TimeBlockCrudService,
  ) {}

  /**
   *
   * Add, update or remove block after http request has finished.
   */
  public handleHTTPCallbacks(): void {
    this.actions$
      .pipe(
        ofType(
          fromTimeBlockActions.timeBlockAdded,
          fromTimeBlockActions.timeBlockUpdated,
          fromTimeBlockActions.timeBlockDeleted,
        ),
      )
      .subscribe(({ payload, type }) => {
        // Render all altered time blocks returned from the server.
        if (
          type === fromTimeBlockActions.timeBlockAdded.type ||
          type === fromTimeBlockActions.timeBlockUpdated.type
        ) {
          const operation =
            type === fromTimeBlockActions.timeBlockAdded.type
              ? UserOperation.Create
              : UserOperation.Update;
          this.generateModifiedTimeBlocks(payload, operation);
        } else if (type === fromTimeBlockActions.timeBlockDeleted.type) {
          const deletedTimeBlock = this.timeBlockStructureService.retrieveTimeBlockPart(
            payload.deletedTimeBlockId,
          ) as ITimeBlockComponentItem;
          this.timeBlockCrudService.removeTimeBlock(deletedTimeBlock);
        }

        // Updated parallel time block positioning.
        this.parallelTimeBlockGeometryCalculatorService.updateParallelTimeBlockGeometries(true);
        this.parallelTimeBlockGeometryCalculatorService.updateParallelTimeBlockGeometries(false);

        const calendarView = this.calendarService.model.calendarViewMode;
        if (calendarView === CalendarView.DayGrid || calendarView === CalendarView.WeekGrid) {
          this.calendarHeaderService.renderDayOrWeekViewFulldayContainer();
        } else {
          // Hide vertical overflowing time blocks.
          this.timeBlockContainerRendererService.collapseMonthViewOverflowingTimeBlocks();
        }

        // Update time block sums
        this.timeBlockDurationCalculationService.calculateTimeBlockDurationSums$.next(null);

        const message = this.localizationService.getTranslation(payload.responseCode);
        this.shiveNotificationService.show(NotificationType.Success, message);
      });

    this.actions$.pipe(ofType(fromTimeBlockActions.httpFail)).subscribe(() => {
      // this.historyService.doUndo();
    });
  }

  private generateModifiedTimeBlocks(
    timeBlockResponse: TimeBlockModifiedResponse,
    operation: UserOperation,
  ): void {
    if (operation === UserOperation.Create) {
      // Remove the temporary non-existing time block.
      const temporaryBlock = this.timeBlockStructureService.retrieveTimeBlockPart(
        null,
        TimeBlockType.NonExistingBlock,
      ) as ITimeBlockComponentItem;
      if (!temporaryBlock) {
        throw new Error('Original time block which is marked for removal could not be found.');
      }
      this.timeBlockCrudService.removeTimeBlock(temporaryBlock, TimeBlockType.NonExistingBlock);
    }

    const modifiedTimeBlocks = timeBlockResponse.modifiedTimeBlockItems;
    modifiedTimeBlocks.forEach((timeBlockItem) => {
      if (this.timeBlockService.isTimeBlockOutsideOfView(timeBlockItem)) {
        return;
      }

      const modifiedTimeBlock = this.timeBlockItemBuilderService.buildFromExisting(
        timeBlockItem,
        timeBlockItem.timeBlockContentType,
      );

      const existingTimeBlock = this.timeBlockStructureService.retrieveTimeBlockPart(
        modifiedTimeBlock.id,
      ) as ITimeBlockComponentItem;

      if (existingTimeBlock) {
        this.timeBlockCrudService.removeTimeBlock(existingTimeBlock);
      }

      this.timeBlockCrudService.insertTimeBlock(modifiedTimeBlock);
    });
  }
}
