import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromProjectActions from './projects.actions';
import {
  NotificationType,
  ShiveNotificationService,
} from '../../services/controls/shive-notification.service';
import { ProjectModel } from '../../models/project/project.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectsHttpService {
  constructor(
    private readonly store: Store,
    private readonly shiveNotificationService: ShiveNotificationService,
    private readonly actions$: Actions,
  ) {
    this.handleHttpCallbacks();
  }

  public getProjects(clientId?: number): Observable<ProjectModel[]> {
    this.store.dispatch(fromProjectActions.fetchProjects({ payload: clientId }));

    return this.actions$.pipe(
      ofType(fromProjectActions.setProjects),
      map((result) => result.payload),
    );
  }

  public getSingleProject(projectId: number): Observable<ProjectModel> {
    this.store.dispatch(
      fromProjectActions.fetchSingleProject({
        payload: projectId,
      }),
    );

    return this.actions$.pipe(
      ofType(fromProjectActions.setSingleProject),
      map((result) => result.payload),
    );
  }

  public addProject(projectModel: ProjectModel | ProjectModel[]): Observable<unknown> {
    this.store.dispatch(
      fromProjectActions.addProject({
        payload: projectModel,
      }),
    );

    return this.actions$.pipe(ofType(fromProjectActions.projectAdded));
  }

  public updateProject(projectModel: ProjectModel | ProjectModel[]): Observable<unknown> {
    this.store.dispatch(
      fromProjectActions.updateProject({
        payload: projectModel,
      }),
    );

    return this.actions$.pipe(ofType(fromProjectActions.projectUpdated));
  }

  public deleteProject(toBeDeletedProject: ProjectModel | ProjectModel[]): Observable<unknown> {
    this.store.dispatch(
      fromProjectActions.deleteProject({
        payload: toBeDeletedProject,
      }),
    );

    return this.actions$.pipe(ofType(fromProjectActions.projectDeleted));
  }

  private handleHttpCallbacks(): void {
    this.actions$
      .pipe(
        ofType(
          fromProjectActions.projectAdded,
          fromProjectActions.projectUpdated,
          fromProjectActions.projectDeleted,
        ),
      )
      .subscribe(() => {
        // const message = this.localizationService.getTranslation(payload.responseCode);
        this.shiveNotificationService.show(NotificationType.Success);
      });
  }
}
