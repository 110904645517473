<div class="k-mb-10 card-wrapper">
  <div class="page-title-card card card with-left-border k-mb-2">
    <div class="card-body">
      <div class="k-d-flex justify-content-between k-align-items-center k-w-100 k-h-10">
        <div class="page-title k-d-flex k-align-items-center">
          <h1 class="k-mb-0 k-mr-9 font-weight-medium">{{ pageTitle }}</h1>
          @if (prevRoute && prevRoute !== "") {
            <span id="back" class="k-ms-4">
              <a class="k-d-flex k-align-items-center" (click)="navBack()">
                <span class="custom-symbol arrow-left turquoise k-mr-3 k-mt-0.5"></span>
                <span class="label">{{ prevTitle }}</span>
              </a>
            </span>
          }
        </div>
        @if (headerBarControlsTemplate) {
          <div class="header-bar-controls">
            <ng-container [ngTemplateOutlet]="headerBarControlsTemplate"></ng-container>
          </div>
        }
      </div>
      @if (headerBarArbitraryDataTemplate) {
        <div><ng-container [ngTemplateOutlet]="headerBarArbitraryDataTemplate"></ng-container></div>
      }
    </div>
  </div>
</div>
