import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UserOperation } from '../../../../core/enums/user-operation';
import { ClientModel } from '../../../../core/models/client/client.model';
import { ShiveDialogService } from '../../../../core/services/controls/shive-dialog.service';
import { ProjectModel } from '../../../../core/models/project/project.model';
import { ListClientDataService } from '../../list-client/data-handling/list-client-data.service';

@Component({
  selector: 'app-client-dialog',
  templateUrl: './client-dialog.component.html',
})
export class ClientDialogComponent implements OnInit {
  @ViewChild('dialogFooter') dialogFooter: TemplateRef<unknown>;
  @Input() userOperation: UserOperation;
  @Input() clientModel: ClientModel;
  public UserOperation = UserOperation;

  constructor(
    private readonly shiveDialogService: ShiveDialogService,
    private readonly listClientDataService: ListClientDataService,
  ) {}

  ngOnInit(): void {
    this.listClientDataService.dialogFooter = this.dialogFooter;
  }

  public delete(): void {
    const updatedClientModel = new ProjectModel();
    Object.assign(updatedClientModel, this.clientModel);
    updatedClientModel.userOperation = this.userOperation;

    this.shiveDialogService.close(updatedClientModel);
  }

  public close(): void {
    this.shiveDialogService.close();
  }
}
