<app-page-header>
  <ng-template #headerBarControlsTemplate>
    <!-- Template ref Needed for content child directive -->
    <button
      kendoButton
      class="k-ml-22 !k-pr-4"
      themeColor="secondary"
      [routerLink]="['/' + ShiveRoutes.Team, ShiveRoutes.Create]"
      size="large"
    >
      <span class="custom-symbol add turquoise k-mr-3"></span>
      Neues Teammitglied
    </button>
  </ng-template>
</app-page-header>

<div class="card-wrapper k-mt-4">
  <div class="card">
    <div class="card-body">
      <div id="projects-table-wrapper" class="k-w-full">
        <app-shive-grid #gridWrapperComponent [grid]="grid">
          <kendo-grid
            #grid
            [kendoGridBinding]="listTeamDataService.gridData$ | async"
            kendoGridSelectBy="id"
            scrollable="none"
            size="medium"
            [pageSize]="20"
            (cellClick)="onCellClick($event)"
            [selectable]="{ enabled: true, checkboxOnly: true }"
            [columnMenu]="{ filter: true }"
          >
            <kendo-grid-checkbox-column
              [width]="60"
              [headerClass]="{ 'k-text-center': true }"
              [class]="{ 'k-text-center': true }"
              [resizable]="false"
              [columnMenu]="false"
              [showSelectAll]="true"
            ></kendo-grid-checkbox-column>

            <kendo-grid-column field="member_name" title="Name" [class]="'cell-with-avatar'">
              <ng-template kendoGridCellTemplate let-member>
                <div class="k-d-flex k-align-items-center">
                  <app-shared-member-template [user]="member"></app-shared-member-template>
                </div>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="contextMenu" title="" [columnMenu]="false" [width]="60">
              <ng-template kendoGridCellTemplate let-dataItem>
                <app-context-menu-anchor
                  [dataItem]="dataItem"
                  [gridWrapperComponent]="gridWrapperComponent"
                />
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </app-shive-grid>
      </div>
    </div>
  </div>
</div>
