import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DropdownOption } from '../../../../../shared/data-types/dropdown-option';

@Component({
  selector: 'app-user-profile-salary-data',
  templateUrl: './user-profile-salary-data.component.html',
})
export class UserProfileSalaryDataComponent implements OnInit {
  public employmentDataForm: FormGroup;
  public formControlNames: { [key: string]: string } = {
    PaymentType: 'paymentType',
  };

  public readonly paymentTypes: DropdownOption[] = [
    { text: 'Fixgehalt', value: 0 },
    { text: 'auf Stundenbasis', value: 1 },
  ];

  private readonly formBuilder = inject(FormBuilder);

  ngOnInit(): void {
    this.employmentDataForm = this.formBuilder.group({
      [this.formControlNames.PaymentType]: [],
    });
  }
}
