import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import * as fromAuthActions from './state/auth.actions';
import { UserModel } from '../core/models/user/user.model';
import { LoginModel } from '../core/models/auth/login.model';
import { RegisterModel } from '../core/models/auth/register.model';
import { selectLoggedInUser } from './state/auth.selectors';

@Injectable({ providedIn: 'root' })
export class AuthService {
  public user = new BehaviorSubject<string>('');

  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
  ) {}

  public login(loginModel: LoginModel): Observable<LoginModel> {
    this.store.dispatch(
      fromAuthActions.loginUser({
        payload: loginModel,
      }),
    );

    return this.actions$.pipe(ofType(fromAuthActions.userLoggedIn));
  }

  public authenticate(): Observable<UserModel> {
    this.store.dispatch(fromAuthActions.fetchLoggedInUser());

    return this.actions$.pipe(
      ofType(fromAuthActions.setLoggedInUser),
      map((result) => result.payload),
    );
  }

  public register(registerModel: RegisterModel): Observable<RegisterModel> {
    this.store.dispatch(
      fromAuthActions.registerUser({
        payload: registerModel,
      }),
    );

    return this.actions$.pipe(ofType(fromAuthActions.userRegistered));
  }

  public logout(): Observable<unknown> {
    this.store.dispatch(fromAuthActions.logoutUser());

    return this.actions$.pipe(ofType(fromAuthActions.userLoggedOut));
  }

  public unauthorized(): Observable<unknown> {
    return this.actions$.pipe(ofType(fromAuthActions.unauthorized));
  }

  public getLoggedInUser(): Observable<UserModel> {
    return this.store.select(selectLoggedInUser);
  }
}
