import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { HolidayTemplateModel } from '../../../../core/models/settings/holiday/holiday-template.model';
import { HolidaySettingsStore } from '../state-and-data-handling/holiday-settings.store';
import { HolidayTemplatesHttpService } from '../../../../core/state/settings/holiday-templates/holiday-templates-http.service';
import { cloneDeep } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class HolidaySettingsService {
  constructor(
    public holidaySettingsStore: HolidaySettingsStore,
    private readonly holidayTemplatesHttpService: HolidayTemplatesHttpService,
  ) {}

  public getHolidayTemplateClone(templateId: number): HolidayTemplateModel {
    let holidayTemplate: HolidayTemplateModel;
    this.holidaySettingsStore
      .selectSingleHolidayTemplate(templateId)
      .pipe(take(1))
      .subscribe((template) => (holidayTemplate = template));

    return cloneDeep(holidayTemplate);
  }

  public emitHolidaySettingsHttpRequests(): void {
    this.holidaySettingsStore.selectHolidayTemplates$
      .pipe(take(1))
      .subscribe((holidayTemplates) => {
        this.sendPostOrPutRequests(holidayTemplates);
        this.sendDeleteRequests();
      });
  }

  private sendPostOrPutRequests(holidayTemplates: HolidayTemplateModel[]): void {
    holidayTemplates.forEach((template) => {
      if (template.id >= 0) {
        this.holidayTemplatesHttpService.updateHolidayTemplate(template);
      } else {
        this.sendHolidayTemplatePostRequests(template);
      }
    });
  }

  private sendDeleteRequests() {
    const deletionList = this.holidaySettingsStore.holidayTemplateDeletionList;
    deletionList.forEach((holidayTemplate) => {
      this.holidayTemplatesHttpService.deleteHolidayTemplate(holidayTemplate);
    });
    this.holidaySettingsStore.holidayTemplateDeletionList = [];
  }

  /**
   * Remove dummy holiday template from store and add there real one later after the POST request has been finished.
   */
  private sendHolidayTemplatePostRequests(template: HolidayTemplateModel) {
    this.holidaySettingsStore.removeHolidayTemplate(template);
    this.holidayTemplatesHttpService.addHolidayTemplate(template);
  }
}
