import { ElementRef, Injectable, Renderer2, RendererFactory2, inject } from '@angular/core';
import { PopupRef, PopupService, PopupSettings } from '@progress/kendo-angular-popup';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShivePopupService {
  public dataLoaded$ = new BehaviorSubject<any>(null);
  public popupRef: PopupRef;
  public anchor: HTMLElement | ElementRef<HTMLElement>;
  private readonly rendererFactory = inject(RendererFactory2);
  private readonly renderer: Renderer2;

  private readonly defaultContextMenuSettings: PopupSettings = {
    popupAlign: {
      horizontal: 'right',
      vertical: 'center',
    },
    anchorAlign: {
      horizontal: 'right',
      vertical: 'center',
    },
    margin: {
      horizontal: 40,
      vertical: 0,
    },
    popupClass: 'grid-context-menu context-menu',
    animate: false,
  };

  constructor(private readonly popupService: PopupService) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public open(popupSettings: PopupSettings): PopupRef {
    const updatedSettings: PopupSettings = {
      ...this.defaultContextMenuSettings,
      ...popupSettings,
    };

    this.anchor = updatedSettings.anchor;
    this.popupRef = this.popupService.open(updatedSettings);

    const container = this.popupRef.popupElement;
    this.renderer.addClass(container, 'k-overflow-visible');

    return this.popupRef;
  }
}
