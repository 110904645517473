import { HolidayTemplateEntryModel } from './holiday-template-entry.model';
import { UserModel } from '../../user/user.model';
import { Expose, Transform, Type } from 'class-transformer';

export class HolidayTemplateModel {
  public id = null;
  public name = '';
  @Expose({ name: 'member_ids', toPlainOnly: true })
  @Type(() => UserModel)
  @Transform(({ value }) => value.map((v) => v.id), { toPlainOnly: true })
  public members: readonly UserModel[] = [];
  @Expose({ name: 'holidayentry_set' })
  @Type(() => HolidayTemplateEntryModel)
  public holidayEntries: readonly HolidayTemplateEntryModel[] = [];
}
