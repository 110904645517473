import {
  DayComposite,
  DayCompositeOfMonth,
  WeekComposite,
} from '../../../shared/data-types/calendar-types';
import { DateTimeHelper } from '../util/date-time-helper';
import { MonthCalendarModel } from '../../../core/models/calendar/month-calendar.model';
import { Interval } from 'date-fns';

export class DayCompositeConverter {
  public static toDayComposites(
    days: Date[],
    holidays: Date[],
    start?: Date,
    end?: Date,
  ): DayComposite[] {
    return days.map((d) => {
      let weekInterval: Interval;
      if (start && end) {
        weekInterval = { start, end };
      }

      const dayComposite: DayComposite = {
        theDate: d,
        epoch: DateTimeHelper.isWhen(d),
        outsideOfView: weekInterval ? !DateTimeHelper.isWithinInterval(d, weekInterval) : false,
        isFirstVisibleDay: start ? DateTimeHelper.isSameDay(d, start) : false,
        isLastVisibleDay: end ? DateTimeHelper.isSameDay(d, end) : false,
        isHoliday: holidays.some((holiday) => DateTimeHelper.isSameDay(holiday, d)),
      };
      return dayComposite;
    });
  }

  public static toWeekComposite(calendarModel: MonthCalendarModel): WeekComposite[] {
    const currentMonth = calendarModel.calendarProperties.month;
    const year = calendarModel.calendarProperties.year;
    const currentMonthDate = new Date(year, currentMonth);

    const monthInterval: Interval = {
      start: DateTimeHelper.startOfMonth(currentMonthDate),
      end: DateTimeHelper.endOfMonth(currentMonthDate),
    };

    const dayComposites = calendarModel.calendarProperties.days;
    const weekComposites: WeekComposite[] = [];
    const size = DateTimeHelper.daysInWeek();

    for (let i = 0; i < dayComposites.length; i += size) {
      const dayCompositesOfWeek = dayComposites.slice(i, i + size);

      const dayCompositesOfMonth = this.convertToCompositesOfMonth(
        dayCompositesOfWeek,
        calendarModel,
        monthInterval,
      );

      const weekIndex = DateTimeHelper.weekNumber(dayCompositesOfWeek[0].theDate);
      const weekComposite: WeekComposite = {
        days: dayCompositesOfMonth,
        weekIndex,
      };
      weekComposites.push(weekComposite);
    }
    return weekComposites;
  }

  private static convertToCompositesOfMonth(
    dayCompositesOfWeek: DayComposite[],
    calendarModel: MonthCalendarModel,
    monthInterval: Interval,
  ): DayCompositeOfMonth[] {
    return dayCompositesOfWeek.map((dayComposite) => {
      const dayCompositeOfMonth: DayCompositeOfMonth = {
        theDate: dayComposite.theDate,
        epoch: DateTimeHelper.isWhen(dayComposite.theDate),
        outsideOfView: false,
        isFirstVisibleDay: DateTimeHelper.isSameDay(
          dayComposite.theDate,
          calendarModel.calendarProperties.visibleStartDate,
        ),
        isLastVisibleDay: DateTimeHelper.isSameDay(
          dayComposite.theDate,
          calendarModel.calendarProperties.visibleEndDate,
        ),
        isWithinMonth: DateTimeHelper.isWithinInterval(dayComposite.theDate, monthInterval),
        isHoliday: dayComposite.isHoliday,
      };

      return dayCompositeOfMonth;
    });
  }
}
