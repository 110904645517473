import { TimeBlockElementSelectorService } from '../../../../../rendering/time-block-element-selector.service';
import { CSSDraggedClass } from '../../../../../../../../../core/data-repository/css-constants';
import { TimeBlockCrudService } from '../../../../../crud/time-block-crud.service';
import { TimeBlockDragResizeControllerService } from '../../time-block-drag-resize-handling/time-block-drag-resize-controller.service';
import { DragDropPositioningService } from './drag-drop-positioning.service';
import { TimeBlockRenderService } from '../../../../../rendering/time-block-render.service';
import { DragAndDropHandlerService } from './drag-and-drop-handler.service';
import { TimeBlockStructureService } from '../../../../../time-block-structure/time-block-structure.service';
import {
  IFulldayViewType,
  IInnerdayViewType,
  TimeBlockType,
} from '../../../../../../../../../shared/data-types/time-block-types';
import { ITimeBlockComponentItem } from '../../../../../time-block-component-items';
import { RendererStyleFlags2 } from '@angular/core';
import { CssAttributes } from '../../../../../rendering/css-attributes';
import { TimeBlockService } from '../../../../../services/time-block.service';
import { SharedHorizontalDragResizeService } from '../../time-block-drag-resize-handling/shared-horizontal-drag-resize.service';
import { TimeBlockDayOrWeekInnerdayType } from '../../../../../../../../../core/models/timeblock/time-block-view-type.model';

export abstract class AbstractDragController {
  protected dXAbs = 0; // / The total amount of pixels the block moved in x-direction since the drag started.
  protected dYRelAggr = 0;
  protected draggedParts = new Array<ITimeBlockComponentItem>();

  protected constructor(
    private readonly tbCrudService: TimeBlockCrudService,
    private readonly tbStructureService: TimeBlockStructureService,
    private readonly dragDropPosService: DragDropPositioningService,
    private readonly tbRenderService: TimeBlockRenderService,
    private readonly tbDragResizeControllerService: TimeBlockDragResizeControllerService,
    private readonly tbService: TimeBlockService,
    private readonly dAndDHandlerService: DragAndDropHandlerService,
    private readonly sharedDAndRService: SharedHorizontalDragResizeService,
  ) {}

  public beforeStartDragging(): void {
    const draggedTimeBlock = this.tbDragResizeControllerService.TransformationTimeBlock;
    if (draggedTimeBlock.timeBlockModel.isFullday) {
      const tbModel = draggedTimeBlock.timeBlockModel.timeBlockViewType as IFulldayViewType;
      this.dAndDHandlerService.sourceContainerId = tbModel.laneIndexStart;
      this.dAndDHandlerService.targetContainerId = tbModel.laneIndexStart;
    } else {
      const tbModel = draggedTimeBlock.timeBlockModel.timeBlockViewType as IInnerdayViewType;
      this.dAndDHandlerService.sourceContainerId = tbModel.laneIndex;
      this.dAndDHandlerService.targetContainerId = tbModel.laneIndex;
      this.draggedParts = this.tbStructureService.retrieveTimeBlockPart(
        draggedTimeBlock.id,
        TimeBlockType.TransformingBlock,
        -1,
      ) as ITimeBlockComponentItem[];
    }

    // Add CSS classes for all dragged time block parts and also the width.
    const draggingBlock = this.tbDragResizeControllerService.TransformationTimeBlock;
    const timeBlockWidth =
      draggingBlock.timeBlockModel.componentRef.instance.timeBlockHTMLWrapper.nativeElement
        .clientWidth;

    this.draggedParts.forEach((timeBlockPart) => {
      const timeBlockHTMLWrapper =
        TimeBlockElementSelectorService.getTimeBlockHTMLWrapper(timeBlockPart);
      this.tbRenderService.addClass(timeBlockHTMLWrapper, CSSDraggedClass);

      // If this dragging part is outside the view, copy the width of the visible dragging part since it has a width of 0.
      if (timeBlockHTMLWrapper.clientWidth === 0) {
        this.tbRenderService.setStyle(
          timeBlockHTMLWrapper,
          CssAttributes.width,
          `${timeBlockWidth}px`,
          RendererStyleFlags2.Important,
        );
      }
    });
  }

  public afterDragging(event: Interact.DragEvent): void {
    const transformationTimeBlock = this.tbDragResizeControllerService.TransformationTimeBlock;

    // Remove transparent indicator block (ghost block)
    if (this.tbDragResizeControllerService.GhostTimeBlock) {
      this.tbCrudService.removeTimeBlock(
        this.tbDragResizeControllerService.GhostTimeBlock,
        TimeBlockType.GhostBlock,
      );
      this.tbDragResizeControllerService.GhostTimeBlock = null;
    }

    // Remove dragging block from calendar model
    if (this.tbService.isTimeBlockOutsideOfView(transformationTimeBlock)) {
      return;
    }

    this.tbCrudService.removeTimeBlock(transformationTimeBlock, TimeBlockType.TransformingBlock);

    // Set the dragged block or blocks
    transformationTimeBlock.timeBlockModel.type = TimeBlockType.ExistingBlock;
    if (transformationTimeBlock.timeBlockModel.isFullday) {
      this.dragDropPosService.deployFulldayTimeBlocks(event, transformationTimeBlock);
    } else if (
      transformationTimeBlock.timeBlockModel.timeBlockViewType instanceof
      TimeBlockDayOrWeekInnerdayType
    ) {
      this.dragDropPosService.deployDayOrWeekInnerdayTimeBlocks(event, this.draggedParts);
    }

    // Reset everything
    this.dXAbs = 0;
    this.dYRelAggr = 0;
    this.sharedDAndRService.lastMouseLaneIndex = -1;
  }
}
