import { createReducer, on } from '@ngrx/store';
import {
  projectAdded,
  projectDeleted,
  projectUpdated,
  setProjects,
  setSingleProject,
} from './projects.actions';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { ProjectModel } from '../../models/project/project.model';

export interface ProjectState extends EntityState<ProjectModel> {}

export const projectAdapter: EntityAdapter<ProjectModel> = createEntityAdapter<ProjectModel>();
const initialState: ProjectState = projectAdapter.getInitialState();

export const projectsReducer = createReducer(
  initialState,
  on(setProjects, (state, { payload }): ProjectState => {
    return projectAdapter.setMany(payload, state);
  }),
  on(setSingleProject, (state, { payload }): ProjectState => {
    return projectAdapter.setOne(payload, state);
  }),
  on(projectAdded, (state, { payload }): ProjectState => {
    if (Array.isArray(payload)) {
      return projectAdapter.addMany(payload, state);
    }
    return projectAdapter.addOne(payload, state);
  }),
  on(projectUpdated, (state, { payload }): ProjectState => {
    if (Array.isArray(payload)) {
      return projectAdapter.upsertMany(payload, state);
    }
    return projectAdapter.upsertOne(payload, state);
  }),
  on(projectDeleted, (state, { payload }): ProjectState => {
    if (Array.isArray(payload)) {
      return projectAdapter.removeMany(
        payload.map((t) => t.id),
        state,
      );
    }
    return projectAdapter.removeOne(payload.id, state);
  }),
);
