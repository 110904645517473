<div class="form-wrapper k-mt-11">
  <form [formGroup]="projectCalculationForm" class="k-form k-d-grid">
    <div class="col">
      @if (selectedBudgetOption !== BudgetOptions.NoBudget) {
        <div formGroupName="resetBudgetGroup">
          <div class="horizontal-controls k-d-flex !k-align-items-center">
            <input type="checkbox" #resetBudget formControlName="resetBudget" kendoCheckBox />
            <kendo-label
              class="k-checkbox-label"
              [for]="resetBudget"
              text="Das Budget zurücksetzen"
            ></kendo-label>
            <kendo-formfield orientation="horizontal" class="!k-mt-0 !k-mb-0 k-ml-2.5 k-flex-grow">
              <kendo-dropdownlist
                textField="text"
                valueField="value"
                formControlName="resetBudgetFrequency"
              >
              </kendo-dropdownlist>
            </kendo-formfield>
          </div>
        </div>
      }
    </div>

    <div class="col">
      @if (selectedBudgetOption !== BudgetOptions.NoBudget) {
        <div formGroupName="resetBudgetGroup">
          <div class="horizontal-controls k-d-flex !k-align-items-center">
            <kendo-formfield orientation="horizontal" class="!k-mb-0">
              <kendo-datepicker formControlName="resetBudgetAt" rounded="large" [size]="'large'">
              </kendo-datepicker>
            </kendo-formfield>
            <span class="k-align-self-center k-ml-2.5 k-mr-2.5">mit</span>
            <span class="prefix-currency">&euro;</span>
            <kendo-formfield class="!k-mb-0 !k-w-20" orientation="horizontal">
              <kendo-textbox showErrorIcon="initial" formControlName="resetBudgetValue">
              </kendo-textbox>
            </kendo-formfield>
          </div>
        </div>
      }
    </div>

    <div class="col">
      @if (selectedBudgetOption !== BudgetOptions.NoBudget) {
        <div formGroupName="exceedingBudgetGroup">
          <div class="horizontal-controls k-d-flex !k-align-items-center">
            <input
              type="checkbox"
              #exceedingBudgetWarning
              formControlName="exceedingBudgetWarning"
              kendoCheckBox
            />
            <kendo-label
              class="k-checkbox-label"
              [for]="exceedingBudgetWarning"
              text="Benachrichtigung an"
            ></kendo-label>
            <kendo-formfield orientation="horizontal" class="!k-mt-0 !k-mb-0 k-ml-2.5 k-flex-grow">
              <kendo-dropdownlist
                textField="text"
                valueField="value"
                formControlName="exceedingBudgetWarningEmail"
              >
              </kendo-dropdownlist>
            </kendo-formfield>
          </div>
        </div>
      }
    </div>

    <div class="col">
      @if (selectedBudgetOption !== BudgetOptions.NoBudget) {
        <div formGroupName="exceedingBudgetGroup">
          <div class="horizontal-controls k-d-flex !k-align-items-center">
            <span class="k-align-self-center k-mr-2.5">sobald</span>
            <kendo-formfield orientation="horizontal" class="!k-mb-0 k-flex-basis-0">
              <kendo-textbox showErrorIcon="initial" formControlName="exceedingBudgetLimit">
              </kendo-textbox>
            </kendo-formfield>
            <span class="k-d-flex align-items-center k-ml-2.5 k-mr-2.5 k-font-size-xl">%</span>
            <span class="k-align-self-center k-mr-2.5">des Budgets aufgebraucht wurden.</span>
          </div>
        </div>
      }
    </div>
  </form>
</div>
