import { ITimeBlockComponentItem } from '../../time-block-component-items';
import { DateTimeHelper } from '../../../../util/date-time-helper';
import { TimeBlockElementSelectorService } from '../../rendering/time-block-element-selector.service';
import { CalendarService } from '../../../../services/calendar.service';
import { TimeCoordinateMappingService } from '../../../../time-mapping/time-coordinate-mapping.service';
import { TimeBlockType } from '../../../../../../shared/data-types/time-block-types';
import { DayOrWeekCalendarModel } from '../../../../../../core/models/calendar/day-or-week-calendar.model';
import { TimeBlockGeometryData } from '../../../../../../core/models/timeblock/time-block-geometry-data.model';
import { CalendarGeometryService } from '../../../../services/calendar-geometry.service';
import { MonthCalendarModel } from '../../../../../../core/models/calendar/month-calendar.model';
import { CalendarView } from '../../../../../../shared/data-types/calendar-types';
import { TimeBlockDayOrWeekFulldayType } from '../../../../../../core/models/timeblock/time-block-view-type.model';
import { TimeBlockService } from '../../services/time-block.service';
import { CalendarServiceHelper } from '../../../../services/calendar-service-helper';

export class TimeBlockGeometryCalculator {
  constructor(
    private readonly calendarService: CalendarService,
    private readonly calendarGeometryService: CalendarGeometryService,
    private readonly timeCoordinateMapperService: TimeCoordinateMappingService,
    private readonly timeBlockService: TimeBlockService,
  ) {}

  public initializeInnerdayTimeBlockGeometry(
    timeBlock: ITimeBlockComponentItem,
  ): TimeBlockGeometryData {
    const calendarView = this.calendarService.model.calendarViewMode;
    if (calendarView === CalendarView.MonthGrid) {
      return this.calculateMonthViewInnerdayTimeBlockGeometry(timeBlock);
    }
    return this.calculateWeekViewInnerdayTimeBlockGeometry(timeBlock);
  }

  public initializeFulldayTimeBlockGeometry(
    timeBlock: ITimeBlockComponentItem,
  ): TimeBlockGeometryData {
    const view = this.calendarService.model.calendarViewMode;
    const geometry = new TimeBlockGeometryData();
    Object.assign(geometry, timeBlock.timeBlockModel.timeBlockViewType.geometryData);

    const calculator =
      view === CalendarView.WeekGrid
        ? new WeekViewFulldayCalculator(
            this.calendarService,
            this.calendarGeometryService,
            this.timeBlockService,
          )
        : new MonthViewFulldayCalculator(this.calendarService, this.calendarGeometryService);
    return calculator.calculateHorizontalGeometry(timeBlock);
  }

  private calculateWeekViewInnerdayTimeBlockGeometry(
    timeBlock: ITimeBlockComponentItem,
  ): TimeBlockGeometryData {
    if (!timeBlock) {
      return;
    }
    const startOfDay = DateTimeHelper.startOfDay(timeBlock.timeBlockModel.start);

    const geometry = new TimeBlockGeometryData();
    Object.assign(geometry, timeBlock.timeBlockModel.timeBlockViewType.geometryData);
    // Calculate each block length and calculate attribute values
    this.calcVerticalGeometry(startOfDay, timeBlock, geometry);
    // Calculate left offset and width for existing and dragging time blocks
    // (so no blocks which will be removed after dropping the drag clone).
    if (timeBlock.timeBlockModel.type !== TimeBlockType.GhostBlock) {
      this.updateInnerDayTimeBlockOffsetsAndWidths(timeBlock, geometry);
    }
    return geometry;
  }

  private calculateMonthViewInnerdayTimeBlockGeometry(
    timeBlock: ITimeBlockComponentItem,
  ): TimeBlockGeometryData {
    const timeBlockHTMLWRapper = TimeBlockElementSelectorService.getTimeBlockHTMLWrapper(timeBlock);
    const timeBlockBCR = timeBlockHTMLWRapper.getBoundingClientRect();
    const calendarGeometry = this.calendarService.model.geometryData;

    const geometryData = new TimeBlockGeometryData();
    Object.assign(calendarGeometry, timeBlock.timeBlockModel.timeBlockViewType.geometryData);
    geometryData.left =
      Math.abs(timeBlockBCR.left) - this.calendarService.model.geometryData.calendarBodyOffsetLeft;
    const calendarRightEdge =
      calendarGeometry.calendarBodyOffsetLeft + calendarGeometry.calendarBodyWidth;
    geometryData.right = calendarRightEdge - Math.abs(timeBlockBCR.left);
    geometryData.height = timeBlockBCR.height;
    return geometryData;
  }

  private updateInnerDayTimeBlockOffsetsAndWidths(
    timeBlock: ITimeBlockComponentItem,
    geometryData: TimeBlockGeometryData,
  ): void {
    const timeBlockHTMLWRapper = TimeBlockElementSelectorService.getTimeBlockHTMLWrapper(timeBlock);
    const timeBlockBCR = timeBlockHTMLWRapper.getBoundingClientRect();
    const calendarGeometry = this.calendarService.model.geometryData;

    geometryData.left =
      Math.abs(timeBlockBCR.left) - this.calendarService.model.geometryData.calendarBodyOffsetLeft;
    const calendarRightEdge =
      calendarGeometry.calendarBodyOffsetLeft + calendarGeometry.calendarBodyWidth;
    geometryData.right = calendarRightEdge - Math.abs(timeBlockBCR.left);

    const calendarModel = this.calendarService.model as DayOrWeekCalendarModel;
    const visibleCalendarStart = calendarModel.calendarProperties.visibleStartDate;
    const visibleCalendarEnd = calendarModel.calendarProperties.visibleEndDate;

    let startDate;
    if (
      DateTimeHelper.isBefore(
        timeBlock.timeBlockModel.start,
        calendarModel.fulldayCalendarModel.calendarProperties.offsetStartDate,
      )
    ) {
      startDate = calendarModel.fulldayCalendarModel.calendarProperties.offsetStartDate;
    } else {
      startDate = timeBlock.timeBlockModel.start;
    }

    const timeBlockPartDate = DateTimeHelper.addDays(
      startDate,
      timeBlock.timeBlockModel.partNumber,
    );

    const prevDaysCount = DateTimeHelper.differenceInCalendarDays(
      visibleCalendarStart,
      timeBlockPartDate,
    );
    const nextDaysCount = DateTimeHelper.differenceInCalendarDays(
      timeBlockPartDate,
      visibleCalendarEnd,
    );

    if (prevDaysCount > 0) {
      // Set left offset if time block part is outside of the visible calendar view.
      geometryData.left =
        this.calendarGeometryService.getDayOrWeekInnerdayLaneWidth() * prevDaysCount * -1;
    } else if (nextDaysCount > 0) {
      // Set right offset if time block part is outside of the visible calendar view.
      geometryData.right =
        this.calendarGeometryService.getDayOrWeekInnerdayLaneWidth() * nextDaysCount;
    }
    // Set initially a width of 100%;
    geometryData.width = 100;
  }

  private calcVerticalGeometry(
    startOfDay: Date,
    timeBlock: ITimeBlockComponentItem,
    geometryData: TimeBlockGeometryData,
  ): void {
    let start = timeBlock.timeBlockModel.start;
    let end = timeBlock.timeBlockModel.end;

    if (!DateTimeHelper.isSameDay(start, end)) {
      if (timeBlock.timeBlockModel.partNumber === 0) {
        end = DateTimeHelper.endOfDay(start);
      } else if (timeBlock.timeBlockModel.partNumber === timeBlock.timeBlockModel.partCount - 1) {
        start = DateTimeHelper.startOfDay(end);
      } else {
        start = DateTimeHelper.addDays(startOfDay, timeBlock.timeBlockModel.partNumber);
        end = DateTimeHelper.endOfDay(start);
      }
    }

    const height = this.timeCoordinateMapperService.mapDurationToScreenLength(start, end);
    geometryData.height = height || 1;
    geometryData.top = this.timeCoordinateMapperService.mapDurationToScreenLength(
      startOfDay,
      start,
    );

    if (geometryData.height < 0) {
      throw new Error(`Invalid geometry data height: ${geometryData.height}`);
    }

    // Because of rounding errors it can happen that bottom becomes less than 0.
    geometryData.bottom = Math.max(
      0,
      this.calendarService.model.geometryData.calendarBodyHeight - geometryData.top - height,
    );
    geometryData.calendarTopEdgeToTimeBlockBottomEdge = geometryData.top + geometryData.height;
    geometryData.calendarBottomEdgeToTimeBlockTopEdge = geometryData.bottom + geometryData.height;

    if (
      geometryData.top < 0 ||
      geometryData.calendarTopEdgeToTimeBlockBottomEdge < 0 ||
      geometryData.calendarBottomEdgeToTimeBlockTopEdge < 0
    ) {
      throw new Error('Invalid geometry data.');
    }
  }
}

interface Calculator {
  calculateHorizontalGeometry: (timeBlock: ITimeBlockComponentItem) => TimeBlockGeometryData;
}

class WeekViewFulldayCalculator implements Calculator {
  constructor(
    private readonly calendarService: CalendarService,
    private readonly calendarGeometryService: CalendarGeometryService,
    private readonly timeBlockService: TimeBlockService,
  ) {}

  public calculateHorizontalGeometry(timeBlock: ITimeBlockComponentItem): TimeBlockGeometryData {
    const geometry = new TimeBlockGeometryData();

    const timeBlockHTMLWRapper = TimeBlockElementSelectorService.getTimeBlockHTMLWrapper(timeBlock);
    const timeBlockBCR = timeBlockHTMLWRapper.getBoundingClientRect();
    const calendarModel = this.calendarService.model as DayOrWeekCalendarModel;
    const viewType = timeBlock.timeBlockModel.timeBlockViewType as TimeBlockDayOrWeekFulldayType;
    let visibleLaneIndex = CalendarServiceHelper.toVisibleLaneIndex(
      calendarModel.fulldayCalendarModel,
      viewType.laneIndexStart,
    );

    // For the transforming block, we render the full time block (i.e. also the part outside the visible calendar view).
    // Otherwise, we set left to 0 to render only the visible portion of the time block.
    if (
      timeBlock.timeBlockModel.type === TimeBlockType.ExistingBlock &&
      visibleLaneIndex < 0 &&
      !this.timeBlockService.isTimeBlockOutsideOfView(timeBlock)
    ) {
      visibleLaneIndex = 0;
    }

    geometry.width = this.calculateFulldayWidth(timeBlock); // Derived from $gap in time-block.scss
    geometry.left = this.calendarGeometryService.getDayOrWeekFulldayLaneWidth() * visibleLaneIndex;
    geometry.calendarTopEdgeToTimeBlockBottomEdge = timeBlockBCR.height;
    geometry.height = timeBlockBCR.height;
    geometry.top = 0;
    return geometry;
  }

  private calculateFulldayWidth(timeBlock: ITimeBlockComponentItem): number {
    const cellWidth = this.calendarGeometryService.getDayOrWeekFulldayLaneWidth();
    const calendarModel = this.calendarService.model as DayOrWeekCalendarModel;

    let start = timeBlock.timeBlockModel.start;
    if (
      DateTimeHelper.isBefore(
        timeBlock.timeBlockModel.start,
        calendarModel.fulldayCalendarModel.calendarProperties.offsetStartDate,
      )
    ) {
      start = calendarModel.fulldayCalendarModel.calendarProperties.offsetStartDate;
    }

    let end = timeBlock.timeBlockModel.end;
    if (
      DateTimeHelper.isAfter(
        timeBlock.timeBlockModel.end,
        calendarModel.fulldayCalendarModel.calendarProperties.offsetEndDate,
      )
    ) {
      end = calendarModel.fulldayCalendarModel.calendarProperties.offsetEndDate;
    }

    let cellCount: number;
    // For the transforming a non-existing block, we render the full time block (i.e. also the part outside the visible calendar view).
    if (
      timeBlock.timeBlockModel.type === TimeBlockType.TransformingBlock ||
      timeBlock.timeBlockModel.type === TimeBlockType.NonExistingBlock
    ) {
      cellCount = DateTimeHelper.differenceInDays(end, start) + 1;
    } else {
      // For an existing block, we render just the time block portion within the visible calendar view.
      cellCount = this.timeBlockService.getTimeBlockVisiblePartCount(timeBlock) + 1;
    }

    // Exclude border
    const firstLane = calendarModel.fulldayCalendarModel.timeBlockHtmlContainers[0].nativeElement;
    const borderWidth = this.calendarGeometryService.getLaneRightBorderWidth(firstLane);
    return cellWidth * cellCount - borderWidth;
  }
}

class MonthViewFulldayCalculator implements Calculator {
  constructor(
    private readonly calendarService: CalendarService,
    private readonly calendarGeometryService: CalendarGeometryService,
  ) {}

  public calculateHorizontalGeometry(timeBlock: ITimeBlockComponentItem): TimeBlockGeometryData {
    const geometry = new TimeBlockGeometryData();
    const timeBlockHTMLWRapper = TimeBlockElementSelectorService.getTimeBlockHTMLWrapper(timeBlock);
    const timeBlockBCR = timeBlockHTMLWRapper.getBoundingClientRect();

    geometry.width = this.calculateWidth(timeBlock);
    geometry.height = timeBlockBCR.height;
    return geometry;
  }

  private calculateWidth(timeBlock: ITimeBlockComponentItem): number {
    const cellWidth = this.calendarGeometryService.getMonthFulldayLaneWidth();
    const calendarModel = this.calendarService.model as MonthCalendarModel;
    const timeBlockHeadDate = DateTimeHelper.isBefore(
      timeBlock.timeBlockModel.start,
      calendarModel.calendarProperties.visibleStartDate,
    )
      ? calendarModel.calendarProperties.visibleStartDate
      : timeBlock.timeBlockModel.start;
    const currentDate = DateTimeHelper.addDays(
      timeBlockHeadDate,
      timeBlock.timeBlockModel.partNumber,
    );

    const currentStartOfWeek = DateTimeHelper.startOfWeek(currentDate, {
      weekStartsOn: calendarModel.calendarProperties.weekStartsOn,
    });
    const start = DateTimeHelper.isBefore(timeBlock.timeBlockModel.start, currentStartOfWeek)
      ? currentStartOfWeek
      : timeBlock.timeBlockModel.start;

    const currentEndOfWeek = DateTimeHelper.endOfWeek(currentDate, {
      weekStartsOn: calendarModel.calendarProperties.weekStartsOn,
    });
    const end = DateTimeHelper.isAfter(timeBlock.timeBlockModel.end, currentEndOfWeek)
      ? currentEndOfWeek
      : timeBlock.timeBlockModel.end;

    const cellCount = DateTimeHelper.differenceInDays(end, start) + 1;

    // Exclude border
    const firstLane = calendarModel.fulldayCalendarModel.timeBlockHtmlContainers[0].nativeElement;
    const borderWidth = this.calendarGeometryService.getLaneRightBorderWidth(firstLane);
    return cellWidth * cellCount - borderWidth * cellCount - borderWidth; // Subtract all cells' right borders and one left border
  }
}
