import { NavigationWrapper } from '../../navigation-data';
import { ShiveRoutes } from '../../segments/route-segments';

export const Team: NavigationWrapper = {
  title: 'Team',
  route: ShiveRoutes.Team,
};

export const TeamOverview: NavigationWrapper = {
  title: 'Teamübersicht',
  route: ShiveRoutes.TeamOverview,
};

export const TeamAbsences: NavigationWrapper = {
  title: 'Abwesenheiten',
  route: ShiveRoutes.TeamAbsences,
};

export const TeamAbsencePlanner: NavigationWrapper = {
  title: 'Abwesenheitsplaner',
  route: ShiveRoutes.TeamAbsencePlanner,
};

export const TeamVacationQuota: NavigationWrapper = {
  title: 'Urlaubskontingente',
  route: ShiveRoutes.TeamVacationQuota,
};

export const TeamLeaveRequests: NavigationWrapper = {
  title: 'Abwesenheitsanträge',
  route: ShiveRoutes.TeamLeaveRequests,
};
