import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ITimeBlockComponentItem } from '../../../features/calendar/components/time-block/time-block-component-items';
import { Store } from '@ngrx/store';
import * as fromTimeBlockActions from './time-blocks.actions';
import { Actions, ofType } from '@ngrx/effects';
import { TimeBlockModifiedResponse } from '../../../shared/data-types/http-response-types';
import { TimeBlockItemBuilderService } from '../../../features/calendar/components/time-block/generation/time-block-item-builder.service';
import { UserOperation } from '../../enums/user-operation';
import { timeBlocksKey } from './time-blocks.selectors';

@Injectable()
export class TimeBlockHttpService {
  constructor(
    private readonly store: Store,
    private readonly timeBlockItemBuilderService: TimeBlockItemBuilderService,
    private readonly actions$: Actions,
  ) {}

  public fetchTimeBlocks(
    startDate: Date,
    endDate: Date,
    additionalUserId: number,
  ): Observable<readonly ITimeBlockComponentItem[]> {
    this.store.dispatch(
      fromTimeBlockActions.fetchTimeBlockList({
        payload: {
          selectedUserId: additionalUserId,
          startDate,
          endDate,
        },
      }),
    );

    return this.actions$.pipe(
      ofType(fromTimeBlockActions.setInitialTimeBlocks),
      take(1),
      map((response) => response.payload),
    );
  }

  public postBlock(
    toBeAddedTimeBlock: ITimeBlockComponentItem,
  ): Observable<TimeBlockModifiedResponse> {
    const clonedTimeBlock = this.timeBlockItemBuilderService.buildFromExisting(
      toBeAddedTimeBlock,
      toBeAddedTimeBlock.timeBlockContentType,
    );
    // Remove this property, since it is not allowed to mutate ngrx state.
    // Angular mutates componentRef through change detection while dispatching.
    delete clonedTimeBlock.timeBlockModel.componentRef;

    this.store.dispatch(
      fromTimeBlockActions.addTimeBlock({
        payload: clonedTimeBlock,
      }),
    );

    return this.actions$.pipe(
      ofType(fromTimeBlockActions.timeBlockAdded),
      map((result) => result.payload),
    );
  }

  public putBlock(
    toBeUpdatedTimeBlock: ITimeBlockComponentItem,
    storeInHistory = true,
  ): Observable<TimeBlockModifiedResponse> {
    const clonedTimeBlock = this.timeBlockItemBuilderService.buildFromExisting(
      toBeUpdatedTimeBlock,
      toBeUpdatedTimeBlock.timeBlockContentType,
    );
    // Remove componentRef property, since it is not allowed to mutate ngrx state.
    // Angular mutates componentRef through change detection while dispatching.
    delete clonedTimeBlock.timeBlockModel.componentRef;

    this.store.dispatch(
      fromTimeBlockActions.updateTimeBlock({
        payload: clonedTimeBlock,
        storeInHistory,
        undoRedoMeta: {
          userOperation: UserOperation.Update,
          undoableFn: this.putBlock.bind(this),
          undoableId: toBeUpdatedTimeBlock.id,
          undoableType: timeBlocksKey,
        },
      }),
    );

    return this.actions$.pipe(
      ofType(fromTimeBlockActions.timeBlockUpdated),
      map((result) => result.payload),
    );
  }

  public deleteBlock(timeBlock: ITimeBlockComponentItem): Observable<unknown> {
    this.store.dispatch(
      fromTimeBlockActions.deleteTimeBlock({
        payload: timeBlock.id,
      }),
    );

    return this.actions$.pipe(ofType(fromTimeBlockActions.timeBlockDeleted));
  }
}
