import { Component, OnInit } from '@angular/core';
import { NavigationWrapper } from '../../../core/routing/navigation-data';
import { take } from 'rxjs/operators';
import { pageMap } from '../../../core/routing/mapping/page-mapping';
import { PageKey } from '../../../core/routing/page-keys';
import { ShiveRoutes } from '../../../core/routing/segments/route-segments';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-detail-team-member',
  templateUrl: './detail-team-member.component.html',
})
export class DetailTeamMemberComponent implements OnInit {
  public tabs: NavigationWrapper[] = [];
  public title = '';

  constructor(private readonly activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.initTabs();
  }

  private initTabs(): void {
    this.activatedRoute.firstChild.params.pipe(take(1)).subscribe((params) => {
      const id = params.id;

      const navStatus = pageMap.get(PageKey.ProjectPageKeys.ProjectStatus);
      const navEdit = pageMap.get(PageKey.ProjectPageKeys.ProjectEdit);
      const navAttachments = pageMap.get(PageKey.ProjectPageKeys.ProjectAttachments);
      const navLogs = pageMap.get(PageKey.ProjectPageKeys.ProjectLogs);

      const statusNavItem: NavigationWrapper = {
        ...navStatus,
        route: navStatus.route.replace(ShiveRoutes.AnyId, id),
      };

      const editNavItem: NavigationWrapper = {
        ...navEdit,
        route: navEdit.route.replace(ShiveRoutes.AnyId, id),
      };

      const attachmentsNavItem: NavigationWrapper = {
        ...navAttachments,
        route: navAttachments.route.replace(ShiveRoutes.AnyId, id),
      };

      const logsNavItem: NavigationWrapper = {
        ...navLogs,
        route: navLogs.route.replace(ShiveRoutes.AnyId, id),
      };

      this.tabs = [statusNavItem, editNavItem, attachmentsNavItem, logsNavItem];
    });
  }
}
