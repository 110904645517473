import { createAction, props } from '@ngrx/store';
import { ITimeBlockComponentItem } from '../../../../features/calendar/components/time-block/time-block-component-items';
import { CalendarTimerStatus } from '../../../../shared/data-types/calendar-types';

export const postTimer = createAction(
  '[Timer] Post Timer',
  props<{ payload: CalendarTimerStatus }>(),
);
export const timerPosted = createAction(
  '[Timer] Timer Posted',
  props<{
    payload: {
      status: CalendarTimerStatus;
      generatedTimeBlock: ITimeBlockComponentItem;
    };
  }>(),
);
