import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ProjectModel } from '../../models/project/project.model';
import { ProjectsHttpService } from '../../state/projects/projects-http.service';
import { assert } from '../../assert/assert';

@Injectable({
  providedIn: 'root',
})
export class ProjectDataResolver {
  constructor(private readonly projectsHttpService: ProjectsHttpService) {}

  public resolve(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProjectModel> {
    const projectId = +state.url.split('/').pop();
    assert(!isNaN(projectId), `project id is not a number: ${projectId}`);
    return this.projectsHttpService.getSingleProject(projectId);
  }
}
