import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { DateTimeHelper } from '../../util/date-time-helper';
import { SubSink } from 'subsink';
import { BaseCalendarModel } from '../../../../core/models/calendar/base-calendar.model';
import { CalendarService } from '../../services/calendar.service';
import {
  CalendarEvents,
  CalendarView,
  DayComposite,
} from '../../../../shared/data-types/calendar-types';
import { TimeBlockContainerDirective } from '../../views/time-block-container.directive';
import { BehaviorSubject } from 'rxjs';
import { CalendarServiceHelper } from '../../services/calendar-service-helper';
import { CalendarViewHandlerService } from '../../services/calendar-view-handler.service';
import { CalendarHeaderService } from './calendar-header.service';
import * as svgIcons from '@progress/kendo-svg-icons';
import { faTreeChristmas } from '@fortawesome/pro-light-svg-icons';
import { DayOrWeekCalendarModel } from '../../../../core/models/calendar/day-or-week-calendar.model';

@Component({
  selector: 'app-shive-calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['./calendar-header.component.scss'],
})
export class CalendarHeaderComponent implements OnInit, OnDestroy {
  @ViewChild('fulldayItemContainer') fulldayItemContainer: ElementRef<HTMLTableElement>;
  @ViewChildren('timeBlockLanes', { read: ElementRef })
  public fulldayLaneHtmlItemContainers: QueryList<ElementRef>;
  @ViewChildren(TimeBlockContainerDirective)
  public fulldayLanePlaceHolders!: QueryList<TimeBlockContainerDirective>;
  @Input() fulldayLanesLoaded$: BehaviorSubject<
    [QueryList<TimeBlockContainerDirective>, QueryList<ElementRef>]
  >;
  public DateTimeHelper = DateTimeHelper;
  public fulldayComposites: DayComposite[] = []; // Days for full day calendar header (including invisible days).
  public monthViewHeaderDays: DayComposite[] = []; // Day names for month view.
  public calendarView: CalendarView;
  public CalendarView = CalendarView;
  public accumulatedTimeMap = new Map<string, number>();
  public chevronUpIcon = svgIcons.chevronUpIcon;
  public chevronDownIcon = svgIcons.chevronDownIcon;
  public readonly faTreeChristmas = faTreeChristmas;

  private readonly subs = new SubSink();

  constructor(
    public calendarHeaderService: CalendarHeaderService,
    public calendarService: CalendarService,
    private readonly calendarViewHandlerService: CalendarViewHandlerService,
    private readonly cd: ChangeDetectorRef,
  ) {
    this.calendarView = calendarService.model.calendarViewMode;
  }

  ngOnInit(): void {
    // Accumulate working times and emit event as soon as the lanes have been loaded.
    this.initCalendarHeader();
    this.initEvents();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Wait until the height toggling animation has been finished before showing the scrollbar. Looks nicer like this.
   */
  public toggleFuldayContainerHeightFinished(event): void {
    if (event.propertyName === 'height') {
      this.calendarHeaderService.finishedHeightToggling$.next(true);
    }
  }

  private initEvents(): void {
    // Update header when day changes
    this.subs.sink = this.calendarViewHandlerService.reRenderFulldayContainerDays$.subscribe(() => {
      this.fulldayComposites = this.calendarHeaderService.reRenderFulldayContainerDays();
      this.monthViewHeaderDays = this.calendarHeaderService.getMonthHeaderFulldayComposites();

      // Process change detection only if day changed (i.e. now indicator passed midnight).
      this.cd.detectChanges();
    });
  }

  private initCalendarHeader() {
    const targetEvents = [
      CalendarEvents.Start,
      CalendarEvents.SwitchedCalendarViewMode,
      CalendarEvents.ChangedStartEndDates,
      CalendarEvents.CalculatedWorkingTimeDurations,
    ];

    const callback = (
      calendarModel: BaseCalendarModel,
      calendarEvents: CalendarEvents[],
      meta,
    ): void => {
      if (calendarEvents.includes(CalendarEvents.CalculatedWorkingTimeDurations)) {
        // The accumulatedTimeMap for the month view is calculated in month-view.component.ts
        if (
          calendarModel.calendarViewMode === CalendarView.DayGrid ||
          calendarModel.calendarViewMode === CalendarView.WeekGrid
        ) {
          this.accumulatedTimeMap = meta;
          this.cd.detectChanges();
        }
        return;
      }

      if (!calendarModel.calendarProperties.visibleStartDate) {
        return;
      }

      this.calendarView = this.calendarService.model.calendarViewMode;
      if (
        this.calendarView === CalendarView.DayGrid ||
        this.calendarView === CalendarView.WeekGrid
      ) {
        this.fulldayComposites = this.calendarService.model.calendarProperties.days;
        this.cd.detectChanges();
        const model = this.calendarService.model as DayOrWeekCalendarModel;
        model.fulldayCalendarModel.geometryData.calendarBodyElementRef = this.fulldayItemContainer;
        this.fulldayLanesLoaded$.next([
          this.fulldayLanePlaceHolders,
          this.fulldayLaneHtmlItemContainers,
        ]);
      } else {
        this.monthViewHeaderDays = this.calendarHeaderService.getMonthHeaderFulldayComposites();
        this.cd.detectChanges();
      }
    };

    this.subs.sink = CalendarServiceHelper.calendarModelUpdated(
      this.calendarService,
      callback,
      targetEvents,
    );
  }
}
