import { Expose } from 'class-transformer';
import { ExcludeFromLog } from '../../../shared/typescript-decorators/exclude-from-log.decorator';

export class UserModel {
  public id: number = null;
  @Expose({ name: 'first_name' })
  public firstName = '';
  @Expose({ name: 'last_name' })
  public lastName = '';
  @ExcludeFromLog()
  public avatar = '';
  public email = '';
  public username = '';
}
