import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DateTimeHelper } from '../../util/date-time-helper';
import { SubSink } from 'subsink';
import { TimeCoordinateMappingService } from '../../time-mapping/time-coordinate-mapping.service';
import { CalendarService } from '../../services/calendar.service';
import { filter } from 'rxjs/operators';
import { CalendarCronJobsService } from '../../services/calendar-cron-jobs.service';
import { calendarCronjobsInterval } from '../../../../../assets/config/config-constants';

@Directive({
  selector: '[appNowIndicator]',
})
export class NowIndicatorDirective implements OnInit, OnDestroy {
  private errorMsg = '';
  private cronJobIndex: number;
  private readonly subs = new SubSink();

  constructor(
    private readonly renderer: Renderer2,
    private readonly el: ElementRef,
    private readonly timeCoordinateMapperService: TimeCoordinateMappingService,
    private readonly calendarService: CalendarService,
    private readonly calendarCronJobsService: CalendarCronJobsService,
  ) {}

  ngOnInit(): void {
    this.initNowIndicator();

    const updateNowIndicator = () => {
      const now = DateTimeHelper.setSecondsAndMillisToZero(new Date());
      this.updateIndicatorPos(this.renderer, this.el, now);
    };

    this.cronJobIndex = this.calendarCronJobsService.registerJob(
      updateNowIndicator,
      calendarCronjobsInterval,
    );

    updateNowIndicator();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.calendarCronJobsService.deregisterJob(this.cronJobIndex);
  }

  private updateIndicatorPos(renderer: Renderer2, el: ElementRef<HTMLElement>, now: Date): void {
    this.timeCoordinateMapperService.pixelsPerMinuteCalculated$
      .pipe(filter((ppm) => !!ppm))
      .subscribe(() => {
        const pixelOffset = this.timeCoordinateMapperService.mapDurationToScreenLength(
          DateTimeHelper.startOfToday(),
          now,
        );
        renderer.setStyle(el.nativeElement, 'top', `${pixelOffset}px`);
      });
  }

  private initNowIndicator(): void {
    this.subs.sink = this.calendarService.calendarViewInitialized$.subscribe({
      next: (initialized) => {
        if (initialized) {
          const now = DateTimeHelper.setSecondsAndMillisToZero(new Date());
          this.updateIndicatorPos(this.renderer, this.el, now);
        }
      },
      error: (err) => {
        this.errorMsg = err;
      },
    });
  }
}
