import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStatusActions from './status.actions';
import {
  NotificationType,
  ShiveNotificationService,
} from '../../../services/controls/shive-notification.service';
import { StatusModel } from '../../../models/project/status.model';
import { ProjectOrTaskType } from '../../../../shared/data-types/status-types';

@Injectable({
  providedIn: 'root',
})
export class StatusHttpService {
  constructor(
    private readonly store: Store,
    private readonly shiveNotificationService: ShiveNotificationService,
    private readonly actions$: Actions,
  ) {
    this.handleHttpCallbacks();
  }

  public getStatus(group: ProjectOrTaskType): Observable<readonly StatusModel[]> {
    this.store.dispatch(
      fromStatusActions.fetchStatus({
        payload: group,
      }),
    );

    return this.actions$.pipe(
      ofType(fromStatusActions.setStatus),
      map((result) => result.payload),
    );
  }

  public addStatus(statusModel: StatusModel | StatusModel[]): Observable<unknown> {
    this.store.dispatch(
      fromStatusActions.addProjectStatus({
        payload: statusModel,
      }),
    );

    return this.actions$.pipe(ofType(fromStatusActions.projectStatusAdded));
  }

  public updateStatus(ProjectStatusModel: StatusModel | StatusModel[]): Observable<unknown> {
    this.store.dispatch(
      fromStatusActions.updateProjectStatus({
        payload: ProjectStatusModel,
      }),
    );

    return this.actions$.pipe(ofType(fromStatusActions.projectStatusUpdated));
  }

  public deleteStatus(toBeDeletedProject: StatusModel | StatusModel[]): Observable<unknown> {
    this.store.dispatch(
      fromStatusActions.deleteProjectStatus({
        payload: toBeDeletedProject,
      }),
    );

    return this.actions$.pipe(ofType(fromStatusActions.projectStatusDeleted));
  }

  private handleHttpCallbacks(): void {
    this.actions$
      .pipe(
        ofType(
          fromStatusActions.projectStatusAdded,
          fromStatusActions.projectStatusUpdated,
          fromStatusActions.projectStatusDeleted,
        ),
      )
      .subscribe(() => {
        // const message = this.localizationService.getTranslation(payload.responseCode);
        this.shiveNotificationService.show(NotificationType.Success);
      });
  }
}
