import { Component, OnInit, inject } from '@angular/core';
import { NavigationWrapper } from '../../../core/routing/navigation-data';
import { pageMap } from '../../../core/routing/mapping/page-mapping';
import { PageKey } from '../../../core/routing/page-keys';
import { ActivatedRoute, Router } from '@angular/router';
import { ShiveRoutes } from '../../../core/routing/segments/route-segments';

@Component({
  selector: 'app-absences',
  templateUrl: './absences.component.html',
})
export class AbsencesComponent implements OnInit {
  public tabs: NavigationWrapper[] = [];
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);

  ngOnInit(): void {
    const navAbsencePlanner = pageMap.get(PageKey.TeamPageKeys.TeamAbsencePlanner);
    const navVacationInsights = pageMap.get(PageKey.TeamPageKeys.TeamVacationQuota);
    const navLeaveRequests = pageMap.get(PageKey.TeamPageKeys.TeamLeaveRequests);

    this.tabs = [navAbsencePlanner, navVacationInsights, navLeaveRequests];
    void this.router.navigate([ShiveRoutes.Planner], {
      relativeTo: this.route,
    });
  }
}
