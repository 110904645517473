import { Observable, of, toArray } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import {
  DropdownDefaultItem,
  DropdownOption,
  DropdownsWithUnassigned,
  GroupedDropdownOption,
} from '../data-types/dropdown-option';
import { EntityType } from '../data-types/entity-types';

export function observableToDropdownOptions<T>(
  obs$: Observable<readonly T[]>,
  id = 'id',
  value = 'name',
): DropdownsWithUnassigned<DropdownOption[]> {
  return obs$.pipe(
    map((options) => {
      return options.map((option) => {
        return {
          text: option[value],
          value: option[id],
        };
      });
    }),
  );
}

/**
 * Used for hard-coded string literals
 */
export function mapToDropdownOptions<T>(literalMap: Map<T, string>): Observable<DropdownOption[]> {
  return of(literalMap).pipe(
    mergeMap((data) => data),
    map(([value, text]) => {
      const option: DropdownOption = {
        text,
        value: isNaN(+value) ? value.toString() : +value,
      };
      return option;
    }),
    toArray(),
  );
}

export function mapToGroupedDropdownOptions<T>(
  literalMap: Map<T, [string, string]>,
): Observable<GroupedDropdownOption[]> {
  return of(literalMap).pipe(
    mergeMap((data) => data),
    map(([value, [text, category]]) => {
      const categorizedOption: GroupedDropdownOption = {
        text,
        value: isNaN(+value) ? value.toString() : +value,
        category,
      };
      return categorizedOption;
    }),
    toArray(),
  );
}

export function entityToDropdownOption<T extends EntityType>(entity: T): DropdownOption {
  if (!entity) {
    return null;
  }

  return {
    text: entity.name,
    value: +entity.id,
  };
}

export function dropdownOptionToEntity(option: DropdownOption): EntityType {
  if (!option) {
    return null;
  }

  return {
    id: option.value,
    name: option.text,
  };
}

export const isDropdownValue = (value: unknown) => typeof value === 'object' && 'value' in value;

function addUnassignedOption<T extends DropdownOption[]>(this: Observable<T>) {
  return this.pipe(
    tap((dropdownOptions) => {
      return dropdownOptions.unshift(DropdownDefaultItem);
    }),
  );
}

declare module 'rxjs/internal/Observable' {
  interface Observable<T> {
    addUnassignedOption: () => Observable<T>;
  }
}
Observable.prototype.addUnassignedOption = addUnassignedOption;
