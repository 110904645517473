import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { HolidayTemplateModel } from '../../../models/settings/holiday/holiday-template.model';
import {
  holidayTemplateAdded,
  holidayTemplateDeleted,
  holidayTemplateUpdated,
  setHolidayTemplate,
  setHolidayTemplates,
} from './holiday-templates.actions';

export interface HolidayTemplateState extends EntityState<HolidayTemplateModel> {}

export const holidayTemplateAdapter: EntityAdapter<HolidayTemplateModel> =
  createEntityAdapter<HolidayTemplateModel>({
    selectId: (entity: HolidayTemplateModel) => entity.id,
    sortComparer: false,
  });
const initialState: HolidayTemplateState = holidayTemplateAdapter.getInitialState();

export const holidayTemplatesReducer = createReducer(
  initialState,
  on(setHolidayTemplates, (state, { payload }): HolidayTemplateState => {
    return holidayTemplateAdapter.setMany(payload, state);
  }),
  on(setHolidayTemplate, (state, { payload }): HolidayTemplateState => {
    return holidayTemplateAdapter.setOne(payload, state);
  }),
  on(holidayTemplateAdded, (state, { payload }): HolidayTemplateState => {
    return holidayTemplateAdapter.addOne(payload.newlyPersistedTemplate, state);
  }),
  on(holidayTemplateUpdated, (state, { payload }): HolidayTemplateState => {
    return holidayTemplateAdapter.upsertOne(payload, state);
  }),
  on(holidayTemplateDeleted, (state, { payload }): HolidayTemplateState => {
    return holidayTemplateAdapter.removeOne(payload.id, state);
  }),
);
