import { Injectable } from '@angular/core';
import { MonthCalendarModel } from '../../../../../../../../core/models/calendar/month-calendar.model';
import { DateTimeHelper } from '../../../../../../util/date-time-helper';
import { CalendarMouseHandlerService } from '../../../../../../mouse/calendar-mouse-handler.service';
import { CalendarGeometryService } from '../../../../../../services/calendar-geometry.service';
import { DragAndDropHandlerService } from '../dragging/drag-and-drop/drag-and-drop-handler.service';
import { CalendarService } from '../../../../../../services/calendar.service';
import { TimeBlockDragResizeControllerService } from './time-block-drag-resize-controller.service';
import { TimeBlockCrudService } from '../../../../crud/time-block-crud.service';
import { TimeBlockService } from '../../../../services/time-block.service';
import {
  TimeBlockDayOrWeekFulldayType,
  TimeBlockMonthFulldayType,
} from '../../../../../../../../core/models/timeblock/time-block-view-type.model';
import { TimeBlockStructureService } from '../../../../time-block-structure/time-block-structure.service';
import { ITimeBlockComponentItem } from '../../../../time-block-component-items';
import { CalendarServiceHelper } from '../../../../../../services/calendar-service-helper';
import { assert } from '../../../../../../../../core/assert/assert';
import { DayCompositeConverter } from '../../../../../../converter/day-composite-converter';

@Injectable()
export class SharedHorizontalDragResizeService {
  public lastMouseLaneIndex = -1;

  constructor(
    private readonly calendarMouseHandlerService: CalendarMouseHandlerService,
    private readonly calendarGeometryService: CalendarGeometryService,
    private readonly dragAndDropHandlerService: DragAndDropHandlerService,
    private readonly calendarService: CalendarService,
    private readonly timeBlockDragResizeControllerService: TimeBlockDragResizeControllerService,
    private readonly timeBlockCrudService: TimeBlockCrudService,
    private readonly timeBlockStructureService: TimeBlockStructureService,
    private readonly timeBlockService: TimeBlockService,
  ) {}

  /**
   * Only full day time blocks of the month view are considered in this services. Full day time blocks are being dragged / resized or
   * inner day time blocks are being dragged (not resized) by removing the original time block and re-adding the updated time block.
   */
  public dragOrResizeMonthTimeBlock(): void {
    const transformationTimeBlock =
      this.timeBlockDragResizeControllerService.TransformationTimeBlock;

    // Check if time block model exists.
    if (!transformationTimeBlock?.timeBlockModel) {
      return;
    }

    const mousePositionWrapper = this.calendarMouseHandlerService.MouseMovePositionWrapper;
    const calendarMousePosX = mousePositionWrapper.mouseCalendarPosition.x;
    const calendarMousePosY = mousePositionWrapper.mouseCalendarPosition.y;
    const mouseLaneIndex = this.calendarMouseHandlerService.calcVisibleMouseLaneIndex(
      calendarMousePosX,
      calendarMousePosY,
      true,
    );

    const cellHeight = this.calendarGeometryService.getMonthFulldayLaneHeight();
    const rowIndex = Math.floor(calendarMousePosY / cellHeight);
    const weekComposites = DayCompositeConverter.toWeekComposite(
      this.calendarService.model as MonthCalendarModel,
    );
    const rowCount = weekComposites.length;

    if (mouseLaneIndex !== this.lastMouseLaneIndex && rowIndex >= 0 && rowIndex < rowCount) {
      const timeBlockModel = transformationTimeBlock.timeBlockModel;

      // If there is no time block visible during dragging, there is also nothing to remove
      if (!this.timeBlockService.isTimeBlockOutsideOfView(transformationTimeBlock)) {
        this.timeBlockCrudService.removeTimeBlock(
          transformationTimeBlock,
          transformationTimeBlock.timeBlockModel.type,
        );
      }

      if (
        this.timeBlockDragResizeControllerService.isDragAction(
          this.timeBlockDragResizeControllerService.TransformationTimeBlock.timeBlockModel.id,
          this.timeBlockDragResizeControllerService.currentDragResizeInteraction,
        )
      ) {
        const laneDiff = mouseLaneIndex - this.lastMouseLaneIndex;
        this.dragAndDropHandlerService.targetContainerId += laneDiff;
        timeBlockModel.start = DateTimeHelper.addDays(timeBlockModel.start, laneDiff);
        timeBlockModel.end = DateTimeHelper.addDays(timeBlockModel.end, laneDiff);
      } else {
        const timeBlockViewType = transformationTimeBlock.timeBlockModel
          .timeBlockViewType as TimeBlockMonthFulldayType;
        const calendarStart = this.calendarService.model.calendarProperties.offsetStartDate;

        const start = DateTimeHelper.addDays(calendarStart, timeBlockViewType.laneIndexStart);
        transformationTimeBlock.timeBlockModel.start = DateTimeHelper.mergeDateAndTime(
          start,
          this.calendarService.model.calendarProperties.workTimeStart,
        );

        const end = DateTimeHelper.addDays(
          this.calendarService.model.calendarProperties.offsetStartDate,
          timeBlockViewType.laneIndexEnd,
        );
        transformationTimeBlock.timeBlockModel.end = DateTimeHelper.mergeDateAndTime(
          end,
          this.calendarService.model.calendarProperties.workTimeEnd,
        );
      }

      if (!this.timeBlockService.isTimeBlockOutsideOfView(transformationTimeBlock)) {
        this.timeBlockCrudService.insertTimeBlock(transformationTimeBlock);

        const parts = this.timeBlockStructureService.retrieveTimeBlockPart(
          transformationTimeBlock.id,
          transformationTimeBlock.timeBlockModel.type,
          -1,
        ) as ITimeBlockComponentItem[];

        const htmlElementRefParts = parts.filter(
          (part) => part.timeBlockModel.componentRef !== null,
        );

        if (htmlElementRefParts.length === 0) {
          throw new Error('No time block parts with html ref was found.');
        }

        // Set current dragging time block part and CSS classes for all new dragging parts.
        htmlElementRefParts.forEach((timeBlockPart) => {
          this.timeBlockDragResizeControllerService.addCSSClasses(
            this.timeBlockDragResizeControllerService.DragPart,
            timeBlockPart,
          );
        });
      }
      this.lastMouseLaneIndex = mouseLaneIndex;
    }
  }

  public dragOrResizeWeekTimeBlock(): void {
    const mousePositionWrapper = this.calendarMouseHandlerService.MouseMovePositionWrapper;
    const calendarMousePosX = mousePositionWrapper.mouseCalendarPosition.x;
    let mouseLaneIndex = this.calendarMouseHandlerService.calcVisibleMouseLaneIndex(
      calendarMousePosX,
      null,
      true,
    );
    mouseLaneIndex = CalendarServiceHelper.toInvisibleLaneIndex(
      this.calendarService.model,
      mouseLaneIndex,
    );

    if (mouseLaneIndex !== this.lastMouseLaneIndex) {
      const transformationTimeBlock =
        this.timeBlockDragResizeControllerService.TransformationTimeBlock;
      const timeBlockViewType = transformationTimeBlock.timeBlockModel
        .timeBlockViewType as TimeBlockDayOrWeekFulldayType;

      const invisibleLaneIndexStart = timeBlockViewType.laneIndexStart;
      const invisibleLaneIndexEnd = timeBlockViewType.laneIndexEnd;

      const start = DateTimeHelper.addDays(
        this.calendarService.model.calendarProperties.offsetStartDate,
        invisibleLaneIndexStart,
      );
      transformationTimeBlock.timeBlockModel.start = DateTimeHelper.mergeDateAndTime(
        start,
        this.calendarService.model.calendarProperties.workTimeStart,
      );

      const end = DateTimeHelper.addDays(
        this.calendarService.model.calendarProperties.offsetStartDate,
        invisibleLaneIndexEnd,
      );
      transformationTimeBlock.timeBlockModel.end = DateTimeHelper.mergeDateAndTime(
        end,
        this.calendarService.model.calendarProperties.workTimeEnd,
      );

      this.timeBlockCrudService.removeTimeBlock(
        transformationTimeBlock,
        transformationTimeBlock.timeBlockModel.type,
      );
      this.timeBlockCrudService.insertTimeBlock(transformationTimeBlock);
      this.lastMouseLaneIndex = mouseLaneIndex;

      // Update transformation time block with newly created time block.
      const headPart = this.timeBlockStructureService.retrieveTimeBlockPart(
        transformationTimeBlock.id,
        transformationTimeBlock.timeBlockModel.type,
        0,
      ) as ITimeBlockComponentItem;

      assert(typeof headPart !== 'undefined', 'Head part of full day time block was not found.');

      this.timeBlockDragResizeControllerService.TransformationTimeBlock = headPart;
      this.timeBlockDragResizeControllerService.addCSSClasses(
        this.timeBlockDragResizeControllerService.DragPart,
        headPart,
      );
    }
  }
}
