import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SecurityContext,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserOperation } from '../../../../../../core/enums/user-operation';
import { ShiveDialogService } from '../../../../../../core/services/controls/shive-dialog.service';
import { faCirclePlus, faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { SubSink } from 'subsink';
import { UserModel } from '../../../../../../core/models/user/user.model';
import { DropdownOption } from '../../../../../../shared/data-types/dropdown-option';
import { StatusHttpService } from '../../../../../../core/state/projects/status/status-http.service';
import { ProjectOrTaskType } from '../../../../../../shared/data-types/status-types';
import { take } from 'rxjs/operators';
import {
  dropdownOptionToEntity,
  entityToDropdownOption,
} from '../../../../../../shared/functions/dropdown-functions';
import { ProjectTaskService } from '../../services/project-task.service';
import { ProjectService } from '../../../../services/project.service';
import { generateDummyId } from '../../../../../../shared/functions/array-functions';
import { ProjectTaskModel } from '../../../../../../core/models/project/project-task.model';
import { StatusModel } from '../../../../../../core/models/project/status.model';
import { assert } from '../../../../../../core/assert/assert';
import { UserService } from '../../../../../../core/services/users/user.service';

@Component({
  selector: 'app-project-task-dialog',
  templateUrl: './project-task-dialog.component.html',
})
export class ProjectTaskDialogComponent implements OnInit, OnDestroy {
  @Input() task: ProjectTaskModel;
  @Input({ required: true }) userOperation: UserOperation;
  @ViewChild('dialogFooter') dialogFooter: TemplateRef<unknown>;
  public taskForm: FormGroup;
  public users: readonly UserModel[] = [];
  public statusOptions: readonly StatusModel[] = [];
  public statusDropdownOptions: readonly DropdownOption[] = [];
  public readonly UserOperation = UserOperation;
  public readonly faCirclePlus = faCirclePlus;
  public readonly faPenToSquare = faPenToSquare;
  public readonly SecurityContext = SecurityContext;
  private readonly subs = new SubSink();

  constructor(
    private readonly shiveDialogService: ShiveDialogService,
    private readonly statusHttpService: StatusHttpService,
    private readonly formBuilder: FormBuilder,
    private readonly taskService: ProjectTaskService,
    private readonly projectService: ProjectService,
  ) {}

  ngOnInit(): void {
    // Load task dependent status.
    const unassignedUserModel = UserService.buildUnassignedUser();
    this.initForm(unassignedUserModel);
    this.loadTaskStatus();
    this.users = [unassignedUserModel, ...this.users];
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public close(): void {
    this.shiveDialogService.close(UserOperation.Cancel);
  }

  public submit(): void {
    const taskIdentifier = this.taskForm.get('taskName').value as string;
    const assignee = this.taskForm.get('assignee').value as UserModel;
    const project = this.projectService.getProjectClone();
    const statusEntity = dropdownOptionToEntity(this.taskForm.get('status').value);
    const selectedStatusModel = this.statusOptions.find((status) => status.id === statusEntity.id);

    assert(!!selectedStatusModel, 'Status model could not be found.');

    let id: number;
    // if it is a new task, create a dummy id
    if (!this.task) {
      id = generateDummyId([...project.tasks]);
    } else {
      id = this.task.id;
    }
    const upsertTask = this.taskService.createTask(
      id,
      taskIdentifier,
      project.id,
      selectedStatusModel,
      assignee,
    );
    this.shiveDialogService.close(upsertTask);
  }

  private initForm(unassignedUserModel: UserModel): void {
    const selectedUserModel = this.task?.assignee ? this.task.assignee : unassignedUserModel;

    this.taskForm = this.formBuilder.group({
      taskName: [this.task?.name, Validators.required],
      assignee: [selectedUserModel, Validators.required],
      status: [null, Validators.required],
    });
  }

  private loadTaskStatus(): void {
    const status$ = this.statusHttpService.getStatus(ProjectOrTaskType.Task).pipe(take(1));
    status$.pipe(take(1)).subscribe((statusOptions) => {
      this.statusOptions = statusOptions;
      const selectedStatus = this.task?.status.id
        ? this.statusOptions.find((option) => option.id === this.task.status.id)
        : this.statusOptions[0];
      const selectedOption = entityToDropdownOption(selectedStatus);
      this.taskForm.controls.status.setValue(selectedOption);

      this.statusDropdownOptions = statusOptions.map((option) => entityToDropdownOption(option));
    });
  }
}
