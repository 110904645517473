import { Component } from '@angular/core';
import { UserOperation } from '../../../../core/enums/user-operation';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { Subject } from 'rxjs';
import { ShiveDialogService } from '../../../../core/services/controls/shive-dialog.service';

@Component({
  selector: 'app-pending-changes-dialog',
  templateUrl: './pending-changes-dialog.component.html',
})
export class PendingChangesDialogComponent {
  public onClose$: Subject<boolean> = new Subject<boolean>();
  public UserOperation = UserOperation;
  public faCircleCheck = faCircleCheck;
  public faTriangleExclamation = faTriangleExclamation;

  constructor(private readonly shiveDialogService: ShiveDialogService) {}

  public confirm(): void {
    this.onClose$.next(true);
    this.shiveDialogService.close();
  }

  public cancel(): void {
    this.onClose$.next(false);
    this.shiveDialogService.close();
  }
}
