import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { CalendarService } from '../../../services/calendar.service';
import { MonthCalendarModel } from '../../../../../core/models/calendar/month-calendar.model';
import { combineMaps } from '../../../../../shared/functions/map-functions';
import { IFulldayViewType } from '../../../../../shared/data-types/time-block-types';
import {
  FulldayTimeBlockHeightPx,
  HorizontalTimeBlockGapPx,
  MonthViewTimeBlockContainer,
} from '../../../../../core/data-repository/css-constants';
import { TimeBlockElementSelectorService } from './time-block-element-selector.service';
import { ITimeBlockComponentItem } from '../time-block-component-items';
import { assert } from '../../../../../core/assert/assert';

@Injectable()
export class TimeBlockMonthViewContainerRendererService {
  private readonly renderer: Renderer2;

  constructor(
    private readonly calendarService: CalendarService,
    private readonly rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public collapseMonthViewOverflowingTimeBlocks(): void {
    const calendarModel = this.calendarService.model as MonthCalendarModel;
    const fulldayTimeBlockMap = calendarModel.fulldayCalendarModel.timeBlocks;
    const innerdayTimeBlockMap = calendarModel.timeBlocks;
    const mergedTimeBlocks = combineMaps(fulldayTimeBlockMap, innerdayTimeBlockMap);

    let i = -1;
    mergedTimeBlocks.forEach((timeBlocks) => {
      i++;

      let maxParallelIndex = 0;
      if (timeBlocks.length > 0) {
        const timeBlock = timeBlocks.reduce((prev, current) => {
          if (prev === null) {
            return null;
          }

          return prev &&
            (prev.timeBlockModel.timeBlockViewType as IFulldayViewType).parallelIndex >
              (current.timeBlockModel.timeBlockViewType as IFulldayViewType).parallelIndex
            ? prev
            : current;
        });
        maxParallelIndex =
          (timeBlock?.timeBlockModel.timeBlockViewType as IFulldayViewType).parallelIndex + 1;
      } else {
        return;
      }

      let container = this.calendarService.model.timeBlockHtmlContainers[i].nativeElement;
      container = container.querySelector(`.${MonthViewTimeBlockContainer}`);
      const containerHeight = container.getBoundingClientRect().height + HorizontalTimeBlockGapPx;
      const accumulatedTimeBlockHeight =
        FulldayTimeBlockHeightPx * maxParallelIndex + maxParallelIndex * HorizontalTimeBlockGapPx;

      if (containerHeight < accumulatedTimeBlockHeight) {
        const visibleTimeBlockCount = Math.floor(
          containerHeight / (FulldayTimeBlockHeightPx + HorizontalTimeBlockGapPx),
        );
        this.hideOverflowingTimeBlocks(visibleTimeBlockCount, timeBlocks);
        this.toggleListIcon(container, true);
      } else {
        this.toggleListIcon(container, false);
      }
    });
  }

  public showAllCellTimeBlocks($event): void {
    $event.stopPropagation();
  }

  private hideOverflowingTimeBlocks(
    visibleTimeBlockCount: number,
    timeBlocks: ITimeBlockComponentItem[],
  ): void {
    const hiddenTimeBlocks = timeBlocks.filter(
      (timeBlock) =>
        (timeBlock.timeBlockModel.timeBlockViewType as IFulldayViewType).parallelIndex >=
        visibleTimeBlockCount,
    );
    hiddenTimeBlocks.forEach((timeBlock) => {
      const timeBlockElementWrapper = TimeBlockElementSelectorService.getTimeBlockHTMLWrapper(
        timeBlock,
        true,
      );
      if (timeBlockElementWrapper) {
        this.renderer.setStyle(timeBlockElementWrapper, 'border', '1px solid purple');
      }
    });
  }

  private toggleListIcon(container: HTMLTableCellElement, show: boolean): void {
    const cellHeader = container.previousSibling as HTMLElement;
    assert(typeof cellHeader !== 'undefined', 'Previous sibling not found.');

    const iconBtn = cellHeader.querySelector('.more-events-button');
    assert(typeof iconBtn !== 'undefined', 'Icon button not found.');

    if (show) {
      this.renderer.removeClass(iconBtn, '!k-display-none');
    } else {
      this.renderer.addClass(iconBtn, '!k-display-none');
    }
  }
}
