import { Injectable } from '@angular/core';
import { ShiveContextMenuComponent } from '../../../shared/components/context/shive-context-menu/shive-context-menu.component';
import { ShivePopupService } from './shive-popup.service';
import { ContextMenuItemStrings } from '../../../shared/data-types/context-menu-item-type';
import { UserOperation } from '../../enums/user-operation';
import {
  faBoxArchive,
  faCircleMinus,
  faCopy,
  faPen,
  faTrash,
} from '@fortawesome/pro-light-svg-icons';

@Injectable({
  providedIn: 'root',
})
export class ShiveContextMenuService {
  private readonly contextItemMap = new Map<ContextMenuItemKeys, ContextMenuItemStrings>([
    [
      ContextMenuItemKeys.Edit,
      {
        itemClass: '',
        iconColorClass: 'turquoise',
        text: 'Bearbeiten',
        faIcon: faPen,
      },
    ],
    [
      ContextMenuItemKeys.Duplicate,
      {
        itemClass: '',
        iconColorClass: 'turquoise',
        text: 'Duplizieren',
        faIcon: faCopy,
      },
    ],
    [
      ContextMenuItemKeys.Archive,
      {
        itemClass: '',
        iconColorClass: 'turquoise',
        text: 'Archivieren',
        faIcon: faBoxArchive,
      },
    ],
    [
      ContextMenuItemKeys.Remove,
      {
        itemClass: '',
        iconColorClass: 'turquoise',
        text: 'Entfernen',
        faIcon: faCircleMinus,
      },
    ],
    [
      ContextMenuItemKeys.Delete,
      {
        itemClass: '',
        text: 'Löschen',
        iconColorClass: 'red',
        faIcon: faTrash,
      },
    ],
  ]);

  constructor(private readonly shivePopupService: ShivePopupService) {}

  public openContextMenu(
    anchor: HTMLDivElement,
    items: Map<ContextMenuItemKeys, (data: unknown) => void>,
    data: any,
    userOperation: UserOperation,
  ): void {
    if (userOperation !== UserOperation.None) {
      return;
    }

    if (this.shivePopupService.popupRef) {
      this.shivePopupService.popupRef.close();
    }

    const popupRef = this.shivePopupService.open({
      content: ShiveContextMenuComponent,
      anchor,
    });

    const contextMenuComponent = popupRef.content.instance as ShiveContextMenuComponent;
    items.forEach((callbackFn, contextMenuItemKey) => {
      const itemStrings = this.contextItemMap.get(contextMenuItemKey);
      contextMenuComponent.items.push({
        text: itemStrings.text,
        iconColorClass: itemStrings.iconColorClass,
        itemClass: itemStrings.itemClass,
        faIcon: itemStrings.faIcon,
        callback: callbackFn,
      });
    });

    contextMenuComponent.data = data;
  }
}

export enum ContextMenuItemKeys {
  Edit,
  Duplicate,
  Archive,
  Remove,
  Delete,
}
