import { Injectable } from '@angular/core';
import { DateTimeHelper } from '../util/date-time-helper';
import { CalendarService } from '../services/calendar.service';
import { CalendarEvents, CalendarView } from '../../../shared/data-types/calendar-types';

@Injectable()
export class CalendarInteractionService {
  constructor(private readonly calendarService: CalendarService) {}

  public next(): void {
    const dayFn = DateTimeHelper.addDays;
    const weekFn = DateTimeHelper.addWeeks;
    const monthFn = DateTimeHelper.addMonths;
    this.navigate(dayFn, weekFn, monthFn);
  }

  public prev(): void {
    const dayFn = DateTimeHelper.subDays;
    const weekFn = DateTimeHelper.subWeeks;
    const monthFn = DateTimeHelper.subMonths;
    this.navigate(dayFn, weekFn, monthFn);
  }

  public today(): void {
    const model = this.calendarService.model;
    const calendarEvents = [CalendarEvents.ChangedStartEndDates];
    this.calendarService.updateCalendarData(new Date(), calendarEvents, model);
  }

  public switchCalendarView(calendarView: CalendarView): void {
    const today = new Date();
    const start = this.calendarService.model.calendarProperties.visibleStartDate;
    const end = this.calendarService.model.calendarProperties.visibleEndDate;
    let date = today;
    if (!DateTimeHelper.isWithinInterval(today, { start, end })) {
      date = this.calendarService.model.calendarProperties.visibleStartDate;
    }

    const model = this.calendarService.model;
    model.calendarViewMode = calendarView;
    const calendarEvents = [CalendarEvents.SwitchedCalendarViewMode];
    // / Update the calendar model.
    this.calendarService.updateCalendarData(date, calendarEvents, model);
  }

  private navigate(dayFn: (d, n) => Date, weekFn: (d, n) => Date, monthFn: (d, n) => Date): void {
    const model = this.calendarService.model;
    let date = model.calendarProperties.visibleStartDate;
    if (model.calendarViewMode === CalendarView.DayGrid) {
      date = dayFn(date, 1);
    } else if (model.calendarViewMode === CalendarView.WeekGrid) {
      date = weekFn(date, 1);
    } else {
      // / MonthGrid
      date = new Date(model.calendarProperties.year, model.calendarProperties.month);
      date = monthFn(date, 1);
    }
    const calendarEvents = [CalendarEvents.ChangedStartEndDates];
    this.calendarService.updateCalendarData(date, calendarEvents, model);
  }
}
