import { ElementRef } from '@angular/core';
import { ITimeBlockComponentItem } from '../../../features/calendar/components/time-block/time-block-component-items';
import { TimeBlockHookContainer } from '../../../shared/data-types/time-block-types';
import {
  CalendarView,
  DayComposite,
  FulldayDuration,
  MonthOrdinal,
  WeekOrdinal,
} from '../../../shared/data-types/calendar-types';

export abstract class BaseCalendarModel {
  public calendarViewMode: CalendarView;

  // The time blocks for the current view.
  public timeBlocks: Map<string, ITimeBlockComponentItem[]> = new Map<
    string,
    ITimeBlockComponentItem[]
  >();

  // The time block container hooks as markers for insertion of new time blocks.
  public timeBlockContainers: TimeBlockHookContainer[];

  // The html representation of the individual time block containers for geometric calculations.
  public timeBlockHtmlContainers: ElementRef<HTMLTableCellElement>[];
  public calendarProperties = new BaseCalendarProperties();
  public geometryData = new CalendarGeometryData();
  public shiveTrackerActive = false;

  // The holidays of the current logged-in user
  public holidays = new Array<Date>();
}

export class BaseCalendarProperties {
  public visibleStartDate: Date;
  // The end date of the visible portion of the calendar (e.g. last day of week for week view).
  // offsetEndDate and visibleEndDate are the same for month view.
  public visibleEndDate: Date;
  // The start date of the calendar view with padded days in the past (e.g. 7 days in the past for week view).
  // offsetStartDate and visibleStartDate are the same for month view.
  public offsetStartDate: Date;
  // The end date for calendar view with padded days in the future (e.g. 7 days in the future for week view).
  // offsetEndDate and visibleEndDate are the same for month view.
  public offsetEndDate: Date;
  public month: MonthOrdinal;
  public year: number;
  public timeFormat: string;
  public minimumTimeBlockDuration: number;
  public weekStartsOn: WeekOrdinal;
  public fulldayDuration: FulldayDuration; // Todo: Implement
  // All days (visible and invisible)
  public days: DayComposite[];
  public workTimeStart: Date;
  public workTimeEnd: Date;
  public selectedUserId: number;
}

class CalendarGeometryData {
  // Distance from top edge of view port to top edge of calendar body. Can become negative if calendar body is scrolled, so scroll pos has
  // to be taken into account.// The border is excluded.
  public calendarBodyOffsetTop: number;
  // The border is excluded.
  public calendarBodyOffsetLeft: number;
  public calendarBodyWidth: number;
  // The total height including scrolling.
  public calendarBodyHeight: number;
  // Only the visible height of the calendar body (i.e. without scrolling).
  public calendarBodyVisibleHeight: number;
  public calendarBodyElementRef: ElementRef<HTMLElement>;
}
