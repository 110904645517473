import { ComponentRef } from '@angular/core';
import { TimeBlockComponent } from '../../../features/calendar/components/time-block/time-block.component';
import { Exclude, Expose, Transform } from 'class-transformer';
import { ITimeBlockViewType, TimeBlockType } from '../../../shared/data-types/time-block-types';
import { EntityType } from '../../../shared/data-types/entity-types';
import { ColorModel } from '../color/color.model';
import { DateTimeHelper } from '../../../features/calendar/util/date-time-helper';

export class TimeBlockModel {
  @Exclude({ toPlainOnly: true })
  public id = -1;

  // The "real" start date of the whole time block compound.
  @Transform(({ value }) => DateTimeHelper.dateToISO8601String(value), { toPlainOnly: true })
  @Transform(({ value }) => new Date(value), { toClassOnly: true })
  public start = new Date();

  // The "real" end date of the whole time block compound.
  @Transform(
    ({ value }) =>
      DateTimeHelper.dateToISO8601String(
        DateTimeHelper.transformToExactlyMidnightIfNeeded(value, true),
      ),
    { toPlainOnly: true },
  )
  @Transform(({ value }) => DateTimeHelper.transformToNearlyMidnightIfNeeded(new Date(value)), {
    toClassOnly: true,
  })
  public end = new Date();

  // TimeBlockDayOrWeekInnerdayType, TimeBlockDayOrWeekFulldayType, TimeBlockMonthInnerdayType, TimeBlockMonthFulldayType
  @Exclude()
  public timeBlockViewType: ITimeBlockViewType = null;

  @Exclude()
  public source = '';

  @Exclude()
  public componentRef: ComponentRef<TimeBlockComponent> = null;

  @Expose({ name: 'is_fullday' })
  public isFullday = false;

  @Expose({ name: 'is_active' })
  public isActive = false;

  @Expose({ name: 'is_working_time' })
  public isWorkingTime = true;

  @Exclude()
  @Expose({ name: 'user_id' })
  public userId: number = null;

  @Exclude()
  public type: TimeBlockType = TimeBlockType.ExistingBlock;

  // Indicates the part of a composed time block that exceeds a day or a week.
  // For month view, partNumber = 0 is the first visible part, but there could be a previous invisible time block part if the composed
  // time block starts before the current month.
  @Exclude()
  public partNumber = 0;

  // Indicates the number of parts the time block consists. Each day represents a part.
  @Exclude()
  public partCount = 1;
}

// /// Time block model for project type
export class TimeBlockProjectModel extends TimeBlockModel {
  public text = '';

  @Exclude({ toPlainOnly: true })
  @Expose({ name: 'task' })
  public task: EntityType = {
    id: -1,
    name: '',
  };

  @Exclude({ toPlainOnly: true })
  @Expose({ name: 'project' })
  public project: EntityType & { color: ColorModel } = {
    id: -1,
    name: '',
    color: null,
  };

  @Exclude({ toPlainOnly: true })
  @Expose({ name: 'client' })
  public client: EntityType = {
    id: -1,
    name: '',
  };

  // For create and update operations, just pass the id.
  @Expose({ name: 'task' })
  public getTask(): string {
    return this.task?.id.toString();
  }
}

// /// Time block model for absence type
export class TimeBlockAbsenceModel extends TimeBlockModel {
  @Exclude({ toPlainOnly: true })
  @Expose({ name: 'reason' })
  public reason: EntityType = {
    id: -1,
    name: '',
  };

  @Expose({ name: 'reason' })
  public getReason(): string {
    return this.reason.id.toString();
  }
}

// /// Time block model for appointment type
export class TimeBlockAppointmentModel extends TimeBlockModel {}
