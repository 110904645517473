import { Injectable } from '@angular/core';
import { ITimeBlockComponentItem } from '../../../time-block-component-items';
import {
  CSSPressedClass,
  CSSSmallTimeBlockClass,
} from '../../../../../../../core/data-repository/css-constants';
import { TimeBlockRenderService } from '../../../rendering/time-block-render.service';
import { TimeBlockElementSelectorService } from '../../../rendering/time-block-element-selector.service';
import { DragPart } from './drag-part';
import {
  CollisionHandlerService,
  IntersectionType,
} from '../collision-handling/collision-handler.service';
import { TimeBlockDragResizeControllerService } from './time-block-drag-resize-handling/time-block-drag-resize-controller.service';
import {
  TimeBlockDayOrWeekFulldayType,
  TimeBlockDayOrWeekInnerdayType,
} from '../../../../../../../core/models/timeblock/time-block-view-type.model';
import { ResizeTimeBlockScheduleCalculatorService } from './resizing/resize-time-block-schedule-calculator.service';
import { CalendarService } from '../../../../../services/calendar.service';
import { DateTimeHelper } from '../../../../../util/date-time-helper';
import { TimeBlockStructureService } from '../../../time-block-structure/time-block-structure.service';

@Injectable()
export class DragHandlerService {
  private readonly showAllDragEdgesBoundary = 10;

  constructor(
    private readonly timeBlockRenderService: TimeBlockRenderService,
    private readonly timeBlockStructureService: TimeBlockStructureService,
    private readonly calendarService: CalendarService,
    private readonly resizeTimeBlockScheduleCalculator: ResizeTimeBlockScheduleCalculatorService,
    private readonly timeBlockDragResizeControllerService: TimeBlockDragResizeControllerService,
  ) {}

  public configureDragHandlers(timeBlock: ITimeBlockComponentItem): void {
    if (timeBlock.timeBlockModel.timeBlockViewType instanceof TimeBlockDayOrWeekInnerdayType) {
      this.configureVerticalDragHandlers(timeBlock);
    }
  }

  public configureVerticalDragHandlers(timeBlock: ITimeBlockComponentItem): void {
    const geometryData = timeBlock.timeBlockModel.timeBlockViewType.geometryData;
    if (
      timeBlock.timeBlockModel.timeBlockViewType instanceof TimeBlockDayOrWeekFulldayType &&
      geometryData.height < 0
    ) {
      throw new Error('Time block model height is invalid.');
    }

    if (geometryData.height <= this.showAllDragEdgesBoundary) {
      if (!timeBlock.timeBlockModel.componentRef) {
        throw new Error('No time block component ref found.');
      }
      // Hide bottom drag edge if time block is very small.
      this.timeBlockRenderService.addClass(
        timeBlock.timeBlockModel.componentRef.instance.timeBlockHTMLWrapper.nativeElement,
        CSSSmallTimeBlockClass,
      );
    }
  }

  /**
   * Flip top and bottom geometry data. The time attributes will be updated in the updateTimeBlockSchedule() function later.
   */
  public flipVerticalDragEdgeSelf(minimumTimeBlockHeight: number): void {
    const geometryData =
      this.timeBlockDragResizeControllerService.TransformationTimeBlock.timeBlockModel
        .timeBlockViewType.geometryData;
    let dragPart = this.timeBlockDragResizeControllerService.DragPart;

    if (dragPart === DragPart.Top) {
      const top = geometryData.calendarTopEdgeToTimeBlockBottomEdge - minimumTimeBlockHeight;
      geometryData.top = top > 0 ? top : 0;
      geometryData.calendarBottomEdgeToTimeBlockTopEdge =
        geometryData.bottom + minimumTimeBlockHeight;
      this.resizeTimeBlockScheduleCalculator.calculateTimeBlockStart();
      dragPart = DragPart.Bottom;
    } else {
      const bottom = geometryData.calendarBottomEdgeToTimeBlockTopEdge - minimumTimeBlockHeight;
      geometryData.bottom = bottom > 0 ? bottom : 0;
      geometryData.calendarTopEdgeToTimeBlockBottomEdge = geometryData.top + minimumTimeBlockHeight;
      this.resizeTimeBlockScheduleCalculator.calculateTimeBlockEnd();
      dragPart = DragPart.Top;
    }

    if (geometryData.top < 0 || geometryData.bottom < 0) {
      throw new Error('Invalid top or bottom value.');
    }

    geometryData.height = minimumTimeBlockHeight;
    CollisionHandlerService.collisionType = IntersectionType.intersectNone;
    this.timeBlockDragResizeControllerService.DragPart = dragPart;
    this.setVerticalDragEdgeSwitchStyling();
  }

  /**
   * Flip start and end dated for the dragging time block if a user switches the lane to part 0 of the composed time block.
   * Afterwards, a new time block with these datetime values will be created. Furthermore, the correct CSS classes will be applied.
   */
  public flipAfterVerticalDragEdgeLaneSwitch(timeBlock: ITimeBlockComponentItem): void {
    const start = timeBlock.timeBlockModel.start;
    const end = timeBlock.timeBlockModel.end;
    const minimalBlockDuration =
      this.calendarService.model.calendarProperties.minimumTimeBlockDuration;
    let dragPart = this.timeBlockDragResizeControllerService.DragPart;

    if (dragPart === DragPart.Top) {
      // If a time block with one right neighbour is resized and the lane is switched so that only the one neighbour is left and is
      // subsequently resized, we need ot check if this neighbour's end lasted until the end of day.
      // If it lasted until the end of day (bottom === 0), the end time has to be set to end of day since the start time would be wrong if
      // the original time block was very small.
      const partZero = this.timeBlockStructureService.retrieveTimeBlockPart(
        timeBlock.id,
        timeBlock.timeBlockModel.type,
        1,
      ) as ITimeBlockComponentItem;

      if (!partZero) {
        throw new Error('Time Block part is not defined.');
      }

      const geometryData = partZero.timeBlockModel.timeBlockViewType.geometryData;
      // If the new start date of the last residing time block is before midnight because the original part zero was very small
      // (< minBlockDuration), set the start time to 00:00:00
      const startOfPartZero = DateTimeHelper.subMinutes(end, minimalBlockDuration);
      timeBlock.timeBlockModel.start =
        DateTimeHelper.differenceInCalendarDays(startOfPartZero, end) !== 0
          ? DateTimeHelper.startOfDay(end)
          : startOfPartZero;
      timeBlock.timeBlockModel.end =
        geometryData.bottom === 0 ? DateTimeHelper.endOfDay(start) : start;
      dragPart = DragPart.Bottom;
    } else {
      timeBlock.timeBlockModel.start = end;
      const endOfPartZero = DateTimeHelper.addMinutes(start, minimalBlockDuration);
      timeBlock.timeBlockModel.end =
        DateTimeHelper.differenceInCalendarDays(endOfPartZero, start) !== 0
          ? DateTimeHelper.endOfDay(start)
          : endOfPartZero;
      dragPart = DragPart.Top;
    }

    this.timeBlockDragResizeControllerService.DragPart = dragPart;
  }

  /**
   * Add the correct CSS classes when switching the drag edges.
   */
  public setVerticalDragEdgeSwitchStyling(): void {
    const dragPart = this.timeBlockDragResizeControllerService.DragPart;
    const firstDragHandle = TimeBlockElementSelectorService.getTimeBlockFirstHandle(
      this.timeBlockDragResizeControllerService.TransformationTimeBlock,
    );
    const secondDragHandle = TimeBlockElementSelectorService.getTimeBlockSecondHandle(
      this.timeBlockDragResizeControllerService.TransformationTimeBlock,
    );

    this.timeBlockRenderService.removeClass(firstDragHandle, CSSPressedClass);
    this.timeBlockRenderService.removeClass(secondDragHandle, CSSPressedClass);

    if (dragPart === DragPart.Top) {
      this.timeBlockRenderService.addClass(firstDragHandle, CSSPressedClass);
    } else {
      this.timeBlockRenderService.addClass(secondDragHandle, CSSPressedClass);
    }
  }

  public handleHorizontalDragEdgeSwitchStyle(dragPart: DragPart): void {
    const firstDragHandle = TimeBlockElementSelectorService.getTimeBlockFirstHandle(
      this.timeBlockDragResizeControllerService.TransformationTimeBlock,
    );
    const secondDragHandle = TimeBlockElementSelectorService.getTimeBlockSecondHandle(
      this.timeBlockDragResizeControllerService.TransformationTimeBlock,
    );

    if (dragPart === DragPart.Right) {
      this.timeBlockRenderService.removeClass(firstDragHandle, CSSPressedClass);
      this.timeBlockRenderService.addClass(secondDragHandle, CSSPressedClass);
    } else if (dragPart === DragPart.Left) {
      this.timeBlockRenderService.removeClass(secondDragHandle, CSSPressedClass);
      this.timeBlockRenderService.addClass(firstDragHandle, CSSPressedClass);
    }
  }
}
