import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { UserModel } from '../../../core/models/user/user.model';
import { cloneDeep } from 'lodash-es';

export interface UserState {
  user: UserModel;
}

@Injectable()
export class UserDetailDataStore extends ComponentStore<UserState> {
  public readonly addUser = this.updater((state: UserState, UserModel: UserModel) => ({
    user: UserModel,
  }));

  constructor() {
    super({ user: null });
  }

  public readonly selectUser = () => this.select((state) => state.user);
  public readonly updateUser = (updatedUser: UserModel) => {
    this.patchState((state) => {
      const updatedState = cloneDeep(state);
      updatedState.user = updatedUser;
      return updatedState;
    });
  };

  public readonly reset = () => {
    this.patchState(() => {
      return { user: null };
    });
  };
}
