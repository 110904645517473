import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StatusModel } from '../../models/project/status.model';
import { StatusHttpService } from '../../state/projects/status/status-http.service';
import { ProjectOrTaskType } from '../../../shared/data-types/status-types';

@Injectable({
  providedIn: 'root',
})
export class ProjectStatusResolver {
  constructor(private readonly statusHttpService: StatusHttpService) {}

  public resolve(): Observable<readonly StatusModel[]> {
    return this.statusHttpService.getStatus(ProjectOrTaskType.Project);
  }
}
