import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as fromAbsenceReasonsActions from './absence-reasons.actions';
import { Actions, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import {
  NotificationType,
  ShiveNotificationService,
} from '../../../services/controls/shive-notification.service';
import { Store } from '@ngrx/store';
import { AbsenceReasonModel } from '../../../models/absence-reason/absence-reason.model';

@Injectable({
  providedIn: 'root',
})
export class AbsenceReasonsHttpService {
  constructor(
    private readonly store: Store,
    private readonly shiveNotificationService: ShiveNotificationService,
    private readonly actions$: Actions,
  ) {
    this.handleHttpCallbacks();
  }

  public getAbsenceReasons(): Observable<readonly AbsenceReasonModel[]> {
    this.store.dispatch(fromAbsenceReasonsActions.fetchAbsenceReasons());

    return this.actions$.pipe(
      ofType(fromAbsenceReasonsActions.setAbsenceReasons),
      map((result) => result.payload),
    );
  }

  public addAbsenceReason(absenceReason: AbsenceReasonModel): Observable<unknown> {
    this.store.dispatch(
      fromAbsenceReasonsActions.addAbsenceReason({
        payload: absenceReason,
      }),
    );

    return this.actions$.pipe(ofType(fromAbsenceReasonsActions.absenceReasonAdded));
  }

  public updateAbsenceReason(absenceReason: AbsenceReasonModel): Observable<unknown> {
    this.store.dispatch(
      fromAbsenceReasonsActions.updateAbsenceReason({
        payload: absenceReason,
      }),
    );

    return this.actions$.pipe(ofType(fromAbsenceReasonsActions.absenceReasonUpdated));
  }

  public deleteAbsenceReason(absenceReason: AbsenceReasonModel): Observable<unknown> {
    this.store.dispatch(
      fromAbsenceReasonsActions.deleteAbsenceReason({
        payload: absenceReason,
      }),
    );

    return this.actions$.pipe(ofType(fromAbsenceReasonsActions.absenceReasonDeleted));
  }

  private handleHttpCallbacks(): void {
    this.actions$
      .pipe(
        ofType(
          fromAbsenceReasonsActions.absenceReasonAdded,
          fromAbsenceReasonsActions.absenceReasonUpdated,
          fromAbsenceReasonsActions.absenceReasonDeleted,
        ),
      )
      .subscribe(() => {
        // const message = this.localizationService.getTranslation(payload.responseCode);
        this.shiveNotificationService.show(NotificationType.Success);
      });
  }
}
