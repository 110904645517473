import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShiveDialogService } from '../../../../../../core/services/controls/shive-dialog.service';
import { UserOperation } from '../../../../../../core/enums/user-operation';
import { faCirclePlus, faPenToSquare } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-holiday-template-dialog',
  templateUrl: './holiday-template-dialog.component.html',
})
export class HolidayTemplateDialogComponent implements OnInit {
  @ViewChild('dialogFooter') dialogFooter: TemplateRef<unknown>;
  @Input() holidayTemplateName: string;
  @Input() userOperation: UserOperation;
  public UserOperation = UserOperation;
  public holidayTemplateForm: FormGroup;
  public readonly faCirclePlus = faCirclePlus;
  public readonly faPenToSquare = faPenToSquare;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly shiveDialogService: ShiveDialogService,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  public submit(): void {
    if (this.holidayTemplateForm.valid) {
      const holidayTemplate = this.holidayTemplateForm.get('holidayTemplateName').value;
      this.shiveDialogService.close(holidayTemplate);
    }
  }

  public close(): void {
    this.shiveDialogService.close(UserOperation.Cancel);
  }

  private initForm(): void {
    this.holidayTemplateForm = this.formBuilder.group({
      holidayTemplateName: [this.holidayTemplateName, Validators.required],
    });
  }
}
