import { CardinalPoints, DragPart } from '../drag-and-resize/drag-part';
import { TimeBlockModel } from '../../../../../../../core/models/timeblock/time-block.model';
import { ITimeBlockComponentItem } from '../../../time-block-component-items';
import { Injectable } from '@angular/core';
import { ResizeData } from '../drag-and-resize/resizing/resize-view-controller/vertical-day-or-week-resize-controller.service';
import { TimeBlockType } from '../../../../../../../shared/data-types/time-block-types';
import { TimeBlockGeometryData } from '../../../../../../../core/models/timeblock/time-block-geometry-data.model';
import { DragData } from '../drag-and-resize/dragging/drag-view-controller/freely-day-or-week-drag-controller.service';

/**
 * This collision handler applies for both resizing and dragging of a time block.
 * We implement collision detection by ourself and don't use the "restrict" property of ineract.js,
 * since restriction doesn't work for scrolling.
 */
@Injectable()
export class CollisionHandlerService {
  public static collisionType: IntersectionType;
  private minBlockHeight = 0;
  private calendarBodyWidth: number;
  private calendarBodyHeight: number;

  constructor() {
    CollisionHandlerService.collisionType = IntersectionType.intersectNone;
  }

  public set CalendarBodyWidth(calendarBodyWidth: number) {
    this.calendarBodyWidth = calendarBodyWidth;
  }

  public set CalendarBodyHeight(calendarBodyHeight: number) {
    this.calendarBodyHeight = calendarBodyHeight;
  }

  public set MinimumBlockHeight(minimumBlockHeight: number) {
    this.minBlockHeight = minimumBlockHeight;
  }

  /**
   * Calculate correct height for the block if it gets too small or hits a calendar edge respectively, during resizing.
   */
  public checkVerticalIntersections(
    timeBlock: ITimeBlockComponentItem,
    translationYData: ResizeData | DragData,
  ): void {
    if (this.minBlockHeight <= 0) {
      throw new Error('Minimum grid height must be greater than 0');
    }

    const posY = translationYData.dragEdgePos.y;
    const dragPart =
      translationYData instanceof ResizeData ? translationYData.dragPart : DragPart.Body;
    const geometryData = timeBlock.timeBlockModel.timeBlockViewType.geometryData;

    this.checkSelfHit(geometryData, dragPart, posY);
    this.checkCalendarTopEdgeCollision(posY);
    this.checkCalendarBottomEdgeCollision(posY);

    if (CollisionHandlerService.collisionType !== IntersectionType.intersectNone) {
      return;
    }

    CollisionHandlerService.collisionType = IntersectionType.intersectNone;
  }

  public checkDragEdgeSwitch(resizeData: ResizeData, timeBlockModel: TimeBlockModel): void {
    // Only make a switch of drag edges for non-existing blocks.
    if (
      timeBlockModel.type !== TimeBlockType.NonExistingBlock &&
      timeBlockModel.type !== TimeBlockType.TransformingBlock
    ) {
      return;
    }

    const geometryData = timeBlockModel.timeBlockViewType.geometryData;

    // If drag part is top and either the mouse cursor is south of the calendar bottom edge or a bottom edge collision has been detected.
    if (
      resizeData.dragPart === DragPart.Top &&
      (resizeData.mousePos.y > geometryData.calendarTopEdgeToTimeBlockBottomEdge ||
        CollisionHandlerService.collisionType === IntersectionType.intersectCalendarBottomEdge) &&
      resizeData.direction.vertical === CardinalPoints.South
    ) {
      CollisionHandlerService.collisionType = IntersectionType.intersectWithDragBottomEdge;
    } else if (
      // If drag part is bottom and either the mouse cursor is north of the calendar top edge or a top edge collision has been detected.
      resizeData.dragPart === DragPart.Bottom &&
      (resizeData.mousePos.y < timeBlockModel.timeBlockViewType.geometryData.top ||
        CollisionHandlerService.collisionType === IntersectionType.intersectCalendarTopEdge) &&
      resizeData.direction.vertical === CardinalPoints.North
    ) {
      CollisionHandlerService.collisionType = IntersectionType.intersectWithDragTopEdge;
    }
  }

  private checkSelfHit(
    geometryData: TimeBlockGeometryData,
    dragPart: DragPart,
    posY: number,
  ): void {
    if (!dragPart) {
      return;
    }

    if (
      dragPart === DragPart.Top &&
      posY > geometryData.calendarTopEdgeToTimeBlockBottomEdge - this.minBlockHeight
    ) {
      CollisionHandlerService.collisionType = IntersectionType.intersectSelf;
      return;
    } else if (dragPart === DragPart.Bottom && posY < geometryData.top + this.minBlockHeight) {
      CollisionHandlerService.collisionType = IntersectionType.intersectSelf;
      return;
    }

    CollisionHandlerService.collisionType = IntersectionType.intersectNone;
  }

  private checkCalendarTopEdgeCollision(posY: number): void {
    if (posY <= 0) {
      CollisionHandlerService.collisionType = IntersectionType.intersectCalendarTopEdge;
    }
  }

  private checkCalendarBottomEdgeCollision(posY: number): void {
    if (posY >= this.calendarBodyHeight) {
      CollisionHandlerService.collisionType = IntersectionType.intersectCalendarBottomEdge;
    }
  }
}

export enum IntersectionType {
  intersectCalendarTopEdge,
  intersectCalendarBottomEdge,
  intersectCalendarLeftEdge,
  intersectCalendarRightEdge,
  intersectSelf,
  intersectWithDragTopEdge,
  intersectWithDragBottomEdge,
  intersectNone,
}
