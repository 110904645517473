import { Injectable } from '@angular/core';
import { CalendarService } from '../../../services/calendar.service';
import { ITimeBlockComponentItem } from '../time-block-component-items';
import { TimeBlockComponentHandlerService } from '../generation/time-block-component-handler.service';
import { TimeBlockItemBuilderService } from '../generation/time-block-item-builder.service';
import { TimeBlockType } from '../../../../../shared/data-types/time-block-types';
import { DayOrWeekCalendarModel } from '../../../../../core/models/calendar/day-or-week-calendar.model';
import {
  TimeBlockDayOrWeekFulldayType,
  TimeBlockDayOrWeekInnerdayType,
  TimeBlockMonthFulldayType,
  TimeBlockMonthInnerdayType,
} from '../../../../../core/models/timeblock/time-block-view-type.model';
import { TimeBlockModel } from '../../../../../core/models/timeblock/time-block.model';
import { TimeBlockMappingService } from './time-block-mapping.service';
import { DateTimeHelper } from '../../../util/date-time-helper';
import { DataStructureHelper } from '../../../../../shared/data-structures/data-structure-helper';
import { MonthCalendarModel } from '../../../../../core/models/calendar/month-calendar.model';
import { CalendarView } from '../../../../../shared/data-types/calendar-types';
import { Interval } from 'date-fns';

// Service that operates directly on the calendar model time block data structure. Does NOT process any http requests.
@Injectable()
export class TimeBlockStructureService {
  constructor(
    private readonly calendarService: CalendarService,
    private readonly timeBlockMappingService: TimeBlockMappingService,
    private readonly timeBlockComponentHandlerService: TimeBlockComponentHandlerService,
    private readonly timeBlockItemBuilderService: TimeBlockItemBuilderService,
  ) {}

  /**
   * Don't call this method directly. Use the insertIntoCalendar() method of the time block instead.
   */
  public insert(toBeAddedBlock: ITimeBlockComponentItem): void {
    const clone = toBeAddedBlock.clone(this.timeBlockItemBuilderService);
    const timeBlocksInContainer = this.retrieveTimeBlocksInContainer(clone);
    timeBlocksInContainer.push(clone);
  }

  /**
   * Don't call this method directly. Use the updateInCalendar() method of the time block instead.
   */
  public replace(toBeUpdatedBlock: ITimeBlockComponentItem): void {
    const timeBlocksInContainer = this.retrieveTimeBlocksInContainer(toBeUpdatedBlock);
    if (timeBlocksInContainer.length === 0) {
      throw new Error('No time blocks in this container.');
    }

    const index = timeBlocksInContainer.findIndex(
      (block) =>
        block.timeBlockModel.id === toBeUpdatedBlock.timeBlockModel.id &&
        block.timeBlockModel.type === toBeUpdatedBlock.timeBlockModel.type,
    );

    if (index < 0) {
      throw new Error('Time block found.');
    }

    timeBlocksInContainer.splice(index, 1, toBeUpdatedBlock);

    const containerIndex = this.calendarService.getContainerIndexByTimeBlock(toBeUpdatedBlock);
    this.replaceTimeBlockContainer(
      containerIndex,
      timeBlocksInContainer,
      toBeUpdatedBlock.timeBlockModel,
    );
  }

  /**
   * Don't call this method directly. Use the removeFromCalendar() method of the time block instead.
   */
  public remove(toBeRemovedBlock: ITimeBlockComponentItem): void {
    const timeBlocksInContainer = this.retrieveTimeBlocksInContainer(toBeRemovedBlock);
    if (timeBlocksInContainer.length === 0) {
      throw new Error('No time blocks in this container.');
    }

    const index = timeBlocksInContainer.findIndex(
      (block) =>
        block.timeBlockModel.id === toBeRemovedBlock.timeBlockModel.id &&
        block.timeBlockModel.type === toBeRemovedBlock.timeBlockModel.type,
    );

    if (index < 0) {
      throw new Error('Time block found.');
    }

    const containerIndex = this.calendarService.getContainerIndexByTimeBlock(toBeRemovedBlock);
    this.eliminateTimeBlock(toBeRemovedBlock, timeBlocksInContainer);
    this.replaceTimeBlockContainer(
      containerIndex,
      timeBlocksInContainer,
      toBeRemovedBlock.timeBlockModel,
    );
  }

  /**
   * Retrieve all time blocks for a time block container that is specified via a passed time block component item.
   * @param timeBlockComponentItem Get the container that this time block is in and return all time blocks of this container.
   * @param filterCond An optional filter condition.
   */
  public retrieveTimeBlocksInContainer(
    timeBlockComponentItem: ITimeBlockComponentItem,
    filterCond?: (curBlock: ITimeBlockComponentItem) => boolean,
  ): ITimeBlockComponentItem[] {
    // Either full day blocks of day, week and month view or calendar inner day blocks of week view
    const containerBlocks = this.getTimeBlockArray(timeBlockComponentItem);
    const containerIndex =
      this.calendarService.getContainerIndexByTimeBlock(timeBlockComponentItem);
    let selectedTimeBlocks = containerBlocks[containerIndex] ?? [];
    if (filterCond) {
      selectedTimeBlocks = selectedTimeBlocks.filter(filterCond);
    }
    return selectedTimeBlocks;
  }

  /**
   * Retrieve all parts or a specific part of a compound time block.
   * @param id The time block part with the id that should be retrieved.
   * @param timeBlockType Search for a time block type (e.g. Ghost).
   * @param partNumber If partNumber is negative, return all parts of the time block.
   * @return A part copy of the compound time block or the whole time block composite copies with all parts.
   * Default: One copied part is returned.
   */
  public retrieveTimeBlockPart(
    id: number | null,
    timeBlockType = TimeBlockType.ExistingBlock,
    partNumber = 0,
  ): ITimeBlockComponentItem | ITimeBlockComponentItem[] | null {
    if (timeBlockType === TimeBlockType.ExistingBlock) {
      if (id === null) {
        throw new Error('Invalid id for an existing block.');
      }

      if (id < 0) {
        return null;
      }
    }

    const allTimeBlocks = this.getTimeBlockArray(id);
    const filterCond = {
      id: (timeBlock: ITimeBlockComponentItem) =>
        id !== null ? timeBlock.timeBlockModel.id === id : true,
      type: (timeBlock: ITimeBlockComponentItem) =>
        !isNaN(timeBlockType) ? timeBlock.timeBlockModel.type === timeBlockType : true,
      part: (timeBlock: ITimeBlockComponentItem) =>
        partNumber >= 0 ? timeBlock.timeBlockModel.partNumber === partNumber : true,
    };

    const result = this.filterEveryLane(allTimeBlocks, filterCond, id, partNumber);

    if (!result) {
      return null;
    }

    // If part number is greater or equal zero, return the fetched part. Return all parts of the time block otherwise.
    return partNumber >= 0 ? result[0] : result;
  }

  /**
   * Get time block parts for a specific day.
   */
  public retrieveTimeBlockPartsForDate(
    day: Date,
    timeBlockDays: Map<string, ITimeBlockComponentItem[]>,
  ): ITimeBlockComponentItem[] {
    const allBlocks = DataStructureHelper.toArray(timeBlockDays);
    const filterCond = {
      date: (timeBlock: ITimeBlockComponentItem) => {
        const dateOfPart = DateTimeHelper.addDays(
          timeBlock.timeBlockModel.start,
          timeBlock.timeBlockModel.partNumber,
        );
        return DateTimeHelper.isSameDay(dateOfPart, day);
      },
    };

    return this.filterEveryLane(allBlocks, filterCond);
  }

  /**
   * Filter for all full day head time block parts. These parts include the HTML representation of the time block.
   * @param timeBlockMap The to be filtered time block map.
   * @param onlyVisible If set to true, return only time blocks that are visible in the calendar view (i.e. at least one part is visible).
   */
  public getFulldayHeadParts(
    timeBlockMap: Map<string, ITimeBlockComponentItem[]>,
    onlyVisible = true,
  ): Map<string, ITimeBlockComponentItem[]> {
    const fulldayHeadTimeBlockParts = new Map<string, ITimeBlockComponentItem[]>();
    const filterFn = (timeBlock: ITimeBlockComponentItem) => {
      const isApplicable = timeBlock.timeBlockModel.componentRef !== null;
      if (isApplicable && onlyVisible) {
        const calendarModel = this.calendarService.getCalendarModelByTimeBlockType(timeBlock);
        const visibleCalendarRange: Interval = {
          start: calendarModel.calendarProperties.visibleStartDate,
          end: calendarModel.calendarProperties.visibleEndDate,
        };

        const timeBlockRange: Interval = {
          start: timeBlock.timeBlockModel.start,
          end: timeBlock.timeBlockModel.end,
        };
        return (
          DateTimeHelper.areIntervalsOverlapping(visibleCalendarRange, timeBlockRange, {
            inclusive: true,
          }) && isApplicable
        );
      }

      return isApplicable;
    };

    for (const [date, timeBlocks] of timeBlockMap) {
      const visibleTimeBlocks = timeBlocks.filter((timeBlock) => filterFn(timeBlock));
      fulldayHeadTimeBlockParts.set(date, visibleTimeBlocks);
    }
    return fulldayHeadTimeBlockParts;
  }

  /**
   * Retrieve the time block map directly from the calendar services. Parts are included.
   * @param fullday If the time block is a full day or inner day time block.
   * @param range Optional range to filter the map.
   *
   * Attention: Although a new map is created, the time blocks themselves are passed via reference!
   */
  public getTimeBlockMap(
    fullday: boolean,
    range?: Interval,
  ): Map<string, ITimeBlockComponentItem[]> {
    const calendarModel = this.calendarService.model;
    const viewMode = calendarModel.calendarViewMode;
    let map: Map<string, ITimeBlockComponentItem[]>;

    if (!fullday) {
      map = calendarModel.timeBlocks;
    } else {
      map =
        viewMode === CalendarView.DayGrid || viewMode === CalendarView.WeekGrid
          ? (calendarModel as DayOrWeekCalendarModel).fulldayCalendarModel.timeBlocks
          : (calendarModel as MonthCalendarModel).fulldayCalendarModel.timeBlocks;
    }

    if (range) {
      map = new Map(
        [...map].filter(([day]) => {
          const date = DateTimeHelper.startOfDay(new Date(day));
          return DateTimeHelper.isWithinInterval(date, range);
        }),
      );
    }

    return map;
  }

  /**
   * Retrieve the time blocks as array copies directly from the calendar services. Parts are included.
   */
  public getTimeBlockArray(
    timeBlock: ITimeBlockComponentItem | number,
  ): ITimeBlockComponentItem[][] {
    if (!timeBlock || typeof timeBlock === 'number') {
      // Return all time blocks
      return [
        ...this.timeBlockMappingService.timeBlockFulldayBlockMapToArray(),
        ...this.timeBlockMappingService.timeBlockInnerdayMapToArray(),
      ];
    }

    const timeBlockViewType = timeBlock.timeBlockModel.timeBlockViewType;
    if (
      timeBlockViewType instanceof TimeBlockDayOrWeekFulldayType ||
      timeBlockViewType instanceof TimeBlockMonthFulldayType
    ) {
      return this.timeBlockMappingService.timeBlockFulldayBlockMapToArray();
    } else if (
      timeBlockViewType instanceof TimeBlockDayOrWeekInnerdayType ||
      timeBlockViewType instanceof TimeBlockMonthInnerdayType
    ) {
      // Return calendar body time blocks
      return this.timeBlockMappingService.timeBlockInnerdayMapToArray();
    }
    throw new Error('Time block type not supported.');
  }

  private eliminateTimeBlock(
    block: ITimeBlockComponentItem,
    timeBlockLane: ITimeBlockComponentItem[],
  ): void {
    this.timeBlockComponentHandlerService.destroyComponent(block);
    const laneIndex = timeBlockLane.findIndex(
      (b) =>
        b.timeBlockModel.id === block.timeBlockModel.id &&
        b.timeBlockModel.type === block.timeBlockModel.type,
    );
    if (laneIndex < 0) {
      throw new Error('Time block not found in lane.');
    }
    timeBlockLane.splice(laneIndex, 1);
  }

  private replaceTimeBlockContainer(
    containerIndex: number,
    toBeReplacedTimeBlocks: ITimeBlockComponentItem[],
    tbModel: TimeBlockModel,
  ): void {
    const model = this.calendarService.model as DayOrWeekCalendarModel;
    const timeBlocks =
      tbModel.timeBlockViewType instanceof TimeBlockDayOrWeekFulldayType ||
      tbModel.timeBlockViewType instanceof TimeBlockMonthFulldayType
        ? model.fulldayCalendarModel.timeBlocks
        : model.timeBlocks;

    const key = Array.from(timeBlocks.keys())[containerIndex];
    timeBlocks.set(key, toBeReplacedTimeBlocks);
  }

  private filterEveryLane(
    allTimeBlocks: ITimeBlockComponentItem[][],
    filterCond: { [key: string]: (tb: ITimeBlockComponentItem) => boolean },
    id = -1,
    partNumber = -1,
  ): ITimeBlockComponentItem[] {
    const result: ITimeBlockComponentItem[] = [];

    allTimeBlocks.every((lane) => {
      const timeBlock = lane.filter(
        (tb) => filterCond.id(tb) && filterCond.type(tb) && filterCond.part(tb),
      );
      if (timeBlock.length > 1) {
        throw new Error(`There are too many time blocks with id  ${timeBlock[0].id} in this lane.`);
      }

      if (timeBlock.length === 1) {
        result.push(timeBlock.pop());
      }
      return true; // Continue the loop
    });

    if (result.length === 0) {
      return null;
    } else if (result.length > 1 && partNumber >= 0) {
      throw new Error(
        `Multiple parts for block with id ${id} and part number ${partNumber} found.`,
      );
    }

    return result;
  }
}
