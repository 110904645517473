<!-- ATTENTION: Add the css class 'tb' to every DOM element to detect if user clicked outside of dialog. -->
@if (timeBlockComponentItem.timeBlockModel; as model) {
  <a
    class="shive-timegrid-event k-d-flex flex-column text-decoration-none tb"
    [ngClass]="[
      model.isFullday ? 'horizontal' : 'vertical ' + CSSHasPrevPart + ' ' + CSSHasNextPart,
      timeBlockCSSService.getTimeBlockContentTypeClass(timeBlockComponentItem),
      TimeBlockContentType[timeBlockComponentItem.timeBlockContentType],
      model.partNumber === 0 ? CSSHeadPart : '',
      model.isActive ? CSSTrackedTimeBlock : '',
      timeBlockService.isDefaultTimeBlock(timeBlockComponentItem) ? CSSDefaultTimeBlock : '',
    ]"
    [timeBlockId]="model.id"
    [timeBlockPart]="model.partNumber"
    [attr.partNr]="model.partNumber"
    appTimeBlockDragResize
    [dragPart]="DragPart.Body"
    [ngStyle]="{
      height:
        model.isFullday || calendarViewMode === CalendarView.MonthGrid
          ? fulldayTimeBlockHeight + 'px'
          : '',
      'border-color': model.isFullday ? '' : (timeBlockComponentItem | timeBlockColor: 0.15),
    }"
    data-x="0"
    data-y="0"
    #timeBlock
  >
    <ng-container
      *ngTemplateOutlet="
        indicatorRef;
        context: {
          $implicit: timeBlockComponentItem,
          cssClass: CSSFulldayTimeBlockOverflowsLeftClass,
        }
      "
    >
    </ng-container>
    <div
      class="inner-wrapper k-h-full tb"
      [ngStyle]="{
        'border-left-color': model.isFullday ? '' : (timeBlockComponentItem | timeBlockColor),
        'background-color': timeBlockComponentItem | timeBlockColor: 0.6,
        'background-image': timeBlockComponentItem | timeBlockStripedBgImagePipe,
      }"
    >
      <!-- Resize Edge -->
      <app-drag-edge-template
        [dragPart]="model.isFullday ? DragPart.Left : DragPart.Top"
        [calendarView]="calendarViewMode"
        [timeBlockComponentItem]="timeBlockComponentItem"
        [model]="model"
      />
      <app-time-block-body
        class="k-d-block k-h-full"
        [calendarViewMode]="calendarViewMode"
        [timeBlockComponentItem]="timeBlockComponentItem"
        (timeBlockBodySet$)="timeBlockBody = $event"
      />
      @if (!model.isActive) {
        <app-drag-edge-template
          [dragPart]="model.isFullday ? DragPart.Right : DragPart.Bottom"
          [calendarView]="calendarViewMode"
          [timeBlockComponentItem]="timeBlockComponentItem"
          [model]="model"
        />
      }
    </div>
    <ng-container
      *ngTemplateOutlet="
        indicatorRef;
        context: {
          $implicit: timeBlockComponentItem,
          cssClass: CSSFulldayTimeBlockOverflowsRightClass,
        }
      "
    >
    </ng-container>
  </a>
}

<ng-template #indicatorRef let-timeBlockComponentItem let-cssClass="cssClass">
  @if (
    timeBlockComponentItem.timeBlockModel.isFullday &&
    timeBlockComponentItem.timeBlockModel.type === TimeBlockType.ExistingBlock
  ) {
    <div
      #overflowIndicator
      class="k-d-none position-absolute overflow-handle"
      [style.background-color]="timeBlockComponentItem | timeBlockColor"
      [ngClass]="'handle-' + cssClass"
    ></div>
  }
</ng-template>
