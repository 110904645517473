<div id="shive-timegrid-body" class="month-view k-display-flex k-flex-column">
  <div id="shive-timegrid-matrix" class="k-display-flex k-flex-1">
    <div class="table k-display-flex">
      <div id="shive-month-view-slots" class="table-body k-d-flex k-flex-column">
        @for (week of weekComposites; track week) {
          <div class="table-row k-d-flex k-flex-1">
            <div class="table-column k-flex-1 fixed-col k-d-flex pt-0 pb-0">
              <div class="index k-flex-1">
                <div>
                  <span class="font-weight-normal">KW</span>
                  <span class="font-weight-bold">{{ week.weekIndex }}</span>
                </div>
                <div>
                  <span class="week-number">{{
                    accumulatedTimeMap | timeBlockAccumulatedWork: week.weekIndex
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        }
      </div>

      <div
        id="shive-month-view-lanes"
        class="table-body k-display-grid k-flex-1"
        #htmlItemContainer
      >
        @for (week of weekComposites; track week; let i = $index) {
          <div class="table-row calendar-row k-d-flex k-flex-row k-flex-1 overflow-hidden">
            <div class="table-column k-d-flex k-flex-1 !k-p-0">
              <div class="table item-container k-d-flex k-m-0">
                <div class="table-row k-d-flex k-flex-1">
                  @for (day of week.days; track day; let j = $index) {
                    <div
                      class="table-cell shive-grid-day weekday k-flex-1 k-pos-relative dropzone"
                      [id]="week.days.length * i + j"
                      [attr.date]="DateTimeHelper.format(day.theDate)"
                      [class]="day.epoch"
                      [class.first-visible-day]="day.isFirstVisibleDay"
                      [class.last-visible-day]="day.isLastVisibleDay"
                      [class.is-holiday]="day.isHoliday"
                      appEmptyCalendarSpaceInteraction
                      [calendarView]="CalendarView.MonthGrid"
                      [attr.highest-parallel-index]="-1"
                      [ngClass]="day.isWithinMonth ? 'is-within-month' : 'is-outside-of-month'"
                      #bucket
                    >
                      <div class="inner-wrapper !k-p-0 k-h-full">
                        <div class="cell-header">
                          <div
                            class="date-number text-decoration-none position-absolute k-top-0 k-left-2 ps-2 pe-2 k-font-semibold"
                          >
                            {{ day.theDate | date: "dd" }}
                          </div>
                          <button
                            kendoButton
                            [size]="'small'"
                            (click)="
                              timeBlockContainerRendererService.showAllCellTimeBlocks($event)
                            "
                            class="more-events-button icon-button position-absolute k-top-0 k-right-0 !k-display-none"
                          >
                            <fa-icon class="icon" size="xs" [icon]="faPlus"></fa-icon>
                          </button>
                        </div>
                        <div class="time-block-container k-pos-relative">
                          <ng-container appTimeBlockContainer> </ng-container>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
